import React from 'react';

import { BankDeposit } from './deposit/BankDeposit';
import BankSummary from './summary';
import { SECURITY_ROLES_ENUM } from '../../../constants/roles.const';
import { PATH_BANK_DEPOSIT, PATH_BANK_SUMMARY } from '../../../constants/routes.const';
import { useGetLocalization } from '../../../hooks';
import { ID_BANK_DEPOSIT_BUTTON, ID_BANK_SUMMARY_BUTTON } from '../../../utils/dom-element-ids';
import { PermissionsContainer, Placeholder, Subheader } from '../../reusable';

function Banking() {
  const getLocalization = useGetLocalization();

  return (
    <>
      <Subheader>{getLocalization('lbl.banking')}</Subheader>
      <PermissionsContainer
        fallback={getLocalization('msg.no-permission')}
        requiredLevel={SECURITY_ROLES_ENUM.LEVEL_40}
      >
        <Placeholder
          id={ID_BANK_DEPOSIT_BUTTON}
          name={getLocalization('lbl.bank-deposit')}
          redirect={PATH_BANK_DEPOSIT}
        />
        <Placeholder
          id={ID_BANK_SUMMARY_BUTTON}
          name={getLocalization('lbl.bank-summary')}
          redirect={PATH_BANK_SUMMARY}
        />
      </PermissionsContainer>
    </>
  );
}

export { BankDeposit, BankSummary };

export default Banking;
