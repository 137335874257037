import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EndOfDayTable from './EndOfDayTable';
import { PATH_BANK_DEPOSIT } from '../../../constants/routes.const';
import { useGetLocalization } from '../../../hooks';
import { getTills } from '../../../utils/service-calls';
import { useCashModeContext } from '../../context';
import { ButtonSubmit, LoadingIndicator, RefreshButton, Subheader } from '../../reusable';
import ErrorMessage from '../../reusable/ErrorMessage';

const isPickedUpStatus = (currentTill) => currentTill.status === 'PICKED_UP';

function EndOfDay() {
  const replace = useNavigate();
  const getLocalization = useGetLocalization();

  const { cashMode } = useCashModeContext();

  const [tillsLoading, setTillsLoading] = useState(false);
  const [tillData, setTillData] = useState([]);
  const [tillDataError, setTillDataError] = useState(null);
  const [areTillsPickedUp, setAreTillsPickedUp] = useState(false);

  // Fetch data on page load
  useEffect(() => {
    // Reset state
    setTillsLoading(true);

    getTills()
      .then(({ data }) => {
        setAreTillsPickedUp(data.tills.every(isPickedUpStatus));
        return setTillData(data.tills);
      })
      .catch((err) => setTillDataError(err))
      .finally(() => setTillsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let renderContent = null;

  if (tillsLoading) {
    renderContent = <LoadingIndicator />;
  }

  if (tillDataError) {
    renderContent = (
      <div className="pt8-sm">
        <ErrorMessage rawError={tillDataError}>
          {getLocalization('msg.error-fetching-tills')}
        </ErrorMessage>
        <RefreshButton />
      </div>
    );
  }

  return (
    <article className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.end-of-day')}</Subheader>
      {renderContent || (
        <>
          <EndOfDayTable cashMode={cashMode} data={tillData} />
          <ButtonSubmit
            isDisabled={!areTillsPickedUp}
            label={getLocalization('lbl.proceed-to-bank-deposit')}
            messageError={!areTillsPickedUp ? getLocalization('msg.closed-tills') : null}
            sectionClassName="mt6-sm"
            onSubmit={() => replace(PATH_BANK_DEPOSIT, { state: { isEndOfDayProcess: true } })}
          />
        </>
      )}
    </article>
  );
}

export default EndOfDay;
