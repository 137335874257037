export const localeInfo = {
  ARE: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'AED',
      thousands: ',',
    },
    currencyCode: 'AED',
    dateFormat: 'YYYY/M/D',
  },
  ARG: {
    currencyCode: 'ARS',
    dateFormat: 'DD/MM/YYYY',
  },
  AUS: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'AUD',
    dateFormat: 'DD/MM/YYYY',
  },
  AUT: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD.MM.YYYY',
  },
  BEL: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'YYYY-MM-DD',
  },
  BGR: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      postfix: 'лв.',
      prefix: '',
      thousands: ',',
    },
    currencyCode: 'BGN',

    dateFormat: 'DD.MM.YYYY',
  },
  BRA: {
    currencyCode: 'BRL',
    dateFormat: 'DD/MM/YYYY',
  },
  CAN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'CAD',

    dateFormat: 'MM/DD/YYYY',
  },
  CHE: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'CHF',
      thousands: '’',
    },
    currencyCode: 'CHF',
    dateFormat: 'DD.MM.YYYY',
  },
  CHL: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 0,
      prefix: '$',
      thousands: '.',
    },
    currencyCode: 'CLP',

    dateFormat: 'DD.MM.YYYY',
  },
  CHN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '￥',
      thousands: ',',
    },
    currencyCode: 'CNY',

    dateFormat: 'YYYY/MM/DD',
  },
  CZE: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'Kč',
      prefix: '',
      thousands: ' ',
    },
    currencyCode: 'CZK',

    dateFormat: 'D. M. YYYY',
  },
  DEU: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'EUR',

    dateFormat: 'DD.MM.YYYY',
  },
  DNK: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'kr.',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'DKK',

    dateFormat: 'DD-MM-YYYY',
  },
  EGY: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'EGP',
      thousands: ',',
    },
    currencyCode: 'EGP',
    dateFormat: 'YYYY/MM/DD',
  },
  ESP: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  },
  FIN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: ',',
    },
    currencyCode: 'EUR',
    dateFormat: 'D.M.YYYY',
  },
  FRA: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: ' ',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  },
  GBR: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '£',
      thousands: ',',
    },
    currencyCode: 'GBP',
    dateFormat: 'DD/MM/YYYY',
  },
  GRC: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  },
  'Hong Kong': {},
  HRV: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      postfix: 'kn',
      prefix: '',
      thousands: ',',
    },
    currencyCode: 'HRK',
    dateFormat: 'D. M. YYYY',
  },
  HUN: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'Ft',
      prefix: '',
      thousands: ' ',
    },
    currencyCode: 'HUF',
    dateFormat: 'YYYY. MM. DD.',
  },
  IDN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'Rp',
      thousands: ',',
    },
    currencyCode: 'IDR',

    dateFormat: 'DD/MM/YYYY',
  },
  IND: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '₹',
      thousands: ',',
    },
    currencyCode: 'INR',

    dateFormat: 'DD-MM-YY',
  },
  IRL: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: ',',
    },
    currencyCode: 'EUR',

    dateFormat: 'DD/MM/YYYY',
  },
  ISR: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '₪',
      thousands: ',',
    },
    currencyCode: 'ILS',
    dateFormat: 'DD/MM/YYYY',
  },
  ITA: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  },
  JPN: {
    currency: {
      digitsAfterDecimal: 0,
      prefix: '￥',
      thousands: ',',
    },
    currencyCode: 'JPY',
    dateFormat: 'YYYY/MM/DD',
  },
  KOR: {
    currency: {
      digitsAfterDecimal: 0,
      postfix: '￦',
      prefix: '',
      thousands: ',',
    },
    currencyCode: 'KRW',
    dateFormat: 'YYYY/MM/DD',
  },
  LUX: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  },
  MAR: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'Dh',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'MAD',
    dateFormat: 'YYYY/M/D',
  },
  MEX: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'MXN',
    dateFormat: 'DD/MM/YYYY',
  },
  MYS: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'RM',
      thousands: ',',
    },
    currencyCode: 'MYR',
    dateFormat: 'D/M/YY',
  },
  NLD: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD-MM-YYYY',
  },
  NOR: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: 'kr',
      thousands: ' ',
    },
    currencyCode: 'NOK',
    dateFormat: 'DD/MM/YYYY',
  },
  NZL: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'NZD',
    dateFormat: 'DD/MM/YYYY',
  },
  PHL: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '₱',
      thousands: ',',
    },
    currencyCode: 'PHP',
    dateFormat: 'MM/DD/YYYY',
  },
  POL: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'zł',
      prefix: '',
      thousands: ' ', // Should only be used if there are 5 or more digits... I'll... figure that out later...
    },
    currencyCode: 'PLN',
    dateFormat: 'D.MM.YYYY',
  },
  PRI: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'USD',
    dateFormat: 'DD/MM/YYYY',
  },
  PRT: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: ' ',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  },
  ROU: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'lei',
      prefix: '',
      thousands: '.',
    },
    currencyCode: 'RON',
    dateFormat: 'DD/MM/YYYY',
  },
  RUS: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '₽',
      prefix: '',
      thousands: ' ',
    },
    currencyCode: 'RUB',
    dateFormat: 'DD.MM.YYYY',
  },
  SAU: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'SAR',
      thousands: ',',
    },
    currencyCode: 'SAR',
    dateFormat: 'YYYY/M/D',
  },
  SGP: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'S$',
      thousands: ',',
    },
    currencyCode: 'SGD',
    dateFormat: 'DD-MM-YYYY',
  },
  SVK: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: ',',
    },
    currencyCode: 'EUR',
    dateFormat: 'DD. MM. YYYY',
  },
  SVN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: ',',
    },
    currencyCode: 'EUR',
    dateFormat: 'D. M. YYYY',
  },
  SWE: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'kr',
      prefix: '',
      thousands: ' ',
    },
    currencyCode: 'SEK',
    dateFormat: 'DD-MM-YY',
  },
  THA: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '฿',
      thousands: ',',
    },
    currencyCode: 'THB',
    dateFormat: 'DD/MM/YYYY',
  },
  TUR: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '₺',
      thousands: '.',
    },
    currencyCode: 'TRY',
    dateFormat: 'DD.MM.YYYY',
  },
  TWN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'TWD',
    dateFormat: 'YYYY/MM/DD',
  },
  URY: {},
  USA: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    currencyCode: 'USD',
    dateFormat: 'MM/DD/YYYY',
  },
  VNM: {
    currency: {
      digitsAfterDecimal: 0,
      postfix: '₫',
      prefix: '',
      thousands: ',',
    },
    currencyCode: 'VND',
    dateFormat: 'D/M/YY',
  },
  ZAF: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: 'R',
      thousands: ' ',
    },
    currencyCode: 'ZAR',
    dateFormat: 'DD-MM-YYYY',
  },
};
