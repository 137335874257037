import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import { getVarianceReason, getSchema } from './validation';
import { NR_CASH_MODE_END, NR_CASH_MODE_END_FINISH } from '../../../../constants/newrelic.const';
import { PATH_CLOSE_STORE, PATH_TILL_STATUS } from '../../../../constants/routes.const';
import { useGetProfile, useGetStoreInfo } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import {
  useGetCashSafeFromStoreIdQuery,
  useReconcileSafeMutation,
} from '../../../../redux/services/store/storeService';
import { getFormattedCurrency } from '../../../../utils/currencyUtils';
import {
  ID_CLOSE_STORE_BUTTON,
  ID_CURRENT_FLOAT,
  ID_SAFE_NUMBER,
  ID_TILL_STATUS_ANCHOR,
  ID_START_OF_DAY_BUTTON,
  ID_VARIANCE_EXPLANATION,
  ID_VARIANCE_REASONS,
} from '../../../../utils/dom-element-ids';
import { getMaxAmountForCountry } from '../../../../utils/getMaxAllowableAmount';
import { nrPageAction } from '../../../../utils/newRelic';
import { updateCashMode } from '../../../../utils/service-calls';
import { MultipleCurrencyInput, TextField } from '../../../common';
import { FormWrapper } from '../../../common/FormWrapper';
import { CASH_MODE_ENUM, useCashModeContext } from '../../../context';
import { ButtonNavigateTo, ButtonSubmit, Subheader, VarianceReasonModal } from '../../../reusable';
import SuccessMessage from '../../../reusable/SuccessMessage';

import '../../../reusable/VarianceReasonRow.css';

export const VARIANCE_REASON_ENUM = {
  SHORT_VARIANCE: 'Short',
  OVER_VARIANCE: 'Over',
  NO_VARIANCE: 'No Variance',
};

const defaultValues = {
  currentFloat: 0,
  varianceExplanation: '',
  varianceReason: VARIANCE_REASON_ENUM.NO_VARIANCE,
};

export function ReconcileSafe() {
  const push = useNavigate();
  const getLocalization = useGetLocalization();
  const { country, storeId } = useGetProfile();
  const { currency, locale } = useGetStoreInfo();

  const validationConfig = {
    fields: {
      safeAmount: {
        MAX_AMOUNT: getMaxAmountForCountry(country),
        NONNEGATIVE: getLocalization('msg.number-must-be-positive'),
        MAX: getLocalization('msg.number-exceeds-max'),
      },
    },
    variance: {
      short: {
        VALUE: 'Short',
        LABEL: getLocalization('lbl.variance-short'),
      },
      over: {
        VALUE: 'Over',
        LABEL: getLocalization('lbl.variance-over'),
      },
      none: {
        VALUE: 'No Variance',
        LABEL: getLocalization('lbl.no-variance'),
      },
    },
  };

  // TODO: move to global state
  const location = useLocation();
  const { isEndOfDayProcess = false, isStartOfDayProcess = false } = location.state || {};

  const {
    data: { currentFloat: originalFloat, safeName, safeNumber } = {},
    error: { message: cashSafeError } = {},
    isFetching: isCashSafeFetching,
    isLoading: isCashSafeLoading,
  } = useGetCashSafeFromStoreIdQuery({ storeId });

  const [
    reconcileSafe,
    { error: submitError, isSuccess: isSubmitSuccess, isLoading: isSubmitLoading },
  ] = useReconcileSafeMutation();

  const form = useForm({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(getSchema(validationConfig)),
  });

  const {
    watch,
    control,
    setValue,
    setError,
    formState: { isSubmitSuccessful },
  } = form;

  const formCurrentFloat = watch('currentFloat', 0);
  const formOriginalFloat = watch('originalFloat', 0);

  // request load
  useEffect(() => {
    setValue('currentFloat', originalFloat);
    setValue('originalFloat', originalFloat);
  }, [originalFloat, setValue]);

  // TODO: move to global storage
  const { cashMode, setCashMode, setCashModeError, setCashModeCreateTime } = useCashModeContext();
  const handleStoreClose = async () => {
    try {
      const data = await updateCashMode(CASH_MODE_ENUM.CLOSED);
      setCashMode(data.data.cash_mode);
      setCashModeCreateTime(data.data.createTime);
      nrPageAction(NR_CASH_MODE_END, {
        storeId,
        storeOpen: false,
        timestamp: Date.now(),
      });
      nrPageAction(NR_CASH_MODE_END_FINISH, {
        storeId,
        storeOpen: false,
        timestamp: Date.now(),
      });
      push(PATH_CLOSE_STORE);
    } catch ({ message }) {
      const error = `${getLocalization('msg.close-store-status-failed')}: ${message}`;
      setCashModeError(error);
      setError('formError', error);
    }
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.safe-reconciliation')}</Subheader>

      <FormWrapper
        crossFieldValidationArray={['currentFloat']}
        fetchErrors={[cashSafeError]}
        fetchingFlags={[isCashSafeFetching]}
        form={form}
        loadingFlags={[isCashSafeLoading]}
        submit={{
          error: submitError,
          handler: (data) => {
            const varianceReason = getVarianceReason(
              formCurrentFloat - formOriginalFloat,
              validationConfig.variance,
            ).VALUE;
            data.varianceReason = varianceReason;
            return reconcileSafe({
              currencyCode: currency,
              safeNumber,
              storeId,
              ...data,
            });
          },
          isLoading: isSubmitLoading,
          isSuccess: isSubmitSuccess,
          disableAfter: true,
          label: getLocalization('lbl.submit-safe-reconciliation'),
        }}
      >
        <h2 className="display-5 mb3-sm" id={ID_SAFE_NUMBER}>
          {`${getLocalization('lbl.safe')}: ${safeName}`}
        </h2>
        <h2 className="display-5 mb3-sm" id={ID_SAFE_NUMBER}>
          {`${getLocalization('lbl.number')}: ${safeNumber}`}
        </h2>

        <div className="pb6-sm">
          <MultipleCurrencyInput
            isRequired
            control={control}
            id={ID_CURRENT_FLOAT}
            label={getLocalization('lbl.current-float')}
            name="currentFloat"
            supportText={`${getLocalization('lbl.change')}: ${getFormattedCurrency(
              locale,
              currency,
              formCurrentFloat - formOriginalFloat,
            )}`}
          />
        </div>
        <div className="variance-reason-row pb3-sm">
          <TextField
            readOnly
            control={control}
            id={ID_VARIANCE_REASONS}
            label={getLocalization('lbl.variance-reason')}
            name="varianceReason"
            value={
              getVarianceReason(formCurrentFloat - formOriginalFloat, validationConfig.variance)
                .LABEL
            }
          />
          <div className="pb3-sm">
            <VarianceReasonModal
              className="dialogModal"
              modalTitle={getLocalization('lbl.variance-reason-definitions')}
            />
          </div>
        </div>

        <TextField
          control={control}
          shouldMakeActive
          type="string"
          name="varianceExplanation"
          id={ID_VARIANCE_EXPLANATION}
          label={getLocalization('lbl.variance-explanation')}
        />
      </FormWrapper>

      {/** SOD/EOD Flow */}
      {isStartOfDayProcess && (
        <>
          <ButtonNavigateTo
            buttonClassName="mb2-sm"
            buttonId={ID_START_OF_DAY_BUTTON}
            buttonSize="large"
            label={getLocalization('lbl.view-till-status-and-association')}
            linkId={ID_TILL_STATUS_ANCHOR}
            pathname={PATH_TILL_STATUS}
            sectionClassName="ncss-col-sm-12 full"
          />
          <SuccessMessage>
            {getLocalization(
              'msg.store-is-now-open-proceed-to-view-till-status-and-continue-with-till-association',
            )}
          </SuccessMessage>
        </>
      )}
      {isEndOfDayProcess && isSubmitSuccessful && (
        <ButtonSubmit
          id={ID_CLOSE_STORE_BUTTON}
          isDisabled={cashMode === CASH_MODE_ENUM.CLOSED}
          label={getLocalization('lbl.close-store')}
          onSubmit={handleStoreClose}
        />
      )}
    </article>
  );
}
