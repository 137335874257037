import escapeHtml from 'lodash.escape';
import PropTypes from 'prop-types';
import React from 'react';
import { CSVLink } from 'react-csv';

import { useGetLocalization } from '../../../../hooks';
import { ButtonSubmit } from '../../../reusable';

function BankSummaryTable({ bankSummaries }) {
  const getLocalization = useGetLocalization();

  const varianceReasonLookupTable = {
    Short: getLocalization('lbl.variance-short'),
    Over: getLocalization('lbl.variance-over'),
    'No Variance': getLocalization('lbl.no-variance'),
  };
  const translateVarianceReason = (reason) => varianceReasonLookupTable[reason] ?? reason;
  const translateBankSummaryRecord = ({ varianceReason, ...rest }) => ({
    ...rest,
    varianceReason: translateVarianceReason(varianceReason),
  });
  const bankSummariesTranslated = bankSummaries.map(translateBankSummaryRecord);

  const COLUMNS = [
    { key: 'localeDate', label: getLocalization('lbl.date-time') },
    { key: 'depositSlip', label: getLocalization('lbl.deposit-number') },
    { key: 'localeDepositAmount', label: getLocalization('lbl.deposited'), textAlign: 'right' },
    {
      key: 'localeDepositAmountBeforeDeposit',
      label: getLocalization('lbl.expected'),
      textAlign: 'right',
    },
    { key: 'localeVariance', label: getLocalization('lbl.variance'), textAlign: 'right' },
    { key: 'varianceReason', label: getLocalization('lbl.reason') },
    { key: 'varianceExplanation', label: getLocalization('lbl.variance-explanation') },
    { key: 'athleteInfo', label: getLocalization('lbl.athlete') },
  ];

  const headerCell = (fieldName, idx, value, textAlign = 'center') => (
    <th
      key={`BankSummaryTable_th_${fieldName}_${idx}`}
      style={{
        background: 'black',
        color: 'white',
        fontSize: '20px',
        textAlign,
      }}
    >
      {escapeHtml(value)}
    </th>
  );

  const dataCell = (fieldName, idx, value, textAlign = 'center', color = '#111') => (
    <td
      key={`BankSummaryTable_td_${fieldName}_${idx}`}
      style={{
        background: '#FAFAFA',
        border: '1px solid #E5E5E5',
        color,
        textAlign,
      }}
    >
      {escapeHtml(value)}
    </td>
  );

  return (
    <>
      <h1 className="text-color-accent">{getLocalization('lbl.search-results')}</h1>
      <table style={{ border: '1px solid #E5E5E5', borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr key="BankSummaryTable_Header_0">
            {COLUMNS.map(({ key, label, textAlign }, columnIdx) =>
              headerCell(key, columnIdx, label, textAlign),
            )}
          </tr>
        </thead>
        <tbody>
          {bankSummariesTranslated.map((summary, summaryIdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={`BankSummaryTable_Data_${summaryIdx}`}>
              {COLUMNS.map(({ key, textAlign }, columnIdx) =>
                dataCell(key, columnIdx, summary[key], textAlign),
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
        <CSVLink
          key="CsvLink"
          data={bankSummariesTranslated}
          filename="bank-summary.csv"
          headers={COLUMNS}
          target="_blank"
        >
          <ButtonSubmit
            key="CsvExportButton"
            label={getLocalization('lbl.export')}
            onSubmit={() => {}}
          />
        </CSVLink>
      </article>
    </>
  );
}

BankSummaryTable.propTypes = {
  bankSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      athleteInfo: PropTypes.string.isRequired,
      depositSlip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      localeDate: PropTypes.string.isRequired,
      localeDepositAmount: PropTypes.string.isRequired,
      localeDepositAmountBeforeDeposit: PropTypes.string.isRequired,
      localeVariance: PropTypes.string.isRequired,
      varianceReason: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BankSummaryTable;
