import { WeatherNightClear, WeatherSunny } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../hooks';

export function StatusIndicator({ text }) {
  const getLocalization = useGetLocalization();
  let color;
  let iconStatus;
  if (text === getLocalization('lbl.store-status-open')) {
    color = 'text-color-success';
    iconStatus = <WeatherSunny className="va-sm-m" />;
  } else {
    color = 'text-color-error';
    iconStatus = <WeatherNightClear className="va-sm-m" />;
  }
  return (
    <section className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4">
      <p
        className={`${color} headline-4 p2-sm prl4-sm mt4-sm mb4-sm ta-sm-c `}
        style={{ position: 'relative' }}
      >
        {iconStatus}
        <span className="pl2-sm ">{text}</span>
      </p>
    </section>
  );
}

StatusIndicator.propTypes = {
  text: PropTypes.string.isRequired,
};
