export const languagePickerData = [
  {
    name: 'English (US)',
    tag: 'en',
    ncssLang: 'en-us',
    enabled: true,
  },
  {
    name: 'Arabic',
    tag: 'ar',
    ncssLang: 'en-gb',
    enabled: true,
  },
  {
    name: 'Catalan',
    tag: 'ca',
    ncssLang: 'ca-es',
    enabled: true,
  },
  {
    name: 'Chinese (Simplified)',
    tag: 'zh-Hans',
    ncssLang: 'zh-cn',
    enabled: true,
  },
  {
    name: 'Chinese (Traditional)',
    tag: 'zh-Hant',
    ncssLang: 'zh-tw',
    enabled: true,
  },
  {
    name: 'Czech',
    tag: 'cs',
    ncssLang: 'cs-cz',
    enabled: true,
  },
  {
    name: 'Danish',
    tag: 'da',
    ncssLang: 'en-gb',
    enabled: true,
  },
  {
    name: 'Dutch',
    tag: 'nl',
    ncssLang: 'nl-nl',
    enabled: true,
  },
  {
    name: 'English (United Kingdom)',
    tag: 'en-GB',
    ncssLang: 'en-gb',
    enabled: true,
  },
  {
    name: 'English',
    tag: 'en-XA',
    ncssLang: 'en-gb',
    enabled: false,
  },
  {
    name: 'French',
    tag: 'fr',
    ncssLang: 'fr-fr',
    enabled: true,
  },
  {
    name: 'German',
    tag: 'de',
    ncssLang: 'de-de',
    enabled: true,
  },
  {
    name: 'Greek',
    tag: 'el',
    ncssLang: 'el-gr',
    enabled: true,
  },

  {
    name: 'Hungarian',
    tag: 'hu',
    ncssLang: 'hu-hu',
    enabled: true,
  },
  {
    name: 'Indonesian',
    tag: 'id',
    ncssLang: 'en-gb',
    enabled: true,
  },
  {
    name: 'Italian',
    tag: 'it',
    ncssLang: 'it-it',
    enabled: true,
  },
  {
    name: 'Japanese',
    tag: 'ja',
    ncssLang: 'ja-jp',
    enabled: true,
  },
  {
    name: 'Korean',
    tag: 'ko',
    ncssLang: 'ko-kr',
    enabled: true,
  },
  {
    name: 'Norwegian',
    tag: 'nb',
    ncssLang: 'en-gb',
    enabled: true,
  },
  {
    name: 'Polish',
    tag: 'pl',
    ncssLang: 'pl-pl',
    enabled: true,
  },
  {
    name: 'Portuguese (Brazilian)',
    tag: 'pt-BR',
    ncssLang: 'pt-br',
    enabled: true,
  },
  {
    name: 'Portuguese (European)',
    tag: 'pt-PT',
    ncssLang: 'pt-pt',
    enabled: true,
  },

  {
    name: 'Russian',
    tag: 'ru',
    ncssLang: 'ru-ru',
    enabled: true,
  },
  {
    name: 'Spanish (as used in Latin America and the Caribbean)',
    tag: 'es-419',
    ncssLang: 'es-es',
    enabled: true,
  },
  {
    name: 'Spanish (Argentina)',
    tag: 'es-AR',
    ncssLang: 'es-ar',
    enabled: true,
  },
  {
    name: 'Spanish (Spain)',
    tag: 'es-ES',
    ncssLang: 'es-es',
    enabled: true,
  },
  {
    name: 'Spanish (Mexico)',
    tag: 'es-MX',
    ncssLang: 'es-mx',
    enabled: true,
  },
  {
    name: 'Swedish',
    tag: 'sv',
    ncssLang: 'en-gb',
    enabled: true,
  },
  {
    name: 'Thai',
    tag: 'th',
    ncssLang: 'th-th',
    enabled: true,
  },
  {
    name: 'Turkish',
    tag: 'tr',
    ncssLang: 'tr-tr',
    enabled: true,
  },
];
