import React, { useEffect, useState } from 'react';

import DateRangeSelect from './DateRangeSelect';
import Summaries from './Summaries';
import { useGetProfile } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import { getReconciliations } from '../../../../utils/service-calls';
import { getLocalEndDateToUTC, getLocalStartDateToUTC } from '../../../../utils/timeUtils';
import { ButtonSubmit, LoadingIndicator, Subheader } from '../../../reusable';

const getToday = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};

function ReconciliationSummaries() {
  const getLocalization = useGetLocalization();
  const { country } = useGetProfile();
  const today = getToday();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const [summaries, setSummaries] = useState([]);
  const [isLoadingSummaries, setIsLoadingSummaries] = useState(false);

  const getSummaries = async () => {
    setIsLoadingSummaries(true);
    const {
      data: { objects },
    } = await getReconciliations(getLocalStartDateToUTC(startDate), getLocalEndDateToUTC(endDate));
    setSummaries(objects);
    setIsLoadingSummaries(false);
  };

  useEffect(() => {
    getSummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.safe-reconciliation-summary')}</Subheader>
      <DateRangeSelect
        country={country}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
      />
      <ButtonSubmit label={getLocalization('lbl.search')} onSubmit={getSummaries} />
      {isLoadingSummaries ? <LoadingIndicator /> : <Summaries data={summaries} />}
    </article>
  );
}

export default ReconciliationSummaries;
