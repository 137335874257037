import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { storeApi } from './services';
import { reducer as globalsReducer } from '../globals';

const rootReducer = combineReducers({
  /** App State Reducers */
  globals: globalsReducer,
  /** API Reducers */
  [storeApi.reducerPath]: storeApi.reducer,
});

const middleware = [storeApi.middleware];

export const setupStore = (preloadedState) =>
  configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), ...middleware],
    preloadedState,
    reducer: rootReducer,
  });

setupListeners(setupStore().dispatch);
