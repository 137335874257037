import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setLangLocale } from './reducer';

export function useSetGlobalLangLocale() {
  const dispatch = useDispatch();

  return useCallback(
    (langLocale) => {
      dispatch(setLangLocale(langLocale));
    },
    [dispatch],
  );
}
