import { Default, match } from '@nike/rcf-fp';
import axios from 'axios';
import { format as formatDate, parseISO } from 'date-fns';

import { getItemFromSessionStorage } from './localStorage';
import { HOST_API, HOST_STOREVIEWS } from '../constants/app.const';
import {
  RESOURCE_STOREVIEWS,
  RESOURCE_BANK_DEPOSIT,
  RESOURCE_BANK_DEPOSIT_AMOUNT,
  RESOURCE_BANK_SUMMARIES,
  RESOURCE_CASH_SAFE_ADJUSTMENTS,
  RESOURCE_CASH_SAFE_RECEIPTS,
  RESOURCE_CASH_SAFE_LOANS,
  RESOURCE_CASH_SAFE_RECONCILIATIONS,
  RESOURCE_CASH_SAFES,
  RESOURCE_STORE_SAFES,
  RESOURCE_CASH_TILL_ADJUSTMENTS,
  RESOURCE_CASH_TILL_ASSOCIATIONS,
  RESOURCE_CASH_TILL_AUDITS,
  RESOURCE_CASH_TILL_INS,
  RESOURCE_CASH_TILL_LOANS,
  RESOURCE_CASH_TILL_OUTS,
  RESOURCE_CASH_TILLS,
  RESOURCE_STORE_TILLS,
  RESOURCE_TILL_VARIANCE_SUMMARIES,
  RESOURCE_TILL_PICKUP,
  RESOURCE_CASH_MODE,
  RESOURCE_UPDATE_CASH_MODE,
} from '../constants/resources.const';

const axiosConfig = {
  baseURL: HOST_API,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
    'X-Api-Key': 'foo',
  },
  timeout: 10000,
};

export const axiosWithAuth = axios.create(axiosConfig);

axiosWithAuth.interceptors.request.use((axiosRequestConfig) => {
  const accessToken = getItemFromSessionStorage('accessToken');
  axiosRequestConfig.headers.Authorization = `Bearer ${accessToken}`;

  return axiosRequestConfig;
});

function getStoreId() {
  return getItemFromSessionStorage('storeId');
}

/* Banks */

export const bankDeposit = ({
  currencyCode,
  depositAmount,
  depositSlip,
  expectedDepositAmount,
  varianceReason,
  varianceExplanation,
  amountBackToSafe,
}) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_BANK_DEPOSIT}`, {
    amountBackToSafe,
    currencyCode,
    depositAmount,
    depositSlip,
    expectedDepositAmount,
    varianceExplanation,
    varianceReason,
  });

export const getBankDepositAmount = () =>
  axiosWithAuth.get(`${HOST_API}${RESOURCE_BANK_DEPOSIT_AMOUNT}/${getStoreId()}`);

export const getBankSummaries = (endDate, startDate) => {
  // TODO: Implement w/ server call.

  const formatCurrency = (amount, currency) =>
    new Intl.NumberFormat(undefined, { currency, style: 'currency' }).format(amount);
  const getAthleteInfo = ({ athleteName, athleteNumber }) => `${athleteName} (${athleteNumber})`;
  const getLocaleDate = ({ createTime }) => formatDate(parseISO(createTime), 'yyyy-MM-dd HH:mm');
  const getLocaleDepositAmount = ({ depositAmount, currencyCode }) =>
    formatCurrency(depositAmount, currencyCode);
  const getLocaleDepositAmountBeforeDeposit = ({ expectedDepositAmount, currencyCode }) =>
    formatCurrency(expectedDepositAmount, currencyCode);
  const getLocaleVariance = ({ variance, currencyCode }) => formatCurrency(variance, currencyCode);
  const getVarianceExplanation = ({ varianceExplanation }) => `${varianceExplanation}`;
  const getVarianceColor = ({ variance }) =>
    match(variance)(
      [() => variance > 0, '#66DD66'], // Green
      [() => variance < 0, '#DD6666'], // Red
      [Default, '#111'], // Black
    );
  const addLocalizationToBankSummary = (bankSummary) => ({
    ...bankSummary,
    athleteInfo: getAthleteInfo(bankSummary),
    localeDate: getLocaleDate(bankSummary),
    localeDepositAmount: getLocaleDepositAmount(bankSummary),
    localeDepositAmountBeforeDeposit: getLocaleDepositAmountBeforeDeposit(bankSummary),
    varianceExplanation: getVarianceExplanation(bankSummary),
    localeVariance: getLocaleVariance(bankSummary),
    varianceColor: getVarianceColor(bankSummary),
  });

  return axiosWithAuth
    .get(
      `${HOST_API}${RESOURCE_BANK_SUMMARIES}?filter=bankDepositDateBeforeEq(${endDate})&filter=bankDepositDateAfterEq(${startDate})`,
    )
    .then(({ data }) => data.objects.map(addLocalizationToBankSummary));
};

/* Stores */

export const getCashMode = () =>
  axiosWithAuth.get(`${HOST_API}${RESOURCE_CASH_MODE}/${getStoreId()}`);

export const updateCashMode = (cashMode) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_UPDATE_CASH_MODE}`, {
    cash_mode: cashMode,
    storeId: getStoreId(),
  });

/* Safes */

export const addSafe = ({
  currencyCode,
  currentFloat,
  defaultFloat,
  safeFloor,
  safeName,
  safeNumber,
}) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_SAFES}`, {
    currencyCode,
    currentFloat,
    defaultFloat,
    safeFloor,
    safeName,
    safeNumber,
  });

export const adjustSafe = ({
  currencyCode,
  floatVariance,
  newDefaultFloat,
  newSafeFloor,
  safeNumber,
}) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_SAFE_ADJUSTMENTS}`, {
    currencyCode,
    floatVariance,
    newDefaultFloat,
    newSafeFloor,
    safeNumber,
  });

export const reconcileSafe = ({
  currencyCode,
  currentFloat,
  varianceExplanation,
  varianceReason,
  safeNumber,
}) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_SAFE_RECONCILIATIONS}`, {
    currencyCode,
    currentFloat,
    safeNumber,
    varianceExplanation,
    varianceReason,
  });

export const getReconciliations = (start, end) =>
  axiosWithAuth.get(
    `${HOST_API}${RESOURCE_CASH_SAFE_RECONCILIATIONS}?filter=reconciliationDateAfterEq(${start})&filter=reconciliationDateBeforeEq(${end})`,
  );

export const safeReceipt = ({ adjustmentAmount, currencyCode, safeNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_SAFE_RECEIPTS}`, {
    adjustmentAmount,
    currencyCode,
    safeNumber,
  });

export const safeLoan = ({ currencyCode, loanAmount, safeNumber, tillNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_SAFE_LOANS}`, {
    currencyCode,
    loanAmount,
    safeNumber,
    tillNumber,
  });

export const getSafes = () =>
  axiosWithAuth.get(`${HOST_API}${RESOURCE_STORE_SAFES}/${getStoreId()}`);

export const getSafeDetail = (safeId) =>
  axiosWithAuth.get(`${HOST_API}${RESOURCE_CASH_SAFES}/${safeId}`);

/* Tills */

export const acceptLoan = ({ currencyCode, loanAmount, pendingLoanId, tillNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILL_LOANS}`, {
    currencyCode,
    loanAmount,
    pendingLoanId,
    tillNumber,
  });

export const addTill = ({ currencyCode, currentFloat, defaultFloat, tillCeiling, tillNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILLS}`, {
    currencyCode,
    currentFloat,
    defaultFloat,
    tillCeiling,
    tillNumber,
  });

export const adjustTill = ({
  currencyCode,
  floatVariance,
  newDefaultFloat,
  newTillCeiling,
  tillNumber,
}) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILL_ADJUSTMENTS}`, {
    currencyCode,
    floatVariance,
    newDefaultFloat,
    newTillCeiling,
    tillNumber,
  });

export const associateTill = ({
  currencyCode,
  newCurrentFloat,
  tillNumber,
  varianceReason,
  workstationNumber,
}) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILL_ASSOCIATIONS}`, {
    currencyCode,
    newCurrentFloat,
    tillNumber,
    varianceReason,
    workstationNumber,
  });

export const tillAudit = ({ currencyCode, newCurrentFloat, tillNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILL_AUDITS}`, {
    currencyCode,
    newCurrentFloat,
    tillNumber,
  });

export const tillIn = ({ currencyCode, tillNumber, workstationNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILL_INS}`, {
    currencyCode,
    tillNumber,
    workstationNumber: Number(workstationNumber),
  });

export const tillOut = ({ currencyCode, tillNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_CASH_TILL_OUTS}`, {
    currencyCode,
    tillNumber,
  });

export const getTillVarianceSummaries = (endDate, startDate) => {
  const formatCurrency = (amount, currency) =>
    new Intl.NumberFormat(undefined, { currency, style: 'currency' }).format(amount);
  const getAthleteInfo = ({ athleteName, athleteNumber }) => `${athleteName} (${athleteNumber})`;
  const getLocaleDate = ({ createTime }) => formatDate(parseISO(createTime), 'yyyy-MM-dd HH:mm');
  const getLocaleAmountExpected = ({ amountExpected, currencyCode }) =>
    formatCurrency(amountExpected, currencyCode);
  const getLocaleAmountReceived = ({ amountReceived, currencyCode }) =>
    formatCurrency(amountReceived, currencyCode);
  const getLocaleVariance = ({ variance, currencyCode }) => formatCurrency(variance, currencyCode);
  const getVarianceExplanation = ({ varianceExplanation }) => `${varianceExplanation}`;
  const getVarianceColor = ({ variance }) =>
    match(variance)(
      [() => variance, '#DD6666'], // Red
      [Default, '#111'], // Black
    );

  const addLocalizationToTillVarianceSummary = (tillVarianceSummary) => ({
    ...tillVarianceSummary,
    athleteInfo: getAthleteInfo(tillVarianceSummary),
    localeDate: getLocaleDate(tillVarianceSummary),
    localeAmountReceived: getLocaleAmountReceived(tillVarianceSummary),
    localeAmountExpected: getLocaleAmountExpected(tillVarianceSummary),
    varianceExplanation: tillVarianceSummary.varianceExplanation
      ? getVarianceExplanation(tillVarianceSummary)
      : '',
    localeVariance: tillVarianceSummary.variance ? getLocaleVariance(tillVarianceSummary) : '',
    varianceReason: tillVarianceSummary.varianceReason ?? '',
    varianceColor: getVarianceColor(tillVarianceSummary),
    tillNumber: tillVarianceSummary.tillNumber,
  });

  return axiosWithAuth
    .get(
      `${HOST_API}${RESOURCE_TILL_VARIANCE_SUMMARIES}?filter=pickupDateBeforeEq(${endDate})&filter=pickupDateAfterEq(${startDate})`,
    )
    .then(({ data }) => data.objects.map(addLocalizationToTillVarianceSummary));
};

export const tillPickup = ({ currencyCode, loansForTheDay, pickUps, tillNumber }) =>
  axiosWithAuth.post(`${HOST_API}${RESOURCE_TILL_PICKUP}`, {
    currencyCode,
    loansForTheDay,
    pickUps,
    tillNumber,
  });

export const getTills = () =>
  axiosWithAuth.get(`${HOST_API}${RESOURCE_STORE_TILLS}/${getStoreId()}`);

export const getTillDetail = (tillId) =>
  axiosWithAuth.get(`${HOST_API}${RESOURCE_CASH_TILLS}/${tillId}`);

export const getStore = (country, storeNumber) => {
  const queryString = `?search=(address.country==${country} and storeNumber==${storeNumber})&fields=address,currencies,id,locale,name,phone,timezone`;

  return axios({
    baseURL: HOST_STOREVIEWS,
    headers: {
      'nike-api-caller-id': 'com.nike:test-caller-id',
    },
    method: 'get',
    timeout: 10000,
    url: `${RESOURCE_STOREVIEWS}${queryString}`,
  });
};
