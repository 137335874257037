import { z } from 'zod';

export const getBaseSchema = (fields) =>
  z.object({
    currentFloat: z
      .number()
      .nonnegative(fields.safeAmount.NONNEGATIVE)
      .max(fields.safeAmount.MAX_AMOUNT, fields.safeAmount.MAX),
    originalFloat: z.number(),
    varianceExplanation: z.string().optional(),
  });

export const getSchema = ({ fields }) =>
  getBaseSchema(fields).refine(({ currentFloat }) => {
    return currentFloat <= fields.safeAmount.MAX_AMOUNT;
  });

export const getVarianceReason = (difference, varianceConfigs) => {
  if (difference < 0) {
    return varianceConfigs.short;
  }
  if (difference > 0) {
    return varianceConfigs.over;
  }
  return varianceConfigs.none;
};
