import { nrPageAction, nrSetAttribute } from './nrBrowserApi';
import { NR_APP_NAME } from '../../constants/newrelic.const';

export function setApplicationAttrs(loginInformation) {
  // start new relic capture
  const countryStoreNum = `${loginInformation?.country}-${loginInformation?.storeNumber}`;
  nrSetAttribute('athlete', loginInformation?.athleteNumber);
  nrSetAttribute('authSource', NR_APP_NAME);
  nrSetAttribute('store-country', loginInformation?.country);
  nrSetAttribute('store-number', loginInformation?.storeNumber);
  nrSetAttribute('store-country-number', countryStoreNum);
  nrPageAction(`${NR_APP_NAME}-login`, {
    action: 'CASH Login',
  });
}
