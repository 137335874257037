import React from 'react';

import SafeLoanStatus from './SafeLoanStatus';
import StoreSafe from './StoreSafe';
import StoreTills from './StoreTills';
import './CashOverviewTable.css';

function CashOverviewTable() {
  return (
    <div className="ncss-container fixed-fluid mt10-sm">
      <div className="ncss-row">
        <div className="ncss-col-md-5 left">
          <StoreSafe />
          <SafeLoanStatus />
        </div>
        <div className="ncss-col-md-7">
          <StoreTills />
        </div>
      </div>
    </div>
  );
}

export default CashOverviewTable;
