export const validateResponseShape = (shape) => async (response) => {
  let json;

  try {
    json = await response.json();
    shape.parse(json);

    return json;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    throw error;
  }
};
