import PropTypes from 'prop-types';
import React from 'react';

export function Subheader({ children }) {
  return <h4 className="display-3 mb5-sm">{children}</h4>;
}

Subheader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
};
