import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setProfile } from './reducer';

export function useSetGlobalProfile() {
  const dispatch = useDispatch();

  return useCallback(
    (profile) => {
      dispatch(setProfile(profile));
    },
    [dispatch],
  );
}
