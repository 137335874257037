import { jwtDecode } from 'jwt-decode';

import { convertTimeFromMs, getDuration } from './dateTime';

export function decodeBermAccessToken(token) {
  const decoded = jwtDecode(token);
  return {
    athleteNumber: decoded?.nike_athleteLogin,
    country: decoded?.nike_country,
    expiresAt: decoded?.exp,
    storeId: decoded?.nike_storeId,
    storeNumber: decoded?.nike_store,
    storeRoles: decoded?.nike_storeRoles,
  };
}

export function decodeBermRefreshToken(token) {
  const decoded = jwtDecode(token);
  return {
    refreshExp: decoded?.exp,
  };
}

export function getExpirationDuration(current, expiration) {
  return getDuration(current, expiration);
}

export function isTokenExpiring(tokenExpiration, currentMs) {
  // calculate to time from now until the token expires, returns in ms
  const expiresInMs = getExpirationDuration(
    currentMs,
    convertTimeFromMs(JSON.parse(tokenExpiration)),
  );

  // convert expiration to seconds
  const expirationSeconds = Math.floor(expiresInMs / 1000);

  // seconds from token expiration to automate refresh
  return expirationSeconds < 90;
}

// export async function tokenRefresh() {
//   return Auth.reAuthorize();
// }
