import React from 'react';

import { useGetLocalization } from '../../hooks';

function LoadingMessage() {
  const getLocalization = useGetLocalization();
  return (
    <main className="ncss-col-sm-12 mt12-sm pt2-sm ta-sm-c">
      <p className="headline-4">{getLocalization('msg.loading')}</p>
    </main>
  );
}

export default LoadingMessage;
