import React from 'react';

// Icon credit to Diogo Marquetti (https://icon-icons.com/icon/pc-payment-dollar/122533)
function Tills() {
  return <svg height="21px" viewBox="0 -2 24.92 17.68" width="25px" xmlns="http://www.w3.org/2000/svg">
    <title>Tills</title>
    <g data-name="Camada 2" id="Camada_2">
      <g data-name="Camada 1" id="Camada_1-2">
        <path d="M22.42,0H1.5A1.5,1.5,0,0,0,0,1.5V13.38a1.5,1.5,0,0,0,1.5,1.5h10V17H8.83a1.34,1.34,0,0,0,0,2.69h6.25a1.34,1.34,0,0,0,0-2.69H12.46V14.88h10a1.5,1.5,0,0,0,1.5-1.5V1.5A1.5,1.5,0,0,0,22.42,0Zm-7,18.33a.34.34,0,0,1-.34.34H8.83a.34.34,0,0,1-.34-.34A.35.35,0,0,1,8.83,18h6.25A.35.35,0,0,1,15.43,18.33Zm7.49-5a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V1.5A.5.5,0,0,1,1.5,1H22.42a.5.5,0,0,1,.5.5Z" fill="#000000" />
        <path d="M13.56,6.86a6,6,0,0,0-1.24-.39,6.73,6.73,0,0,1-.86-.26A1.13,1.13,0,0,1,11,5.92a.58.58,0,0,1-.14-.39A.6.6,0,0,1,11.16,5a1.35,1.35,0,0,1,.76-.18,1.33,1.33,0,0,1,.73.19.71.71,0,0,1,.35.47.25.25,0,0,0,.25.16h.83a.18.18,0,0,0,.18-.18A1.4,1.4,0,0,0,14,4.77a1.94,1.94,0,0,0-.62-.6,2.42,2.42,0,0,0-1-.34V3.21A.2.2,0,0,0,12.23,3h-.54a.21.21,0,0,0-.15.06.2.2,0,0,0-.06.15v.6a2.3,2.3,0,0,0-1.35.58,1.54,1.54,0,0,0-.49,1.16,1.42,1.42,0,0,0,.48,1.16,3.72,3.72,0,0,0,1.52.65q.62.17.94.28a1.32,1.32,0,0,1,.48.28.53.53,0,0,1,.16.39.64.64,0,0,1-.31.56,1.67,1.67,0,0,1-.92.21,1.56,1.56,0,0,1-.85-.2.94.94,0,0,1-.41-.5.4.4,0,0,0-.11-.13.29.29,0,0,0-.16,0H9.65a.19.19,0,0,0-.14.06.18.18,0,0,0-.06.13,1.48,1.48,0,0,0,.25.76,1.84,1.84,0,0,0,.68.6,2.82,2.82,0,0,0,1.08.31v.61a.2.2,0,0,0,.06.15.21.21,0,0,0,.15.06h.54a.2.2,0,0,0,.21-.21v-.61a2.61,2.61,0,0,0,1.48-.57,1.55,1.55,0,0,0,.55-1.23,1.52,1.52,0,0,0-.22-.85A1.62,1.62,0,0,0,13.56,6.86Z" fill="#000000" />
      </g>
    </g>
  </svg>
}

export default Tills;
