import { zodResolver } from '@hookform/resolvers/zod';
import Big from 'big.js';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import BankDepositConfirmationModal from './BankDepositConfirmationModal';
import { getSchema, getVarianceReason } from './validation';
import { PATH_SAFE_RECONCILIATION } from '../../../../constants/routes.const';
import {
  useGetProfile,
  useGetStoreInfo,
  useGetBankDepositCount,
  useSetGlobalBankDepositCount,
} from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import {
  useBankDepositMutation,
  useGetDepositAmountFromStoreIDQuery,
  useGetExistingDepositSlipsQuery,
} from '../../../../redux/services/store/storeService';
import { getFormattedCurrency } from '../../../../utils/currencyUtils';
import {
  ID_DEPOSIT_AMOUNT,
  ID_DEPOSIT_SLIP,
  ID_VARIANCE_REASONS,
  ID_PROCEED_TO_SAFE_RECONCILIATION_BUTTON,
  ID_VARIANCE_EXPLANATION,
} from '../../../../utils/dom-element-ids';
import { getMaxAmountForCountry } from '../../../../utils/getMaxAllowableAmount';
import { TextField, MultipleCurrencyInput } from '../../../common';
import { FormWrapper } from '../../../common/FormWrapper';
import { ButtonSubmit, Subheader, VarianceReasonModal } from '../../../reusable';

import '../../../reusable/VarianceReasonRow.css';

export function BankDeposit() {
  const getLocalization = useGetLocalization();
  const { country, storeId } = useGetProfile();
  const { currency, locale } = useGetStoreInfo();

  const location = useLocation();
  const { isEndOfDayProcess = false } = location.state || {};

  const validationConfig = {
    fields: {
      depositSlip: {
        MIN: `${getLocalization('lbl.deposit-slip')} ${getLocalization('msg.is-a-required-field')}`,
      },
      depositAmount: {
        NONNEGATIVE: getLocalization('msg.number-must-be-positive'),
        MAX: getLocalization('msg.number-exceeds-max'),
        MAX_AMOUNT: getMaxAmountForCountry(country),
      },
    },
    variance: {
      short: {
        VALUE: 'Short',
        LABEL: getLocalization('lbl.variance-short'),
      },
      over: {
        VALUE: 'Over',
        LABEL: getLocalization('lbl.variance-over'),
      },
      none: {
        VALUE: 'No Variance',
        LABEL: getLocalization('lbl.no-variance'),
      },
    },
    deposit: {
      slipDuplicate: {
        MESSAGE: getLocalization('msg.duplicate-deposit-slip'),
      },
    },
  };

  const defaultValues = {
    depositSlip: '0',
    depositAmount: 0,
    varianceExplanation: '',
    varianceReason: validationConfig.variance.none.REASON,
  };

  const BANK_DEPOSIT = getLocalization('lbl.bank-deposit');

  const {
    data: {
      amountBackToSafeLocal: amountBackToSafe,
      currencyCode = currency,
      newDepositAmount,
    } = {},
    isLoading: isGetDepositAmountLoading,
    error: getDepositAmountError,
  } = useGetDepositAmountFromStoreIDQuery({
    safeId: `${storeId}-888-${currency}`,
    storeId,
    currency,
  });

  const {
    data: existingDepositSlips,
    error: getExistingDepositSlipsError,
    isLoading: isGetExistingDepositSlipsLoading,
  } = useGetExistingDepositSlipsQuery();

  const [
    bankDeposit,
    { error: submitError, isSuccess: isSubmitSuccess, isLoading: isSubmitLoading },
  ] = useBankDepositMutation();

  const form = useForm({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(getSchema(validationConfig)),
  });

  const {
    watch,
    control,
    setValue,
    // eslint-disable-next-line no-unused-vars
    formState: { isSubmitSuccessful },
  } = form;
  const formDepositAmount = watch('depositAmount', Number(Big(0)));
  const formExpectedDepositAmount = watch('expectedDepositAmount', Number(Big(0)));

  useEffect(() => {
    setValue('existingDepositSlips', existingDepositSlips);
    setValue('currencyCode', currencyCode);
    setValue('amountBackToSafe', amountBackToSafe);
    setValue('depositAmount', newDepositAmount);
    setValue('expectedDepositAmount', newDepositAmount);
  }, [existingDepositSlips, currencyCode, amountBackToSafe, newDepositAmount, setValue]);

  const replace = useNavigate();
  const submit = () => replace(PATH_SAFE_RECONCILIATION, { state: { isEndOfDayProcess } });

  const bankDepositCount = useGetBankDepositCount();
  const setBankDepositCount = useSetGlobalBankDepositCount();

  const handleSubmit = (data) => {
    const {
      depositAmount,
      depositSlip,
      expectedDepositAmount,
      varianceExplanation,
      amountBackToSafe: amountBack,
    } = data;

    const varianceReason = getVarianceReason(
      depositAmount - expectedDepositAmount,
      validationConfig.variance,
    ).VALUE;

    bankDeposit({
      currencyCode,
      depositAmount,
      depositSlip,
      expectedDepositAmount,
      varianceExplanation,
      varianceReason,
      amountBackToSafe: amountBack,
    });
    setBankDepositCount(bankDepositCount + 1);
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{BANK_DEPOSIT}</Subheader>

      <FormWrapper
        crossFieldValidationArray={['currentFloat']}
        fetchErrors={[getDepositAmountError, getExistingDepositSlipsError]}
        form={form}
        loadingFlags={[isGetDepositAmountLoading, isGetExistingDepositSlipsLoading]}
        submit={{
          error: submitError,
          handler: (data) => handleSubmit(data),
          isLoading: isSubmitLoading,
          isSuccess: isSubmitSuccess,
          label: getLocalization('lbl.deposit-to-bank'),
          disableAfter: true,
        }}
      >
        <TextField
          shouldMakeActive
          control={control}
          id={ID_DEPOSIT_SLIP}
          label={getLocalization('lbl.deposit-slip')}
          name="depositSlip"
          requiredIndicator="*"
        />
        <MultipleCurrencyInput
          isRequired
          control={control}
          id={ID_DEPOSIT_AMOUNT}
          label={getLocalization('lbl.amount')}
          name="depositAmount"
          supportText={`${getLocalization('lbl.change')}: ${getFormattedCurrency(
            locale,
            currencyCode,
            formDepositAmount - formExpectedDepositAmount,
          )}`}
        />
        <div className="variance-reason-row pb3-sm">
          <TextField
            readOnly
            control={control}
            id={ID_VARIANCE_REASONS}
            label={getLocalization('lbl.variance-reason')}
            name="varianceReason"
            value={
              getVarianceReason(
                formDepositAmount - formExpectedDepositAmount,
                validationConfig.variance,
              ).LABEL
            }
          />
          <div className="pb3-sm">
            <VarianceReasonModal
              className="dialogModal"
              modalTitle={getLocalization('lbl.variance-reason-definitions')}
            />
          </div>
        </div>
        <TextField
          control={control}
          shouldMakeActive
          type="string"
          name="varianceExplanation"
          id={ID_VARIANCE_EXPLANATION}
          label={getLocalization('lbl.variance-explanation')}
        />
      </FormWrapper>
      {isEndOfDayProcess &&
        (bankDepositCount > 0 || !newDepositAmount ? (
          <ButtonSubmit
            id={ID_PROCEED_TO_SAFE_RECONCILIATION_BUTTON}
            label={getLocalization('lbl.proceed-to-safe-reconciliation')}
            onSubmit={submit}
          />
        ) : (
          <BankDepositConfirmationModal modalTitle="" submit={submit} />
        ))}
    </article>
  );
}
