import React, { useEffect, useState } from 'react';

import TillOutForm from './TillOutForm';
import { useGetLocalization } from '../../../../hooks';
import { updateItemById } from '../../../../utils/arrayUtils';
import { updateTillPayload } from '../../../../utils/dataUtils';
import { getTills, getTillDetail, tillOut } from '../../../../utils/service-calls';
import { isTillActive } from '../../../../utils/tillUtils';
import { ErrorMessage, Subheader, TillSelect } from '../../../reusable';

const tillStatus = 'CLOSED';

function TillOut() {
  const getLocalization = useGetLocalization();
  const SUBMIT_SUCCESS = getLocalization('msg.closed-till-successfully');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.till-closure-failed')}: ${message}`;

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState(null);

  const [selectedTill, setSelectedTill] = useState(null);
  const [retrievingTills, setRetrievingTills] = useState(true);
  const [retrievedTillError, setRetrievedTillError] = useState(null);

  const [tillData, setTillData] = useState({
    currencyCode: '',
    status: '',
    tillNumber: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formError, setFormError] = useState('');

  const filterTills = (tills) =>
    tills.filter(isTillActive).filter((till) => till.workstationNumber);

  const retrieveTillsForTillOut = (data) => {
    const updatedPayload = updateTillPayload(data, tillStatus);
    const updatedTills = updateItemById(availableTills, updatedPayload);
    setAvailableTills(filterTills(updatedTills));
  };

  useEffect(() => {
    const fetchData = async () =>
      getTills()
        .then(({ data }) => setAvailableTills(filterTills(data.tills)))
        .catch((err) => setAvailableTillsError(err))
        .finally(() => setRetrievingTills(false));
    fetchData();
  }, []);

  const retrieveSelectedTill = (tillId) => {
    setSubmitSuccess('');
    setSelectedTill(null);
    setSubmitLoading(true);
    return getTillDetail(tillId)
      .then(({ data }) => {
        const { currencyCode, tillNumber, status } = data;
        setTillData({
          currencyCode,
          status,
          tillNumber,
        });
        return setSelectedTill(tillId);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    setFormError(availableTillsError || retrievedTillError || submitError);
    setSubmitDisabled(!!(availableTillsError || retrievedTillError || submitError));
  }, [availableTillsError, retrievedTillError, submitError]);

  const submit = () => {
    setSubmitError('');
    setSubmitLoading(true);
    setSubmitDisabled(true);

    return tillOut(tillData)
      .then(async (res) => {
        await retrieveTillsForTillOut(res.data);
        setSubmitDisabled(false);
        return setSubmitSuccess(SUBMIT_SUCCESS);
      })
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))

      .finally(() => setSubmitLoading(false));
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.till-out')}</Subheader>
      {availableTillsError ? (
        <ErrorMessage rawError={availableTillsError}>
          {getLocalization('msg.there-was-an-error-loading-tills')}
        </ErrorMessage>
      ) : (
        <TillSelect
          availableTills={availableTills}
          retrieveSelectedTill={retrieveSelectedTill}
          retrievingTills={retrievingTills}
          selectedTill={selectedTill}
        />
      )}
      {retrievedTillError ? (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.there-was-an-error-loading-requested-till')}
        </ErrorMessage>
      ) : (
        selectedTill && (
          <TillOutForm
            formErrors={formError}
            isDisabled={submitDisabled}
            submit={submit}
            submitError={submitError}
            submitLoading={submitLoading}
            submitSuccess={submitSuccess}
          />
        )
      )}
    </article>
  );
}

export default TillOut;
