import {
  ButtonStyled,
  DialogStyled,
  toggleSiblingNodes,
  IconButton,
  TextStyled,
} from '@nike/nike-design-system-components';
import { Info } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_MODAL_ROOT, ID_OPEN_MODAL_SUBMIT_BUTTON } from '../../../../utils/dom-element-ids';

function TillSummaryModal({ modalTitle }) {
  const getLocalization = useGetLocalization();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, true, undefined);
  };

  const closeModal = () => {
    setIsOpen(false);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, false, undefined);
  };

  const actions = () => (
    <DialogStyled>
      <ButtonStyled appearance="primary" ariaLabel="close-dialog" onClick={closeModal}>
        Close Dialog
      </ButtonStyled>
    </DialogStyled>
  );
  return (
    <>
      <IconButton
        Icon={<Info />}
        ariaLabel="info button"
        id={ID_OPEN_MODAL_SUBMIT_BUTTON}
        size="small"
        type="button"
        onClick={openModal}
      />
      <DialogStyled
        className="dialogModal"
        closeModal={closeModal}
        footerContent={actions}
        isOpen={isOpen}
        slots={{ FooterSlot: actions }}
        title={modalTitle}
      >
        <div className="ta-sm-l">
          <TextStyled appearance="heading4" bold="true" display="inline">
            {`${getLocalization('msg.till-status-active-header')}`}{' '}
          </TextStyled>
          {`${getLocalization('msg.till-status-active')}`}
          <br />
          <TextStyled appearance="heading4" bold="true" display="inline">{`${getLocalization(
            'msg.till-status-pickedup-header',
          )}`}</TextStyled>
          {`${getLocalization('msg.till-status-pickedup')}`}
          <br />
          <TextStyled appearance="heading4" bold="true" display="inline">{`${getLocalization(
            'msg.till-status-closed-header',
          )}`}</TextStyled>
          {`${getLocalization('msg.till-status-closed')}`}
        </div>
      </DialogStyled>
    </>
  );
}

TillSummaryModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
};

export default TillSummaryModal;
