import { match, Default } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ErrorMessage, MultipleCurrencyText, Table } from '../../../reusable';
import { GetMultipleCurrencyText } from '../../../reusable/MultipleCurrencyText';

function OpenTableItem({ item, className }) {
  const getLocalization = useGetLocalization();
  return (
    <div className={className}>
      {item.varianceReason && (
        <div>
          {getLocalization('lbl.reason')}: {item.varianceReason}
        </div>
      )}
      {item.currentFloatBeforeReconciliation && item.currentFloatAfterReconciliation && (
        <>
          <div>
            {getLocalization('lbl.expected')}
            {`: `}
            <MultipleCurrencyText value={item.currentFloatBeforeReconciliation} />
          </div>
          <div>
            {getLocalization('lbl.actual')}
            {`: `}
            <MultipleCurrencyText value={item.currentFloatAfterReconciliation} />
          </div>
        </>
      )}
    </div>
  );
}

OpenTableItem.defaultProps = {
  className: '',
};

OpenTableItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    currentFloatAfterReconciliation: PropTypes.number.isRequired,
    currentFloatBeforeReconciliation: PropTypes.number.isRequired,
    varianceReason: PropTypes.string.isRequired,
  }).isRequired,
};

function Summaries({ data }) {
  const getLocalization = useGetLocalization();
  return data.length === 0 ? (
    <ErrorMessage>{getLocalization('msg.there-are-no-safe-reconciliations')}</ErrorMessage>
  ) : (
    <Table
      Open={OpenTableItem}
      columns={[
        {
          header: getLocalization('lbl.date'),
          processor: ({ createTime }) => {
            const date = new Date(createTime);
            return `${date.getMonth() + 1}/${date.getDate()}/${date
              .getFullYear()
              .toString()
              .slice(2)}`;
          },
          width: 3,
        },
        {
          getClassName: ({ variance }) => {
            const color = match()(
              [() => variance > 0, 'text-color-success'],
              [() => variance < 0, 'text-color-error'],
              [Default, 'text-color-primary-dark'],
            );
            return `ta-sm-r ${color}`;
          },
          header: getLocalization('lbl.variance'),
          headerClassName: 'ta-sm-r',
          processor: ({ variance }) => {
            const num = GetMultipleCurrencyText(variance);
            return `${num}`;
          },
          width: 3,
        },
        {
          header: getLocalization('lbl.explanation'),
          headerClassName: 'ta-sm-r',
          processor: ({ varianceExplanation }) => varianceExplanation,
          width: 3,
        },
        {
          header: getLocalization('lbl.athlete'),
          processor: ({ athleteName }) => {
            const names = athleteName.split(' ');
            const firstInitial = names[0].slice(0, 1);
            const lastName = names[names.length - 1];
            return `${firstInitial} ${lastName}`;
          },
          width: 3,
        },
      ]}
      data={data}
      title={getLocalization('lbl.summaries')}
    />
  );
}

Summaries.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Summaries;
