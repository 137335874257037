import { localeInfo } from '../constants/locale.const';
import { useGetProfile } from '../globals';

export function useLocalCurrency() {
  const { country } = useGetProfile();
  const {
    currency: { prefix, postfix },
  } = localeInfo[country];
  return {
    postfix,
    prefix,
  };
}
