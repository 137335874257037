import { match, Default } from '@nike/rcf-fp';
import { format, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';

import BankSummaryForm from './BankSummaryForm';
import BankSummaryTable from './BankSummaryTable';
import { useGetProfile } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import { getBankSummaries } from '../../../../utils/service-calls';
import { getLocalEndDateToUTC, getLocalStartDateToUTC } from '../../../../utils/timeUtils';
import { ErrorMessage, Subheader } from '../../../reusable';

const DATE_FORMAT = 'yyyy-MM-dd';

const formatDate = (date) => format(date, DATE_FORMAT);

function BankSummary() {
  const getLocalization = useGetLocalization();
  const { country } = useGetProfile();
  // Contextual state
  const now = new Date();
  const today = formatDate(now);
  const maxDate = today; // Can't be a future date
  const minDate = formatDate(subMonths(now, 13)); // Can't be more than 13 months in the past;

  // React state
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [formState, setFormState] = useState('INITIAL'); // INITIAL, SUBMITTING, SUBMIT_SUCCESS, SUBMIT_ERROR
  const [bankSummaries, setBankSummaries] = useState([]);
  const [submissionError, setSubmissionError] = useState('');

  useEffect(() => {
    const resetBankSummaries = () => {
      setSubmissionError('');
      setBankSummaries([]);
    };
    const handleSubmitSuccess = (newSummaries) => {
      setFormState('SUBMIT_SUCCESS');
      setBankSummaries(newSummaries);
    };
    const handleSubmitError = (err) => {
      setFormState('SUBMIT_ERROR');
      setSubmissionError(err);
    };
    if (formState === 'SUBMITTING') {
      resetBankSummaries();
      getBankSummaries(getLocalEndDateToUTC(endDate), getLocalStartDateToUTC(startDate))
        .then(handleSubmitSuccess)
        .catch(handleSubmitError);
    }
  }, [endDate, startDate, formState]);

  return (
    <>
      <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
        <Subheader>{getLocalization('lbl.bank-summary')}</Subheader>
        <BankSummaryForm
          country={country}
          dateFormat={DATE_FORMAT}
          endDate={endDate}
          formState={formState}
          maxDate={maxDate}
          minDate={minDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          onSubmit={() => setFormState('SUBMITTING')}
        />
      </article>
      <br />
      <article className="va-sm-m ta-sm-c pb2-sm u-full-width">
        {match({ bankSummaries, formState })(
          [
            () => formState === 'SUBMIT_SUCCESS' && bankSummaries.length > 0,
            () => <BankSummaryTable bankSummaries={bankSummaries} />,
          ],
          [
            () => formState === 'SUBMIT_SUCCESS',
            () => (
              <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
                <ErrorMessage>{getLocalization('msg.no-results-found')}</ErrorMessage>
              </article>
            ),
          ],
          [Default, () => null],
        )}
        {formState === 'SUBMIT_ERROR' ? (
          <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
            <ErrorMessage rawError={submissionError}>
              {getLocalization('msg.an-error-occurred-please-try-again')}
            </ErrorMessage>
          </article>
        ) : null}
      </article>
    </>
  );
}

export default BankSummary;
