import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setBankDepositCount } from './reducer';

export function useSetGlobalBankDepositCount() {
  const dispatch = useDispatch();

  return useCallback(
    (count) => {
      dispatch(setBankDepositCount(count));
    },
    [dispatch],
  );
}
