import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_ADJUSTMENT_AMOUNT, ID_SAFE_NUMBER } from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function SafeReceiptForm({
  safeDataError,
  safeName,
  safeNumber,
  currencyCode,
  adjustmentAmountError,
  currentFloatMessage,
  setAdjustmentAmount,
  setCurrencyCode,
  adjustmentAmount,
  isDisabled,
  submitLoading,
  submitError,
  submitSuccess,
  submit,
  responseError,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <TextFieldStyled
        readOnly
        error={!!safeDataError}
        errorMessage={safeDataError}
        id={ID_SAFE_NUMBER}
        label={getLocalization('lbl.safe-number')}
        name="safeNumber"
        placeholder={`${getLocalization('lbl.safe')}: ${safeName} ${getLocalization(
          'lbl.number',
        )}: ${safeNumber}`}
      />
      <MultipleCurrencyInput
        currency={currencyCode}
        error={!!adjustmentAmountError}
        errorMessage={adjustmentAmountError}
        id={ID_ADJUSTMENT_AMOUNT}
        label={getLocalization('lbl.adjust-amount')}
        message={currentFloatMessage}
        name="adjustmentAmount"
        setAmount={setAdjustmentAmount}
        setCurrency={setCurrencyCode}
        showErrorMessage={!!adjustmentAmountError}
        value={adjustmentAmount}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled || submitLoading || !!submitSuccess}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.safe-receipt')}
        messageError={`${responseError} ${submitError}`}
        messageSuccess={submitError || responseError ? emptyString : submitSuccess}
        onSubmit={submit}
      />
    </>
  );
}

SafeReceiptForm.propTypes = {
  adjustmentAmount: PropTypes.number.isRequired,
  adjustmentAmountError: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  currentFloatMessage: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  responseError: PropTypes.string.isRequired,
  safeDataError: PropTypes.string.isRequired,
  safeName: PropTypes.string.isRequired,
  safeNumber: PropTypes.number.isRequired,
  setAdjustmentAmount: PropTypes.func.isRequired,
  setCurrencyCode: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
};

export default SafeReceiptForm;
