import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setTokens } from './reducer';

export function useSetGlobalTokens() {
  const dispatch = useDispatch();

  return useCallback(
    (tokens) => {
      dispatch(setTokens(tokens));
    },
    [dispatch],
  );
}
