import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import { useGetLocalization } from '../../../../hooks';
import { tillAudit, getTills, getTillDetail } from '../../../../utils/service-calls';
import { retrieveOpenTills } from '../../../../utils/tillUtils';
import { ErrorMessage, Subheader, TillSelect } from '../../../reusable';
import TillAuditForm from './TillAuditForm';

function TillAudit() {
  const getLocalization = useGetLocalization();

  const GREATER_THAN_ZERO = getLocalization('msg.must-be-greater-than-zero');
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const SUBMIT_SUCCESS = getLocalization('msg.till-audit-submit-success');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.till-audit-submit-failed')}: ${message}`;

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState(null);

  const [retrievedTillError, setRetrievedTillError] = useState(null);
  const [retrievingTills, setRetrievingTills] = useState(true);

  const [selectedTill, setSelectedTill] = useState(null);
  const [currencyCode, setCurrencyCode] = useState('');
  const [tillNumber, setTillNumber] = useState('');
  const [originalCurrentFloat, setOriginalCurrentFloat] = useState();
  const [newCurrentFloat, setNewCurrentFloat] = useState();
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getTills()
      .then(({ data }) => setAvailableTills(retrieveOpenTills(data.tills)))
      .catch((err) => setAvailableTillsError(err))
      .finally(() => setRetrievingTills(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentFloatError = (float) =>
    match()(
      [() => typeof float !== 'number', REQUIRED_FIELD],
      [() => float < 0, GREATER_THAN_ZERO],
      [Default, ''],
    );

  const submit = () => {
    setSubmitLoading(true);

    tillAudit({
      currencyCode,
      newCurrentFloat,
      tillNumber,
    })
      .then(() => setSubmitSuccess(SUBMIT_SUCCESS))
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  const getVarianceMessage = (original, modified) =>
    `${getLocalization('lbl.variance')}: $${(modified - original).toFixed(2)}`;
  const getVarianceMessageColor = (original, modified) =>
    match()(
      [() => original - modified > 0, '#D43F21'],
      [() => original - modified < 0, '#128A09'],
      [Default, ''],
    );

  const retrieveSelectedTill = (tillId) => {
    setSelectedTill(null);
    setSubmitLoading(true);
    setRetrievedTillError(null);

    return getTillDetail(tillId)
      .then(({ data }) => {
        setCurrencyCode(data.currencyCode);
        setTillNumber(data.tillNumber);
        setOriginalCurrentFloat(data.currentFloat);
        return setSelectedTill(tillId);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.till-audit')}</Subheader>
      {availableTillsError ? (
        <ErrorMessage rawError={availableTillsError}>
          {getLocalization('msg.there-was-an-error-loading-tills')}
        </ErrorMessage>
      ) : (
        <TillSelect
          availableTills={availableTills}
          retrieveSelectedTill={retrieveSelectedTill}
          retrievingTills={retrievingTills}
          selectedTill={selectedTill}
        />
      )}
      {retrievedTillError ? (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.there-was-an-error-loading-requested-till')}
        </ErrorMessage>
      ) : (
        selectedTill && (
          <TillAuditForm
            auditTill={submit}
            currency={currencyCode}
            isDisabled={!!getCurrentFloatError(newCurrentFloat) || submitLoading}
            newCurrentFloat={newCurrentFloat}
            newCurrentFloatError={getCurrentFloatError(newCurrentFloat)}
            responseError={getCurrentFloatError(newCurrentFloat) ? ERRORS : ''}
            setCurrency={setCurrencyCode}
            setNewCurrentFloat={setNewCurrentFloat}
            submitError={submitError}
            submitLoading={submitLoading}
            submitSuccess={submitSuccess}
            varianceMessage={getVarianceMessage(originalCurrentFloat, newCurrentFloat)}
            varianceMessageColor={getVarianceMessageColor(originalCurrentFloat, newCurrentFloat)}
          />
        )
      )}
    </article>
  );
}

export default TillAudit;
