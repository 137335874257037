// eslint-disable-next-line prefer-destructuring
export const HOST_API = process.env.HOST_API;
// eslint-disable-next-line prefer-destructuring
export const HOST_STOREVIEWS = process.env.HOST_STOREVIEWS;
// eslint-disable-next-line prefer-destructuring
export const NAME = process.env.NAME;
export const UI_NAME = 'NIKE CASH';
export const DEV = 'development';
export const PROD = 'production';
export const LOCAL = 'local';
export const TEST = 'test';
export const IS_PROD = NAME === PROD;
export const IS_DEV = NAME === DEV;
export const IS_LOCAL = NAME === LOCAL;
export const IS_TEST = NAME === TEST;
