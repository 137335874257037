import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import {
  PATH_ACCEPT_LOAN,
  PATH_BANKING,
  PATH_BANK_DEPOSIT,
  PATH_BANK_SUMMARY,
  PATH_CASH,
  PATH_CASH_PICKUP,
  PATH_CLOSE_STORE,
  PATH_END_OF_DAY,
  PATH_FRONT_OFFICE_HOME,
  PATH_PICKUP_DECLARATION,
  PATH_PROFILE,
  PATH_RECONCILIATION_SUMMARIES,
  PATH_SAFES,
  PATH_SAFE_ADD,
  PATH_SAFE_ADJUST,
  PATH_SAFE_LOAN,
  PATH_SAFE_RECEIPT,
  PATH_SAFE_RECONCILIATION,
  PATH_TILLS,
  PATH_TILL_ADD,
  PATH_TILL_ADJUST,
  PATH_TILL_ASSOCIATION,
  PATH_TILL_AUDIT,
  PATH_TILL_IN,
  PATH_TILL_OUT,
  PATH_TILL_STATUS,
  PATH_TILL_VARIANCE_SUMMARY,
  PATH_UNAUTHORIZED,
} from '../../constants/routes.const';
import getIsFeatureEnabled from '../../utils/get-is-feature-enabled';
import Banking, { BankDeposit, BankSummary } from '../back-office/bank';
import CloseStore from '../back-office/CloseStore';
import EndOfDay from '../back-office/end-of-day';
import Safes, {
  AddSafe,
  AdjustSafe,
  ReconcileSafe,
  ReconciliationSummaries,
  SafeLoan,
  SafeReceipt,
} from '../back-office/safe';
import {
  Tills,
  AcceptLoan,
  AddTill,
  AdjustTill,
  CashPickup,
  PickupDeclaration,
  TillAssociation,
  TillAudit,
  TillIn,
  TillOut,
  TillVarianceSummary,
} from '../back-office/till';
import TillStatus from '../back-office/till/till-status';
import Cash from '../Cash';
import NavBar from '../navigation';
import PrivateRouteWrapper from '../private-route';
import Profile from '../profile';
import RouteInvalid from '../route-invalid';
import Unauthorized from '../Unauthorized';

const isBankingEnabled = getIsFeatureEnabled('banking');
const isSafesEnabled = getIsFeatureEnabled('safes');
const isTillsEnabled = getIsFeatureEnabled('tills');
const isEodEnabled = getIsFeatureEnabled('eod');

function RouteContainer() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<NavBar />} />
      </Routes>
      <main className="ncss-col-sm-12 ta-sm-c p6-sm mb12-sm">
        <Routes>
          {/* CASH */}
          <Route path="/" element={<PrivateRouteWrapper element={<Cash />} />} />
          <Route path={PATH_CASH} element={<PrivateRouteWrapper element={<Cash />} />} />
          <Route
            path={PATH_FRONT_OFFICE_HOME}
            element={<PrivateRouteWrapper element={<Cash />} />}
          />
          <Route
            path={PATH_CLOSE_STORE}
            element={<PrivateRouteWrapper element={<CloseStore />} />}
          />
          <Route
            path={PATH_END_OF_DAY}
            element={<PrivateRouteWrapper element={<EndOfDay />} isEnabled={isEodEnabled} />}
          />

          {/* Profile */}
          <Route path={PATH_PROFILE} element={<PrivateRouteWrapper element={<Profile />} />} />

          {/* Banking */}
          <Route
            path={PATH_BANKING}
            element={<PrivateRouteWrapper element={<Banking />} isEnabled={isBankingEnabled} />}
          />
          <Route
            path={PATH_BANK_DEPOSIT}
            element={<PrivateRouteWrapper element={<BankDeposit />} isEnabled={isBankingEnabled} />}
          />
          <Route
            path={PATH_BANK_SUMMARY}
            element={<PrivateRouteWrapper element={<BankSummary />} isEnabled={isBankingEnabled} />}
          />

          {/* Safes */}
          <Route
            path={PATH_SAFES}
            element={<PrivateRouteWrapper element={<Safes />} isEnabled={isSafesEnabled} />}
          />
          <Route
            path={PATH_SAFE_ADD}
            element={<PrivateRouteWrapper element={<AddSafe />} isEnabled={isSafesEnabled} />}
          />
          <Route
            path={PATH_SAFE_ADJUST}
            element={<PrivateRouteWrapper element={<AdjustSafe />} isEnabled={isSafesEnabled} />}
          />
          <Route
            path={PATH_SAFE_RECONCILIATION}
            element={<PrivateRouteWrapper element={<ReconcileSafe />} isEnabled={isSafesEnabled} />}
          />
          <Route
            path={PATH_SAFE_RECEIPT}
            element={<PrivateRouteWrapper element={<SafeReceipt />} isEnabled={isSafesEnabled} />}
          />
          <Route
            path={PATH_SAFE_LOAN}
            element={<PrivateRouteWrapper element={<SafeLoan />} isEnabled={isSafesEnabled} />}
          />
          <Route
            path={PATH_RECONCILIATION_SUMMARIES}
            element={
              <PrivateRouteWrapper
                element={<ReconciliationSummaries />}
                isEnabled={isSafesEnabled}
              />
            }
          />

          {/* Tills */}
          <Route
            path={PATH_TILLS}
            element={<PrivateRouteWrapper element={<Tills />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_ADD}
            element={<PrivateRouteWrapper element={<AddTill />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_ADJUST}
            element={<PrivateRouteWrapper element={<AdjustTill />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_CASH_PICKUP}
            element={<PrivateRouteWrapper element={<CashPickup />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_ASSOCIATION}
            element={
              <PrivateRouteWrapper element={<TillAssociation />} isEnabled={isTillsEnabled} />
            }
          />
          <Route
            path={PATH_PICKUP_DECLARATION}
            element={
              <PrivateRouteWrapper element={<PickupDeclaration />} isEnabled={isTillsEnabled} />
            }
          />
          <Route
            path={PATH_ACCEPT_LOAN}
            element={<PrivateRouteWrapper element={<AcceptLoan />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_AUDIT}
            element={<PrivateRouteWrapper element={<TillAudit />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_IN}
            element={<PrivateRouteWrapper element={<TillIn />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_STATUS}
            element={<PrivateRouteWrapper element={<TillStatus />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_OUT}
            element={<PrivateRouteWrapper element={<TillOut />} isEnabled={isTillsEnabled} />}
          />
          <Route
            path={PATH_TILL_VARIANCE_SUMMARY}
            element={
              <PrivateRouteWrapper element={<TillVarianceSummary />} isEnabled={isTillsEnabled} />
            }
          />

          {/* Other */}
          <Route path={PATH_UNAUTHORIZED} element={<Unauthorized />} />
          <Route path="*" element={<RouteInvalid />} />
        </Routes>
      </main>
    </Router>
  );
}

export default RouteContainer;
