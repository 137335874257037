import { useCallback } from 'react';

import { useGetRoles } from '../globals';
import { hasRole } from '../utils/roleUtils';

export function useHasRequiredRole() {
  const nikeStoreRoles = useGetRoles();
  return useCallback(
    (allowedRoles) => {
      return hasRole(allowedRoles, nikeStoreRoles);
    },
    [nikeStoreRoles],
  );
}
