import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import isString from 'lodash.isstring';
import PropTypes from 'prop-types';
import React from 'react';

function isEmptyString(value) {
  return isString(value) && isEmpty(value.trim());
}

function stringIsValid(value) {
  return !(isNil(value) || isEmpty(value) || isEmptyString(value));
}

export default function ErrorMessage(props) {
  if (!stringIsValid(props.children)) {
    return null;
  }

  return (
    <p
      className="bg-error u-rounded p2-sm prl4-sm mt4-sm mb4-sm text-color-primary-light ta-sm-c"
      style={{ position: 'relative' }}
    >
      <i className="g72-alert mr2-sm" /> {props.children}
      {/* Optionally show a network error response code */}
      {props.rawError?.response?.status ? (
        <span className="pl2-sm" style={{ opacity: 0.6 }}>
          [{props.rawError.response.status}]
        </span>
      ) : null}
    </p>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  rawError: PropTypes.shape({
    message: PropTypes.string,
    // `response` is found on Axios errors
    response: PropTypes.shape({
      status: PropTypes.number,
    }),
    stack: PropTypes.string,
  }),
};

ErrorMessage.defaultProps = {
  rawError: null,
};
