import { shouldUseNewRelic } from '../envUtils';

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/
// BROWSER METHODS

// newrelic.addPageAction(string $name[, JSON object $attributes])
export function nrPageAction(name, attr) {
  if (shouldUseNewRelic()) {
    window.newrelic.addPageAction(name, attr);
  }
}

// newrelic.addRelease(string $release_name, string $release_id)
// newrelic.addToTrace(JavaScript object $custom_object)
// newrelic.finished(time $time_stamp)

// newrelic.noticeError(error object $error, [object $customAttributes])
export function nrError(error) {
  if (shouldUseNewRelic()) {
    window.newrelic.noticeError(error);
  } else {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
}

// newrelic.setCustomAttribute(string $name, string OR number $value)
export function nrSetAttribute(name, val) {
  if (shouldUseNewRelic()) {
    window.newrelic.setCustomAttribute(name, val);
  }
}
// newrelic.setErrorHandler(function $callback)
// newrelic.setPageViewName(string $name[, string $host])

// SPA METHODS
// newrelic.interaction()
export function nrInteraction() {
  if (shouldUseNewRelic()) {
    return window.newrelic.interaction();
  }
  return null;
}

// newrelic.interaction().actionText(string $value)
// newrelic.interaction().createTracer(string $name[, function $callback])
// newrelic.interaction().end()
// newrelic.interaction().getContext(function $callback)
// newrelic.interaction().ignore()
// newrelic.interaction().onEnd(function $callback)
// newrelic.interaction().save()
// newrelic.interaction().setAttribute(string $key, any $value)
// newrelic.interaction().setName(string $name[, string $trigger])
// newrelic.setCurrentRouteName(string $name)
