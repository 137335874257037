import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import AddTillForm from './AddTillForm';
import { useGetStoreInfo } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import { addTill, getTills } from '../../../../utils/service-calls';
import { LoadingIndicator, Subheader } from '../../../reusable';

function AddTill() {
  const getLocalization = useGetLocalization();
  const { currency } = useGetStoreInfo();
  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const DEFAULT_FLOAT_LESS_THAN = getLocalization('msg.till-default-float-less-than');
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const INVALID_TILL_NUMBER = getLocalization('msg.invalid-till-number');
  const SUBMIT_SUCCESS = getLocalization('msg.till-add-submit-success');
  // TODO: refactor currencyCode to pull from global state
  const [currencyCode, setCurrencyCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [tillNumber, setTillNumber] = useState('');
  const [defaultFloat, setDefaultFloat] = useState();
  const [currentFloat, setCurrentFloat] = useState();
  const [tillCeiling, setTillCeiling] = useState();
  const [tillNumberError, setTillNumberError] = useState('');
  const [defaultFloatError, setDefaultFloatError] = useState('');
  const [currentFloatError, setCurrentFloatError] = useState('');
  const [tillCeilingError, setTillCeilingError] = useState('');
  const [responseError, setResponseError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState('');

  useEffect(() => {
    getTills()
      .then(({ data }) => {
        setCurrencyCode(currency);
        return setTillNumber(
          String(
            data.tills.length ? Math.max(...data.tills.map((till) => till.tillNumber)) + 1 : 1,
          ),
        );
      })
      .catch(({ message }) => setTillNumber(message))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitSuccess !== '') {
      setTillNumber((till) => String(Number(till) + 1));
    }
  }, [submitSuccess]);

  const submit = async () => {
    setSubmitLoading(true);
    setSubmitSuccess('');
    const tillToAdd = {
      currencyCode,
      currentFloat,
      defaultFloat,
      tillCeiling,
      tillNumber: Number(tillNumber),
    };
    const translateResponseError = (message) => {
      const isConflictMessage = (msg) => msg.includes('409');
      return match(message)(
        [
          isConflictMessage,
          `${getLocalization('msg.till-with-till-number')} ${tillNumber} ${getLocalization(
            'msg.already-exists',
          )}.`,
        ],
        [Default, (msg) => msg],
      );
    };

    await addTill(tillToAdd)
      .then(() => setSubmitSuccess(SUBMIT_SUCCESS))
      .catch(({ message }) => setResponseError(translateResponseError(message)))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    const tillNumberErrors = match()(
      [() => tillNumber === '', REQUIRED_FIELD],
      [() => !/^[0-9]+$/.test(tillNumber), INVALID_TILL_NUMBER],
      [Default, ''],
    );
    const defaultFloatErrors = match()(
      [() => typeof defaultFloat !== 'number', REQUIRED_FIELD],
      [() => defaultFloat > tillCeiling, DEFAULT_FLOAT_LESS_THAN],
      [Default, ''],
    );
    const currentFloatErrors = match()(
      [() => typeof currentFloat !== 'number', REQUIRED_FIELD],
      [() => currentFloat < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const tillCeilingErrors = match()(
      [() => typeof tillCeiling !== 'number', REQUIRED_FIELD],
      [() => tillCeiling < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    setResponseError(isDisabled ? ERRORS : '');
    setTillNumberError(tillNumberErrors);
    setTillCeilingError(tillCeilingErrors);
    setCurrentFloatError(currentFloatErrors);
    setDefaultFloatError(defaultFloatErrors);
    setIsDisabled(
      !!(tillCeilingErrors || currentFloatErrors || tillNumberErrors || defaultFloatErrors),
    );
  }, [
    currentFloat,
    defaultFloat,
    isDisabled,
    tillNumber,
    tillCeiling,
    REQUIRED_FIELD,
    POSITIVE_NUMBER,
    ERRORS,
    INVALID_TILL_NUMBER,
    DEFAULT_FLOAT_LESS_THAN,
  ]);

  return (
    <article className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.till-add')}</Subheader>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AddTillForm
          addTill={submit}
          currencyCode={currencyCode}
          currentFloat={currentFloat}
          currentFloatError={currentFloatError}
          defaultFloat={defaultFloat}
          defaultFloatError={defaultFloatError}
          isDisabled={isDisabled}
          responseError={responseError}
          setCurrencyCode={setCurrencyCode}
          setCurrentFloat={setCurrentFloat}
          setDefaultFloat={setDefaultFloat}
          setTillCeiling={setTillCeiling}
          setTillNumber={setTillNumber}
          submitLoading={submitLoading}
          submitSuccess={submitSuccess}
          tillCeiling={tillCeiling}
          tillCeilingError={tillCeilingError}
          tillNumber={tillNumber}
          tillNumberError={tillNumberError}
        />
      )}
    </article>
  );
}

export default AddTill;
