import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import AdjustTillForm from './AdjustTillForm';
import { useGetLocalization, useLocalCurrency } from '../../../../hooks';
import getFloatVarianceMessage from '../../../../utils/getFloatVarianceMessage';
import { adjustTill, getTills, getTillDetail } from '../../../../utils/service-calls';
import { retrieveClosedTills } from '../../../../utils/tillUtils';
import { ErrorMessage, Subheader, TillSelect, WarningMessage } from '../../../reusable';

function AdjustTill() {
  const getLocalization = useGetLocalization();
  const { postfix, prefix } = useLocalCurrency();

  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const GREATER_THAN_ZERO = getLocalization('msg.must-be-greater-than-zero');
  const DEFAULT_FLOAT_LESS_THAN = getLocalization(
    'msg.must-be-greater-than-or-equal-to-default-float',
  );
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const SUBMIT_SUCCESS = getLocalization('msg.adjusted-till');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.adjust-till-failed')}: ${message}`;

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState(null);

  const [retrievedTillError, setRetrievedTillError] = useState(null);
  const [retrievingTills, setRetrievingTills] = useState(true);

  const [selectedTill, setSelectedTill] = useState(null);
  const [currencyCode, setCurrencyCode] = useState('');
  const [tillNumber, setTillNumber] = useState('');
  const [defaultFloat, setDefaultFloat] = useState();
  const [newDefaultFloat, setNewDefaultFloat] = useState();
  const [newDefaultFloatError, setNewDefaultFloatError] = useState('');
  const [newTillCeiling, setNewTillCeiling] = useState();
  const [newTillCeilingError, setNewTillCeilingError] = useState('');
  const [responseError, setResponseError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getTills()
      .then(({ data }) => setAvailableTills(retrieveClosedTills(data.tills)))
      .catch((err) => setAvailableTillsError(err))
      .finally(() => setRetrievingTills(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newDefaultFloatErrors = match()(
      [() => typeof newDefaultFloat !== 'number', REQUIRED_FIELD],
      [() => newDefaultFloat < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const newTillCeilingErrors = match()(
      [() => typeof newTillCeiling !== 'number', REQUIRED_FIELD],
      [() => newDefaultFloat > newTillCeiling, DEFAULT_FLOAT_LESS_THAN],
      [() => newTillCeiling <= 0, GREATER_THAN_ZERO],
      [Default, ''],
    );
    setNewTillCeilingError(newTillCeilingErrors);
    setNewDefaultFloatError(newDefaultFloatErrors);
    setIsDisabled(!!(newTillCeilingErrors || newDefaultFloatErrors));
    setResponseError(newTillCeilingErrors || newDefaultFloatErrors ? ERRORS : '');
  }, [
    newDefaultFloat,
    newTillCeiling,
    DEFAULT_FLOAT_LESS_THAN,
    ERRORS,
    GREATER_THAN_ZERO,
    POSITIVE_NUMBER,
    REQUIRED_FIELD,
  ]);

  const submit = () => {
    setSubmitLoading(true);
    setSubmitError(null);

    adjustTill({
      currencyCode,
      floatVariance: newDefaultFloat - defaultFloat,
      newDefaultFloat,
      newTillCeiling,
      tillNumber,
    })
      .then(() => setSubmitSuccess(SUBMIT_SUCCESS))
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  const retrieveSelectedTill = (tillId) => {
    setSelectedTill(null);
    setRetrievedTillError(null);
    setSubmitLoading(true);

    return getTillDetail(tillId)
      .then(({ data }) => {
        setCurrencyCode(data.currencyCode);
        setTillNumber(data.tillNumber);
        setDefaultFloat(data.defaultFloat ? data.defaultFloat : 0);
        setNewDefaultFloat(data.defaultFloat);
        setNewTillCeiling(data.tillCeiling);
        return setSelectedTill(tillId);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => setSubmitLoading(false));
  };
  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.till-adjust')}</Subheader>
      {availableTills.length === 0 && (
        <WarningMessage>{getLocalization('msg.till-adjust-none-available')}</WarningMessage>
      )}
      {availableTillsError ? (
        <ErrorMessage rawError={availableTillsError}>
          {getLocalization('msg.error-loading-tills')}
        </ErrorMessage>
      ) : (
        <TillSelect
          availableTills={availableTills}
          retrieveSelectedTill={retrieveSelectedTill}
          retrievingTills={retrievingTills}
          selectedTill={selectedTill}
        />
      )}
      {retrievedTillError ? (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.error-loading-requested-till')}
        </ErrorMessage>
      ) : (
        selectedTill && (
          <AdjustTillForm
            adjustTill={submit}
            currency={currencyCode}
            floatVarianceMessage={getFloatVarianceMessage(
              defaultFloat,
              newDefaultFloat,
              getLocalization('lbl.float-variance'),
              postfix,
              prefix,
            )}
            isDisabled={isDisabled}
            newDefaultFloat={newDefaultFloat}
            newDefaultFloatError={newDefaultFloatError}
            newTillCeiling={newTillCeiling}
            newTillCeilingError={newTillCeilingError}
            responseError={responseError}
            setCurrency={setCurrencyCode}
            setNewDefaultFloat={setNewDefaultFloat}
            setNewTillCeiling={setNewTillCeiling}
            submitError={submitError}
            submitLoading={submitLoading}
            submitSuccess={submitSuccess}
          />
        )
      )}
    </article>
  );
}

export default AdjustTill;
