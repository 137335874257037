import { Skeleton } from '@nike/nike-design-system-components';
import { NikeSwoosh } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';

export function Loader({ className, title }) {
  return (
    <div className={`d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm mb12-sm ${className}`}>
      <Skeleton borderRadius="s" height="40px" title={title} width="40px">
        <div
          className="bg-accent"
          style={{ borderRadius: '50% 50%', height: '48px', width: '48px' }}
        >
          <NikeSwoosh size="l" />
        </div>
      </Skeleton>
    </div>
  );
}

Loader.defaultProps = {
  className: '',
  title: '',
};

Loader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
