import { zodResolver } from '@hookform/resolvers/zod';
import { TextStyled } from '@nike/nike-design-system-components';
import isEmpty from 'lodash.isempty';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { getSchema } from './validation';
import { PATH_TILL_STATUS } from '../../../../constants/routes.const';
import { useGetProfile, useGetStoreInfo } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import {
  useAssociateTillMutation,
  useGetStoreClosedAndPickedUpTillsQuery,
  useGetTillDetailQuery,
} from '../../../../redux/services/store/storeService';
import { getFormattedCurrency } from '../../../../utils/currencyUtils';
import {
  ID_TILL_STATUS_ANCHOR,
  ID_CURRENT_FLOAT,
  ID_VARIANCE_REASONS,
  ID_WORKSTATION_NUMBER,
  ID_STORE_TILL_SELECT,
} from '../../../../utils/dom-element-ids';
import { getMaxAmountForCountry } from '../../../../utils/getMaxAllowableAmount';
import { DropDown, MultipleCurrencyInput, TextField } from '../../../common';
import { FormWrapper } from '../../../common/FormWrapper';
import { ButtonNavigateTo, Subheader, WarningMessage } from '../../../reusable';

export const VARIANCE_REASON_ENUM = {
  NO_VARIANCE: 'No Variance',
  PREVIOUS_MISCOUNT: 'Previous Miscount',
};

const defaultValues = {
  currentFloat: 0,
  originalFloat: 0,
  originalWorkstationNumber: 0,
  selectTill: '',
  tillNumber: 0,
  varianceExplanation: '',
  varianceReason: VARIANCE_REASON_ENUM.NO_VARIANCE,
  workstationNumber: '',
};

export function TillAssociation() {
  const location = useLocation();
  const { data: selectedTillFromTillStatusTable } = location.state || {};
  const isSelectedTillFromTillStatusTable = !isEmpty(selectedTillFromTillStatusTable);

  const getLocalization = useGetLocalization();
  const varianceReasonOptions = [
    { label: getLocalization('lbl.no-variance'), value: VARIANCE_REASON_ENUM.NO_VARIANCE },
    {
      label: getLocalization('lbl.previous-miscount'),
      value: VARIANCE_REASON_ENUM.PREVIOUS_MISCOUNT,
    },
  ];

  const { country, storeId } = useGetProfile();
  const { currency, locale } = useGetStoreInfo();

  const validationConfig = {
    fields: {
      isRequired: {
        MIN: getLocalization('msg.is-a-required-field'),
      },
      currentFloat: {
        NONNEGATIVE: getLocalization('msg.number-must-be-positive'),
        MAX: getLocalization('msg.number-exceeds-max'),
        MAX_AMOUNT: getMaxAmountForCountry(country),
      },
      workstationNumber: {
        NONNEGATIVE: getLocalization('msg.number-must-be-positive'),
      },
    },
    variance: {
      short: {
        REASON: 'Short',
        MESSAGE: getLocalization('msg.variance-reason-mismatch'),
      },
      over: {
        REASON: 'Over',
        MESSAGE: getLocalization('msg.variance-reason-mismatch'),
      },
      none: {
        REASON: 'No Variance',
        MESSAGE: getLocalization('msg.variance-reason-mismatch'),
      },
    },
  };

  const form = useForm({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(getSchema(validationConfig)),
  });
  const { watch, control, setValue, clearErrors, setError, reset } = form;
  const formSelectTill = watch('selectTill');

  const {
    data: tillData,
    isSuccess: isTillDetailSuccess,
    error: tillDetailError,
    isFetching: isTillDetailFetching,
  } = useGetTillDetailQuery({ tillId: formSelectTill }, { skip: !formSelectTill });
  const {
    data: storeTillsData,
    error: storeTillsError,
    isSuccess: isStoreTillsSuccess,
    isFetching: isStoreTillFetching,
    isLoading: isStoreTillLoading,
  } = useGetStoreClosedAndPickedUpTillsQuery({ storeId });

  const [
    associateTill,
    { isSuccess: isSubmitSuccess, error: submitError, isLoading: isSubmitLoading },
  ] = useAssociateTillMutation();

  // set selectTill Error message when no tills
  useEffect(() => {
    if (!storeTillsData?.length) {
      setError('selectTill', { message: 'No tills to associate' });
    } else {
      clearErrors('selectTill');
    }
  }, [isStoreTillsSuccess, clearErrors, storeTillsData, setError]);

  // set selectedTill when navigated from till status page
  useEffect(() => {
    if (isSelectedTillFromTillStatusTable) {
      setValue('selectTill', selectedTillFromTillStatusTable.id);
    }
  }, [isSelectedTillFromTillStatusTable, selectedTillFromTillStatusTable, setValue]);

  // update values when tillDetails fetch runs
  useEffect(() => {
    if (isTillDetailSuccess) {
      if (tillData.workstationNumber > 0) {
        setValue('currentFloat', tillData.currentFloat);
        setValue('originalFloat', tillData.currentFloat);
        setValue('workstationNumber', tillData.workstationNumber.toString());
        setValue('originalWorkstationNumber', tillData.workstationNumber.toString());
        setValue('tillNumber', tillData.tillNumber);
        setValue('varianceReason', defaultValues.varianceReason);
        setValue('displayWarning', false);
        setValue('displayWorkstationInfo', true);
      } else {
        setValue('currentFloat', tillData.currentFloat);
        setValue('originalFloat', tillData.currentFloat);
        setValue('originalWorkstationNumber', null);
        setValue('workstationNumber', tillData.tillNumber.toString());
        setValue('tillNumber', tillData.tillNumber);
        setValue('varianceReason', defaultValues.varianceReason);
        setValue('displayWarning', true);
        setValue('displayWorkstationInfo', false);
      }
    }
  }, [isTillDetailSuccess, setValue, tillData]);

  const submit = async (data) => {
    const { currentFloat, tillNumber, varianceReason, workstationNumber } = data;

    const varianceTuple = Object.entries(VARIANCE_REASON_ENUM).filter(
      // eslint-disable-next-line no-unused-vars
      ([key, val]) => val === varianceReason,
      '',
    );
    const varianceKey = varianceTuple.flat()[0];

    await associateTill({
      currencyCode: currency,
      newCurrentFloat: currentFloat,
      tillNumber,
      varianceReason: varianceKey,
      workstationNumber: Number(workstationNumber),
      storeId,
    });
    setValue('displayWarning', false);
    setValue('displayWorkstationInfo', false);
  };

  const successMessage = (workstationNumber, now = new Date()) => {
    if (workstationNumber) {
      return `${getLocalization('msg.till-successfully-associated')} ${' '} ${' '}${getLocalization(
        'lbl.workstation-number',
      )} ${workstationNumber} ${getLocalization('lbl.till')} ${watch(
        'tillNumber',
      )} ${' '}  ${getLocalization('msg.on')} ${now[Symbol.toPrimitive]('string')}`;
    }
    return <div />;
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.associate-and-count')}</Subheader>
      <FormWrapper
        crossFieldValidationArray={['varianceReason', 'currentFloat']}
        fetchErrors={[tillDetailError, storeTillsError]}
        fetchingFlags={[isTillDetailFetching, isStoreTillFetching]}
        form={form}
        loadingFlags={[isStoreTillLoading]}
        submit={{
          resetAfter: false,
          error: submitError,
          handler: submit,
          isLoading: isSubmitLoading,
          isSuccess: isSubmitSuccess,
          label: getLocalization('lbl.associate-till'),
        }}
        successMessage={successMessage(watch('workstationNumber'))}
      >
        <DropDown
          control={control}
          id={ID_STORE_TILL_SELECT}
          label={getLocalization('lbl.store-tills')}
          name="selectTill"
          options={
            storeTillsData?.map((item) => ({
              label: `${item.currencyCode} - ${item.tillNumber}`,
              value: item.id,
            })) || []
          }
          onChange={(event) => !event.target.value && reset()}
        />

        <MultipleCurrencyInput
          isRequired
          control={control}
          id={ID_CURRENT_FLOAT}
          label={getLocalization('lbl.current-float')}
          name="currentFloat"
          supportText={`${getLocalization('lbl.change')}: ${getFormattedCurrency(
            locale,
            currency,
            watch('currentFloat') - watch('originalFloat'),
          )}`}
        />
        <DropDown
          isRequired
          control={control}
          id={ID_VARIANCE_REASONS}
          label={getLocalization('lbl.variance-reason')}
          name="varianceReason"
          includeSelectAnOption={false}
          options={varianceReasonOptions.map(({ label, value }) => ({ label, value }))}
          supportText={getLocalization('lbl.variance-reason-support')}
        />
        <TextField
          type="number"
          shouldMakeActive
          control={control}
          id={ID_WORKSTATION_NUMBER}
          label={getLocalization('lbl.workstation-number')}
          maxLength={100}
          name="workstationNumber"
          requiredIndicator="*"
        />
        {watch('originalWorkstationNumber') && watch('displayWorkstationInfo') ? (
          <TextStyled appearance="body1Strong" Component="p">{`${getLocalization(
            'lbl.till',
          )} ${watch('tillNumber')}${getLocalization(
            'msg.associated-to-workstation-number',
          )}${watch('originalWorkstationNumber')}`}</TextStyled>
        ) : null}
        <br />
      </FormWrapper>
      {watch('displayWarning') ? (
        <WarningMessage>{`${getLocalization('lbl.till')} ${getLocalization(
          'msg.till-not-associated',
        )}`}</WarningMessage>
      ) : null}
      <ButtonNavigateTo
        isRendered={isSelectedTillFromTillStatusTable}
        label={getLocalization('lbl.back-to-till-summary')}
        linkId={ID_TILL_STATUS_ANCHOR}
        pathname={PATH_TILL_STATUS}
      />
    </article>
  );
}
