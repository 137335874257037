import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PickupDeclarationForm from './PickupDeclarationForm';
import { pickUpRequest } from './pickUpRequest';
import { VARIANCE_REASON_ENUM } from './validation';
import { useGetLocalization } from '../../../../hooks';
import { updateItemById } from '../../../../utils/arrayUtils';
import createTenderData from '../../../../utils/createTenderData';
import { updateTillPayload } from '../../../../utils/dataUtils';
import { getTills, getTillDetail, tillPickup } from '../../../../utils/service-calls';
import { retrieveClosedTills } from '../../../../utils/tillUtils';
import { ErrorMessage, Subheader, TillSelect, WarningMessage } from '../../../reusable';

const tillStatus = 'PICKED_UP';

function PickupDeclaration() {
  const location = useLocation();
  const { tillData: selectedTillFromTillStatusTable } = location.state || {};
  const getLocalization = useGetLocalization();
  const SUBMIT_SUCCESS = getLocalization('msg.till-pickup-declaration-submit-success');

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState(null);

  const [retrievedTillError, setRetrievedTillError] = useState(null);
  const [retrievingTills, setRetrievingTills] = useState(true);

  const [selectedTill, setSelectedTill] = useState(null);
  const [currencyCode, setCurrencyCode] = useState('');
  const [tillNumber, setTillNumber] = useState('');
  const [pickupAmount, setPickupAmount] = useState(0.0);
  const [loansForTheDay, setLoansForTheDay] = useState({});

  const [virtualTenders, setVirtualTenders] = useState([]);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitLoading, setSubmitLoading] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const getSubmitErrorMessage = (e) => {
    return `${getLocalization('msg.failed-to-submit-pickup-declaration')}: ${e.message}`;
  };

  const [backToTillSummaryEnabled, setBackToTillSummaryEnabled] = useState(false);

  const updateTillStatus = (data) => {
    const updatedPayload = updateTillPayload(data, tillStatus);
    const updatedTills = updateItemById(availableTills, updatedPayload);
    setAvailableTills(retrieveClosedTills(updatedTills));
  };

  useEffect(() => {
    const fetchData = async () =>
      getTills()
        .then(({ data }) => setAvailableTills(retrieveClosedTills(data.tills)))
        .catch((err) => setAvailableTillsError(err))
        .finally(() => setRetrievingTills(false));
    fetchData();
  }, []);

  const retrieveSelectedTill = (tillId, enableBackToTillSummary = false) => {
    setSubmitSuccess('');
    setSelectedTill(null);
    setSubmitLoading(true);
    setSubmitDisabled(false);

    if (enableBackToTillSummary) {
      setBackToTillSummaryEnabled(true);
    }

    return getTillDetail(tillId)
      .then(({ data }) => {
        setCurrencyCode(data.currencyCode);
        setTillNumber(data.tillNumber);
        setPickupAmount(data.currentFloat - data.defaultFloat);
        setLoansForTheDay(data.loansForTheDay);
        const tenderData = createTenderData(data);
        const [first, ...rest] = tenderData;
        setVirtualTenders([
          { ...first, varianceReason: VARIANCE_REASON_ENUM.NO_VARIANCE, varianceExplanation: '' },
          ...rest,
        ]);
        return setSelectedTill(tillId);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    if (selectedTillFromTillStatusTable) {
      retrieveSelectedTill(selectedTillFromTillStatusTable.id, !!selectedTillFromTillStatusTable);
    }
  }, [selectedTillFromTillStatusTable]);

  useEffect(() => {
    setSubmitDisabled(!!(availableTillsError || retrievedTillError));
  }, [availableTillsError, retrievedTillError]);

  const submit = () => {
    setSubmitError('');
    setSubmitLoading(true);
    setSubmitDisabled(true);
    if (virtualTenders[0].actualAmount < 0) {
      setSubmitLoading(false);
      setSubmitDisabled(false);
      return setSubmitError(
        `${getLocalization('msg.failed-to-submit-pickup-declaration')}: ${getLocalization(
          'msg.till-neg-balance',
        )}`,
      );
    }

    return tillPickup({
      currencyCode,
      loansForTheDay,
      pickUps: pickUpRequest(virtualTenders),
      tillNumber,
    })
      .then(({ data }) => {
        updateTillStatus(data);
        return setSubmitSuccess(SUBMIT_SUCCESS);
      })
      .catch((e) => {
        setSubmitError(getSubmitErrorMessage(e));
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.till-final-pickup-declaration')}</Subheader>
      {availableTills.length === 0 && (
        <WarningMessage>
          {getLocalization('msg.till-final-pickup-declaration-none-available')}
        </WarningMessage>
      )}
      {availableTillsError ? (
        <ErrorMessage rawError={availableTillsError}>
          {getLocalization('msg.there-was-an-error-loading-tills')}
        </ErrorMessage>
      ) : (
        <TillSelect
          availableTills={availableTills}
          retrieveSelectedTill={retrieveSelectedTill}
          retrievingTills={retrievingTills}
          selectedTill={selectedTill}
        />
      )}
      {retrievedTillError ? (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.there-was-an-error-loading-requested-till')}
        </ErrorMessage>
      ) : (
        selectedTill && (
          <PickupDeclarationForm
            backToTillSummaryEnabled={backToTillSummaryEnabled}
            isDisabled={submitDisabled}
            pickupAmount={pickupAmount}
            setVirtualTenders={setVirtualTenders}
            submit={submit}
            submitError={submitError}
            submitLoading={submitLoading}
            submitSuccess={submitSuccess}
            tillNumber={tillNumber}
            virtualTenders={virtualTenders}
          />
        )
      )}
    </article>
  );
}

export default PickupDeclaration;
