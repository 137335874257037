import {
  DialogStyled,
  toggleSiblingNodes,
  IconButton,
  RadioSetStyled,
  RadioStyled,
} from '@nike/nike-design-system-components';
import { Global } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { languagePickerData } from '../../constants/languagePickerData';
import { ID_MODAL_ROOT, ID_OPEN_LANG_MODAL_BUTTON } from '../../utils/dom-element-ids';

function LanguagePicker({ modalTitle, selectedLanguage, retrieveSelectedLanguage }) {
  const [isOpen, setIsOpen] = useState(false);

  const languages = languagePickerData
    .filter((item) => item.enabled !== false)
    .map((item) => (
      <RadioStyled id={item.tag} label={item.name} value={item.ncssLang} key={item.tag} />
    ));

  const openModal = () => {
    setIsOpen(true);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, true, undefined);
  };

  const closeModal = () => {
    setIsOpen(false);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, false, undefined);
  };

  return (
    <>
      <IconButton
        Icon={<Global />}
        ariaLabel="info button"
        id={ID_OPEN_LANG_MODAL_BUTTON}
        size="small"
        type="button"
        onClick={openModal}
      />
      <DialogStyled
        className="dialogModal"
        isOpen={isOpen}
        title={modalTitle}
        closeModal={closeModal}
      >
        <RadioSetStyled
          name="name"
          hasLargeLegend
          disabled={false}
          selectedValue={selectedLanguage}
          onChange={({ target: { value: newValue, id: newId } }) => {
            retrieveSelectedLanguage(newValue, newId);
            closeModal();
          }}
        >
          <div>{languages}</div>
        </RadioSetStyled>
      </DialogStyled>
    </>
  );
}

LanguagePicker.defaultProps = {
  selectedLanguage: null,
};

LanguagePicker.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  retrieveSelectedLanguage: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
};

export default LanguagePicker;
