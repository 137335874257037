import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import {
  ID_CURRENCY_CODE_INPUT,
  ID_CURRENT_FLOAT,
  ID_DEFAULT_FLOAT,
  ID_TILL_CEILING,
  ID_TILL_NUMBER,
} from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function AddTillForm({
  currencyCode,
  addTill,
  isDisabled,
  responseError,
  setTillNumber,
  tillNumberError,
  tillCeilingError,
  currentFloatError,
  defaultFloatError,
  setDefaultFloat,
  setCurrentFloat,
  setTillCeiling,
  currentFloat,
  defaultFloat,
  tillCeiling,
  tillNumber,
  submitLoading,
  submitSuccess,
}) {
  const getLocalization = useGetLocalization();

  return (
    <>
      <TextFieldStyled
        error={!!tillNumberError}
        errorMessage={tillNumberError}
        id={ID_TILL_NUMBER}
        label={getLocalization('lbl.till-number')}
        maxLength={7}
        name="Till Number"
        type="text"
        value={tillNumber}
        onChange={({ target: { value: newValue } }) => setTillNumber(newValue)}
      />
      <TextFieldStyled
        readOnly
        id={ID_CURRENCY_CODE_INPUT}
        label={getLocalization('lbl.currency-code')}
        name="CurrencyCode"
        placeholder={currencyCode}
      />
      <MultipleCurrencyInput
        currency={currencyCode}
        errorMessage={defaultFloatError}
        id={ID_DEFAULT_FLOAT}
        label={getLocalization('lbl.default-float')}
        name="Default Float"
        setAmount={setDefaultFloat}
        setCurrency={() => {}}
        showErrorMessage={!!defaultFloatError}
        value={defaultFloat}
      />
      <MultipleCurrencyInput
        currency={currencyCode}
        errorMessage={currentFloatError}
        id={ID_CURRENT_FLOAT}
        label={getLocalization('lbl.current-float')}
        name="currentFloat"
        setAmount={setCurrentFloat}
        setCurrency={() => {}}
        showErrorMessage={!!currentFloatError}
        value={currentFloat}
      />
      <MultipleCurrencyInput
        currency={currencyCode}
        errorMessage={tillCeilingError}
        id={ID_TILL_CEILING}
        label={getLocalization('lbl.till-ceiling')}
        name="Till Ceiling"
        setAmount={setTillCeiling}
        setCurrency={() => {}}
        showErrorMessage={!!tillCeilingError}
        value={tillCeiling}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled || submitLoading || !!submitSuccess}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.till-add')}
        messageError={responseError}
        messageSuccess={responseError ? emptyString : submitSuccess}
        onSubmit={addTill}
      />
    </>
  );
}

AddTillForm.defaultProps = {
  tillNumber: '',
};

AddTillForm.propTypes = {
  addTill: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  currentFloat: PropTypes.number.isRequired,
  currentFloatError: PropTypes.string.isRequired,
  defaultFloat: PropTypes.number.isRequired,
  defaultFloatError: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  responseError: PropTypes.string.isRequired,
  setCurrentFloat: PropTypes.func.isRequired,
  setDefaultFloat: PropTypes.func.isRequired,
  setTillCeiling: PropTypes.func.isRequired,
  setTillNumber: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  tillCeiling: PropTypes.number.isRequired,
  tillCeilingError: PropTypes.string.isRequired,
  tillNumber: PropTypes.string,
  tillNumberError: PropTypes.string.isRequired,
};
export default AddTillForm;
