import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import AdjustSafeForm from './AdjustSafeForm';
import { useGetStoreInfo } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import { getFormattedCurrency } from '../../../../utils/currencyUtils';
import { adjustSafe, getSafes, getSafeDetail } from '../../../../utils/service-calls';
import { LoadingIndicator, Subheader } from '../../../reusable';

function AdjustSafe() {
  const getLocalization = useGetLocalization();
  const { currency, locale } = useGetStoreInfo();

  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const PLEASE_RESOLVE_ERRORS = getLocalization('msg.form-errors');
  const DEFAULT_FLOAT_LABEL = getLocalization('lbl.default-float');
  const SAFE_FLOOR_LABEL = getLocalization('lbl.safe-floor');
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const DEFAULT_FLOAT_LESS_THAN = `${DEFAULT_FLOAT_LABEL} ${getLocalization(
    'msg.must-be-greater-than',
  )} ${SAFE_FLOOR_LABEL}.`;
  const SUCCESSFULLY_ADJUSTED_SAFE = getLocalization('msg.adjusted-safe');
  const NO_SAFE_DATA = getLocalization('msg.no-safe-exists');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.adjust-safe-failed')}: ${message}`;

  const [currencyCode, setCurrencyCode] = useState('');
  const [safeNumber, setSafeNumber] = useState(0);
  const [newDefaultFloat, setNewDefaultFloat] = useState(0.0);
  const [originalDefaultFloat, setOriginalDefaultFloat] = useState();
  const [newSafeFloor, setNewSafeFloor] = useState(0.0);
  const [originalSafeFloor, setOriginalSafeFloor] = useState();
  const [floatError, setFloatError] = useState('');
  const [floorError, setFloorError] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [responseError, setResponseError] = useState('');

  const [defaultSafeData, setDefaultSafeData] = useState();
  const [safeName, setSafeName] = useState('');
  const [safeDataError, setSafeDataError] = useState('');
  const [defaultSafeDataError, setDefaultSafeDataError] = useState('');
  const [isSafeFormVisible, setIsSafeFormVisible] = useState(false);

  const retrieveSafeDetail = (safeId) => {
    setSubmitLoading(true);
    getSafeDetail(safeId)
      .then(({ data }) => {
        setNewSafeFloor(data.safeFloor);
        setOriginalDefaultFloat(data.defaultFloat);
        setOriginalSafeFloor(data.safeFloor);
        setNewDefaultFloat(data.defaultFloat);
        return setDefaultSafeData(data);
      })
      .catch((err) => setDefaultSafeDataError(err.message))
      .finally(() => {
        setIsSafeFormVisible(true);
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    setSubmitLoading(true);
    getSafes()
      .then(({ data }) => {
        const defaultSafe = data.safes[data.safes.length - 1];
        setCurrencyCode(defaultSafe.currencyCode);
        setSafeNumber(defaultSafe.safeNumber);
        setSafeName(defaultSafe.safeName);
        return retrieveSafeDetail(defaultSafe.id);
      })
      .catch((err) => setDefaultSafeDataError(err.message))
      .finally(() => setSubmitLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const floatErrors = match()(
      [() => typeof originalDefaultFloat !== 'number', REQUIRED_FIELD],
      [() => newDefaultFloat < newSafeFloor, DEFAULT_FLOAT_LESS_THAN],
      [() => newDefaultFloat < 0, POSITIVE_NUMBER],
      [Default, ''],
    );

    const floorErrors = match()(
      [() => typeof originalSafeFloor !== 'number', REQUIRED_FIELD],
      [() => newSafeFloor < 0, POSITIVE_NUMBER],
      [Default, ''],
    );

    const safeDataErrors = match()(
      [() => defaultSafeData === undefined, NO_SAFE_DATA],
      [() => !!setDefaultSafeDataError, defaultSafeDataError],
      [Default, ''],
    );
    setFloatError(floatErrors);
    setFloorError(floorErrors);
    setSafeDataError(safeDataErrors);
    setIsDisabled(!!(floatErrors || floorErrors || safeDataErrors));
    setResponseError(isDisabled ? PLEASE_RESOLVE_ERRORS : '');
  }, [
    defaultSafeData,
    defaultSafeDataError,
    isDisabled,
    newDefaultFloat,
    newSafeFloor,
    originalDefaultFloat,
    originalSafeFloor,
    DEFAULT_FLOAT_LESS_THAN,
    PLEASE_RESOLVE_ERRORS,
    POSITIVE_NUMBER,
    NO_SAFE_DATA,
    REQUIRED_FIELD,
  ]);

  const submit = () => {
    setSubmitLoading(true);
    setSubmitError('');
    setSubmitSuccess('');
    adjustSafe({
      currencyCode,
      floatVariance: newDefaultFloat - originalDefaultFloat,
      newDefaultFloat,
      newSafeFloor,
      safeNumber,
    })
      .then(() => setSubmitSuccess(SUCCESSFULLY_ADJUSTED_SAFE))
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  const getChangeMessage = (original, modified) =>
    `${getLocalization('lbl.change')}: ${getFormattedCurrency(
      locale,
      currency,
      modified - original,
    )}`;

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.adjust-safe')}</Subheader>
      {isSafeFormVisible ? (
        <AdjustSafeForm
          adjustSafe={submit}
          currencyCode={currencyCode}
          defaultFloatChangeMessage={getChangeMessage(originalDefaultFloat, newDefaultFloat)}
          isDisabled={isDisabled}
          isSafeFormVisible={isSafeFormVisible}
          newDefaultFloat={newDefaultFloat}
          newDefaultFloatError={floatError}
          newSafeFloor={newSafeFloor}
          newSafeFloorError={floorError}
          responseError={responseError}
          safeDataError={safeDataError}
          safeFloorChangeMessage={getChangeMessage(originalSafeFloor, newSafeFloor)}
          safeName={safeName}
          safeNumber={safeNumber}
          setCurrency={setCurrencyCode}
          setNewDefaultFloat={setNewDefaultFloat}
          setNewSafeFloor={setNewSafeFloor}
          submitError={submitError}
          submitLoading={submitLoading}
          submitSuccess={submitSuccess}
        />
      ) : (
        <LoadingIndicator />
      )}
    </article>
  );
}

export default AdjustSafe;
