import { match, Default } from '@nike/rcf-fp';

export const getTillStatusColorClassName = ({ status }) => {
  const color = match(status)(
    ['ACTIVE', 'text-color-success'],
    ['CLOSED', 'text-color-error'],
    [Default, 'text-color-primary-dark'],
  );
  return `ta-sm-c ${color}`;
};
