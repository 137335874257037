import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { act } from 'react-dom/test-utils';

import { getCashMode } from '../../../utils/service-calls';

export const CASH_MODE_ENUM = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
};

const CashModeContext = createContext();

export const useCashModeContext = () => useContext(CashModeContext);

function CashModeProvider({ children, mockedValue }) {
  // const cachedCashMode = getItemFromSessionStorage(CACHED_CASH_MODE_KEY) || null;
  const [cashMode, setCashMode] = useState(null);
  const [cashModeError, setCashModeError] = useState(null);
  const [athleteInfo, setAthleteInfo] = useState(null);
  const [cashModeCreateTime, setCashModeCreateTime] = useState(null);

  useEffect(() => {
    getCashMode()
      .then(
        (data) => {
          const { athleteName, athleteNumber, createTime, cash_mode: mode } = data.data;
          if (mode in CASH_MODE_ENUM) {
            // setItemInSessionStorage(CACHED_CASH_MODE_KEY, mode);
            act(() => {
              setAthleteInfo(`${athleteName} (${athleteNumber})`);
              setCashModeCreateTime(createTime);
              return setCashMode(mode);
            });
          }

          return new Error(`Cash mode value: ${mode} does not match key in CASH_MODE_ENUM`);
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log(`getCashMode error: ${err.message}`);
          setCashModeError(err);
        },
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(`getCashMode error: ${err.message}`);
        setCashModeError(err);
      });
  }, []);

  // TODO: Will be refactored to redux in near future
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    athleteInfo,
    cashMode,
    cashModeCreateTime,
    cashModeError,
    setCashMode,
    setCashModeCreateTime,
    setCashModeError,
  };

  return (
    <CashModeContext.Provider value={mockedValue || value}>{children}</CashModeContext.Provider>
  );
}

CashModeProvider.defaultProps = {
  children: <div />,
  mockedValue: null,
};

CashModeProvider.propTypes = {
  children: PropTypes.node,
  // only used for testing
  // eslint-disable-next-line react/forbid-prop-types
  mockedValue: PropTypes.any,
};

export default CashModeProvider;
