import React from 'react';

import { useGetProfile } from '../../globals';
import { useGetLocalization } from '../../hooks';
import { useGetCashSafeFromStoreIdQuery } from '../../redux/services/store/storeService';
import { ErrorMessage, Loader } from '../reusable';
import { MultipleCurrencyText } from '../reusable/MultipleCurrencyText';

function StoreSafe() {
  const getLocalization = useGetLocalization();
  const { storeId } = useGetProfile();
  const {
    data: { currentFloat, defaultFloat } = {},
    error: { message: cashSafeError } = {},
    isFetching: isCashSafeFetching,
    isLoading: isCashSafeLoading,
  } = useGetCashSafeFromStoreIdQuery({ storeId });

  if (isCashSafeLoading || isCashSafeFetching) return <Loader />;

  return (
    <div className="ta-sm-c">
      {cashSafeError ? (
        <ErrorMessage rawError={cashSafeError}>
          {getLocalization('msg.error-fetching-safe')}
        </ErrorMessage>
      ) : (
        <div>
          <section className="headline-1">{getLocalization('lbl.safe-details')}</section>
          {`${getLocalization('lbl.current-float')}: `}
          <MultipleCurrencyText value={currentFloat} />
          <br />
          {`${getLocalization('lbl.default-float')}: `}
          <MultipleCurrencyText value={defaultFloat} />
          <br />
        </div>
      )}
    </div>
  );
}

export default StoreSafe;
