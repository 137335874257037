import PropTypes from 'prop-types';
import React from 'react';

import { ID_WARNING_MESSAGE } from '../../utils/dom-element-ids';

export default function WarningMessage(props) {
  return (
    <p
      className="u-rounded p2-sm prl4-sm mt4-sm mb4-sm text-color-primary-light ta-sm-c"
      style={{ backgroundColor: '#ffc107' }}
      id={ID_WARNING_MESSAGE}
    >
      <i className="g72-info mr2-sm" /> {props.children}
    </p>
  );
}

WarningMessage.propTypes = {
  children: PropTypes.node.isRequired,
};
