import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useGetStoreInfo } from '../../../globals';
import { getDecimalCount } from '../../../utils/currencyUtils';
import { stringToFloat } from '../../../utils/mathUtils';
import { convertToPascalCase } from '../../../utils/stringUtils';
import './Currency.css';

function MultipleCurrencyInput({
  currency,
  errorMessage,
  id,
  isReadOnly,
  isRequired,
  label,
  message,
  name,
  showErrorMessage,
  supportTextColor,
  value,
  setAmount,
  setCurrency,
  allowNegativeValue,
}) {
  // TODO: refactor locale to pull from global state
  const [locale, setLocale] = useState('en-US');

  const [formattedValue, setFormattedValue] = useState(value);

  const { currency: storeCurrency, locale: storeLocale } = useGetStoreInfo();

  useEffect(() => {
    setFormattedValue(value);
  }, [value]);

  useEffect(() => {
    setCurrency(storeCurrency);
    setLocale(storeLocale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextFieldStyled
      shouldMakeActive
      ariaLive="polite"
      currency={currency}
      error={showErrorMessage || (isRequired && !value)}
      errorMessage={errorMessage}
      id={id}
      label={label}
      maxLength={9}
      name={convertToPascalCase(label)}
      readOnly={isReadOnly}
      requiredIndicator={isRequired ? '*' : ''}
      slots={{
        InputSlot: (
          <CurrencyInput
            allowDecimals
            allowNegativeValue={allowNegativeValue}
            className="currency-input"
            decimalsLimit={getDecimalCount()}
            defaultValue={formattedValue}
            disableAbbreviations
            disabled={isReadOnly}
            id={id}
            intlConfig={currency && locale ? { currency, locale } : {}}
            maxLength={10}
            name={name}
            value={formattedValue}
            onValueChange={(newValue) => {
              if (!newValue) {
                setFormattedValue(0);
                setAmount(0);
              } else {
                setFormattedValue(newValue);
                setAmount(stringToFloat(newValue));
              }
            }}
          />
        ),
      }}
      supportText={message}
      supportTextProps={{ style: { color: supportTextColor } }}
      type="text"
      value={`${value}`}
      onChange={() => {}}
    />
  );
}

MultipleCurrencyInput.defaultProps = {
  allowNegativeValue: false,
  errorMessage: '',
  isReadOnly: false,
  isRequired: false,
  message: '',
  setAmount: null,
  showErrorMessage: false,
  supportTextColor: '',
  value: 0,
};

MultipleCurrencyInput.propTypes = {
  allowNegativeValue: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  setAmount: PropTypes.func,
  setCurrency: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.bool,
  supportTextColor: PropTypes.string,
  value: PropTypes.number,
};

export default MultipleCurrencyInput;
