import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function AdjustTillForm({
  newDefaultFloat,
  newDefaultFloatError,
  setNewDefaultFloat,
  newTillCeiling,
  newTillCeilingError,
  setNewTillCeiling,
  responseError,
  submitLoading,
  submitSuccess,
  isDisabled,
  adjustTill,
  submitError,
  currency,
  setCurrency,
  floatVarianceMessage,
}) {
  const getLocalization = useGetLocalization();

  return (
    <>
      <MultipleCurrencyInput
        isRequired
        currency={currency}
        error={!!newDefaultFloatError}
        errorMessage={newDefaultFloatError}
        id="defaultFloat"
        label={getLocalization('lbl.default-float')}
        message={floatVarianceMessage}
        name="defaultFloat"
        setAmount={setNewDefaultFloat}
        setCurrency={setCurrency}
        showErrorMessage={!!newDefaultFloatError}
        value={newDefaultFloat}
        onChange={({ value: newValue }) => setNewDefaultFloat(newValue)}
      />
      <MultipleCurrencyInput
        isRequired
        currency={currency}
        error={!!newTillCeilingError}
        errorMessage={newTillCeilingError}
        id="tillCeiling"
        label={getLocalization('lbl.till-ceiling')}
        name="Till Ceiling"
        setAmount={setNewTillCeiling}
        setCurrency={setCurrency}
        showErrorMessage={!!newTillCeilingError}
        value={newTillCeiling}
        onChange={({ value: newValue }) => setNewTillCeiling(newValue)}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled || submitLoading || !!submitSuccess}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.till-adjust')}
        messageError={submitError || responseError}
        messageSuccess={submitError || responseError ? emptyString : submitSuccess}
        onSubmit={adjustTill}
      />
    </>
  );
}

AdjustTillForm.propTypes = {
  adjustTill: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  floatVarianceMessage: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  newDefaultFloat: PropTypes.number.isRequired,
  newDefaultFloatError: PropTypes.string.isRequired,
  newTillCeiling: PropTypes.number.isRequired,
  newTillCeilingError: PropTypes.string.isRequired,
  responseError: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setNewDefaultFloat: PropTypes.func.isRequired,
  setNewTillCeiling: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
};
export default AdjustTillForm;
