import React from 'react';

import { useGetProfile } from '../../globals';
import { useGetLocalization } from '../../hooks';
import { useGetStoreTillsQuery } from '../../redux/services/store/storeService';
import { getTillStatusColorClassName } from '../../utils/getTillStatusColorClassName';
import { getTillStatusLocalization } from '../../utils/getTillStatusLocalization';
import { ErrorMessage, Loader, Table } from '../reusable';
import { GetMultipleCurrencyText } from '../reusable/MultipleCurrencyText';

function StoreTills() {
  const getLocalization = useGetLocalization();
  const getTillStatusLabel = getTillStatusLocalization({ getLocalization });
  const { storeId } = useGetProfile();
  const {
    data: { tills } = {},
    error: { message: tillsError } = {},
    isFetching: isTillsFetching,
    isLoading: isTillsLoading,
  } = useGetStoreTillsQuery({ storeId });

  if (isTillsLoading || isTillsFetching) return <Loader />;

  return (
    <div>
      {tillsError ? (
        <ErrorMessage rawError={tillsError}>
          {getLocalization('msg.error-fetching-tills')}
        </ErrorMessage>
      ) : (
        <Table
          columns={[
            {
              header: getLocalization('lbl.till-number'),
              processor: ({ tillNumber }) => tillNumber,
              width: 2,
            },
            {
              header: getLocalization('lbl.default-float'),
              processor: ({ defaultFloat }) =>
                defaultFloat ? GetMultipleCurrencyText(defaultFloat) : GetMultipleCurrencyText(0),
              width: 2,
            },
            {
              header: getLocalization('lbl.current-float'),
              processor: ({ currentFloat }) =>
                currentFloat ? GetMultipleCurrencyText(currentFloat) : GetMultipleCurrencyText(0),
              width: 2,
            },
            {
              getClassName: getTillStatusColorClassName,
              header: getLocalization('lbl.status'),
              processor: getTillStatusLabel,
              width: 2,
            },
            {
              header: getLocalization('lbl.workstation-number'),
              processor: ({ workstationNumber }) =>
                workstationNumber && workstationNumber > 0 ? workstationNumber : '-',
              width: 2,
            },
          ]}
          data={tills}
          itemsPerPage={tills.length}
          rowsAreOpenable={false}
          showPagination={false}
          title={getLocalization('lbl.tills-details')}
        />
      )}
    </div>
  );
}

export default StoreTills;
