import { TextFieldStyled } from '@nike/nike-design-system-components';
import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState, useRef } from 'react';

import { useGetLocalization } from '../../../../hooks';
import {
  getSafes,
  getSafeDetail,
  getTills,
  getTillDetail,
  safeLoan,
} from '../../../../utils/service-calls';
import { retrieveOpenTills } from '../../../../utils/tillUtils';
import { Subheader, TillSelect } from '../../../reusable';
import ErrorMessage from '../../../reusable/ErrorMessage';
import SafeLoanForm from './LoanForm';

function SafeLoan() {
  const getLocalization = useGetLocalization();

  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const SUBMIT_SUCCESS = getLocalization('msg.safe-loan-success');
  const SAFE_FLOOR_ERROR = getLocalization('msg.float-below-safe-floor');
  const NO_SAFE_DATA = getLocalization('msg.no-safe-exists');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.safe-loan-failed')}: ${message}`;

  const [defaultSafeData, setDefaultSafeData] = useState();
  const [safeName, setSafeName] = useState('');
  const [defaultSafeError, setDefaultSafeError] = useState('');
  const [safeDataError, setSafeDataError] = useState('');

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState('');
  const [selectedTill, setSelectedTill] = useState(null);
  const [retrievedTillError, setRetrievedTillError] = useState('');
  const [retrievingTills, setRetrievingTills] = useState(true);

  const [loanAmount, setLoanAmount] = useState();
  const [loanAmountError, setLoanAmountError] = useState('');
  const [safeFloor, setSafeFloor] = useState();
  const [safeFloorError, setSafeFloorError] = useState();
  const [currencyCode, setCurrencyCode] = useState('');
  const [safeNumber, setSafeNumber] = useState();
  const [tillNumber, setTillNumber] = useState();
  const [currentFloat, setCurrentFloat] = useState();

  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formError, setFormError] = useState('');
  const [displaySafeNumber, setDisplaySafeNumber] = useState(false);

  const isFirstRender = useRef(true);

  const retrieveSafeDetail = (safeId) => {
    setSubmitLoading(true);
    getSafeDetail(safeId)
      .then(({ data }) => {
        setSafeFloor(data.safeFloor);
        setCurrentFloat(data.currentFloat);
        return setDefaultSafeData(data);
      })
      .catch((err) => setDefaultSafeError(err.message))
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const safeDetailErrors = match()(
      [() => defaultSafeData === undefined, NO_SAFE_DATA],
      [() => !!setDefaultSafeError, defaultSafeError],
      [Default, ''],
    );
    const loanAmountErrors = match()(
      [() => typeof loanAmount !== 'number', REQUIRED_FIELD],
      [() => loanAmount < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const safeFloorErrors = match()(
      [() => currentFloat - loanAmount < safeFloor, SAFE_FLOOR_ERROR],
      [Default, ''],
    );
    setFormError(loanAmountErrors || safeFloorErrors || safeDetailErrors ? ERRORS : '');
    setLoanAmountError(loanAmountErrors);
    setSafeFloorError(safeFloorErrors);
    setSafeDataError(safeDetailErrors);
    setSubmitDisabled(!!(loanAmountErrors || safeFloorErrors || safeDetailErrors));
  }, [
    currentFloat,
    defaultSafeData,
    defaultSafeError,
    loanAmount,
    safeFloor,
    ERRORS,
    NO_SAFE_DATA,
    POSITIVE_NUMBER,
    REQUIRED_FIELD,
    SAFE_FLOOR_ERROR,
  ]);

  useEffect(() => {
    setSubmitLoading(true);
    getSafes()
      .then(({ data }) => {
        const defaultSafe = data.safes[data.safes.length - 1];
        setCurrencyCode(defaultSafe.currencyCode);
        setSafeNumber(defaultSafe.safeNumber);
        setSafeName(defaultSafe.safeName);
        return retrieveSafeDetail(defaultSafe.id);
      })
      .catch((err) => setDefaultSafeError(err.message))
      .finally(() => {
        setSubmitLoading(false);
        setDisplaySafeNumber(true);
      });
    getTills()
      .then(({ data }) => setAvailableTills(retrieveOpenTills(data.tills)))
      .catch((err) => setAvailableTillsError(err.message))
      .finally(() => {
        setSubmitLoading(false);
        setRetrievingTills(false);
      });
  }, []);

  const retrieveSelectedTill = (tillId) => {
    setSelectedTill(null);
    setSubmitLoading(true);

    return getTillDetail(tillId)
      .then(({ data }) => {
        setCurrencyCode(data.currencyCode);
        setLoanAmount(data.loanAmount);
        setTillNumber(parseInt(data.tillNumber, 10));
        return setSelectedTill(tillId);
      })
      .catch((err) => setRetrievedTillError(err.message))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const calculateCurrentFloat = (currentFloatDifference, loanAmountDifference) =>
    `${getLocalization('lbl.current-float')}: $${(
      currentFloatDifference - loanAmountDifference
    ).toFixed(2)}`;

  const submit = () => {
    setSubmitLoading(true);
    setSubmitDisabled(true);
    safeLoan({
      currencyCode,
      loanAmount,
      newCurrentFloat: currentFloat - loanAmount,
      safeNumber,
      tillNumber,
    })
      .then(() => setSubmitSuccess(SUBMIT_SUCCESS))
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => {
        setSubmitLoading(false);
        setSubmitDisabled(false);
      });
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.safe-loan')}</Subheader>
      {displaySafeNumber && (
        <TextFieldStyled
          readOnly
          error={!!safeDataError}
          errorMessage={safeDataError}
          id="safeNumber"
          label={getLocalization('lbl.safe-number')}
          name="safeNumber"
          placeholder={`${getLocalization('lbl.safe')}: ${safeName} ${getLocalization(
            'lbl.number',
          )}: ${safeNumber}`}
        />
      )}
      {availableTillsError && (
        <>
          {getLocalization('msg.error-fetching-tills')}: {availableTillsError}
        </>
      )}
      <TillSelect
        availableTills={availableTills}
        retrieveSelectedTill={retrieveSelectedTill}
        retrievingTills={retrievingTills}
        selectedTill={selectedTill}
      />
      {retrievedTillError ? (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.error-loading-requested-info')}
        </ErrorMessage>
      ) : (
        selectedTill && (
          <SafeLoanForm
            currency={currencyCode}
            currentFloat={currentFloat}
            formErrors={formError}
            getCurrentFloat={calculateCurrentFloat(currentFloat, loanAmount)}
            isDisabled={submitDisabled}
            loanAmount={loanAmount}
            loanAmountError={loanAmountError}
            safeFloor={safeFloor}
            safeFloorError={safeFloorError}
            setCurrency={setCurrencyCode}
            setLoanAmount={setLoanAmount}
            setSafeFloor={setSafeFloor}
            submit={submit}
            submitError={submitError}
            submitLoading={submitLoading}
            submitSuccess={submitSuccess}
          />
        )
      )}
    </article>
  );
}

export default SafeLoan;
