import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ButtonSubmit } from '../../../reusable';

const emptyString = ' ';

function TillInForm({
  formErrors,
  isSubmitDisabled,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  workstationNumber,
  workstationNumberError,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <TextFieldStyled
        readOnly
        error={!!workstationNumberError}
        errorMessage={workstationNumberError}
        id="workstationNumberId"
        label={getLocalization('lbl.workstation-number')}
        name="Workstation Number"
        placeholder={workstationNumber}
        type="number"
        value={workstationNumber}
      />
      <br />
      <ButtonSubmit
        isDisabled={isSubmitDisabled || submitLoading}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.till-in')}
        messageError={formErrors || submitError}
        messageSuccess={submitError || formErrors ? emptyString : submitSuccess}
        onSubmit={submit}
      />
    </>
  );
}

TillInForm.propTypes = {
  formErrors: PropTypes.string.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  workstationNumber: PropTypes.string.isRequired,
  workstationNumberError: PropTypes.string.isRequired,
};
export default TillInForm;
