import axios from 'axios';

import { getEnvConfig } from '../config';

const CONFIG = getEnvConfig(process.env.NAME);

const AUTH_API = axios.create({
  baseURL: CONFIG.AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authApi = {
  // { athleteNumber, athletePassword, selectedCountry, selectedStore }
  login: (loginInformation) =>
    AUTH_API({
      data: {
        loginInformation,
        refresh: false,
      },
      method: 'POST',
      url: `/store/athlete_jwts/v1`,
    }),
  // { access_token, refresh_token }
  refresh: (loginInformation) =>
    AUTH_API({
      data: {
        loginInformation,
        refresh: true,
      },
      method: 'POST',
      url: `/store/athlete_jwts/v1`,
    }),
};
