import React from 'react';

import { useGetLocalization } from '../../hooks';
import { Subheader } from './Subheader';

function FeatureUnavailable() {
  const getLocalization = useGetLocalization();
  return <Subheader>{getLocalization('msg.feature-unavailable')}</Subheader>;
}

export default FeatureUnavailable;
