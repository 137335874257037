import React from 'react';
import adapter from 'webrtc-adapter';

import packageJSON from '../../../package.json';
import { useHandleLogout } from '../../AuthMessaging';
import { useGetProfile } from '../../globals';
import { useGetLocalization } from '../../hooks';
import { ID_LOGOUT_BUTTON } from '../../utils/dom-element-ids';
import { Button, Subheader } from '../reusable';

function Profile() {
  const getLocalization = useGetLocalization();
  const { athleteNumber, country, storeId, storeNumber, storeRoles } = useGetProfile();
  const sortedStoreRoles = Object.values(storeRoles).sort();
  const handleLogout = useHandleLogout();

  return (
    <>
      <Subheader>{getLocalization('lbl.profile')}</Subheader>
      <h3 className="headline-3">{`${getLocalization('lbl.athlete')} ${athleteNumber}`}</h3>
      <h3 className="headline-3">{`${country} - ${storeNumber}`}</h3>
      <p className="body-2 mt0-sm">{`StoreId - ${storeId}`}</p>
      <p className="body-2 mt3-sm">{getLocalization('lbl.athlete-roles')}:</p>
      {sortedStoreRoles.map((role) => (
        <p key={role} className="body-3">
          {role}
        </p>
      ))}
      <Button
        id={ID_LOGOUT_BUTTON}
        ariaLabel={getLocalization('lbl.log-out')}
        label={getLocalization('lbl.log-out')}
        sectionClassName="d-sm-flx flx-jc-sm-c mt6-sm"
        onClick={() => {
          handleLogout();
        }}
      />
      <p className="typography-body-1 mt3-sm">
        {getLocalization('lbl.version')} {packageJSON.version}
      </p>
      <p className="typography-body-1">{`${getLocalization('lbl.browser')} : ${
        adapter.browserDetails.browser
      }`}</p>
    </>
  );
}

export default Profile;
