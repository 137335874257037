import { match, Default } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PATH_PICKUP_DECLARATION } from '../../../constants/routes.const';
import { useGetLocalization } from '../../../hooks';
import {
  ID_FINAL_PICKUP_BUTTON,
  ID_FINAL_PICKUP_LINK,
  ID_BACKWARD_BUTTON,
  ID_FORWARD_BUTTON,
} from '../../../utils/dom-element-ids';
import { getTillStatusColorClassName } from '../../../utils/getTillStatusColorClassName';
import { getTillStatusLocalization } from '../../../utils/getTillStatusLocalization';
import { CASH_MODE_ENUM } from '../../context';
import { Button, ButtonDirection, ButtonSubmit } from '../../reusable';

function ListItem({ columns, index, item }) {
  const bgColor = index % 2 ? 'bg-primary-grey' : 'bg-white';

  return (
    <div className={`va-sm-m u-full-width p2-sm ${bgColor}`}>
      {columns.map((column) => (
        <div
          key={`col-${uuidv4()}`}
          className={`ncss-col-sm-${column.width || 1} ${
            column.getClassName ? column.getClassName(item) : ''
          }`}
        >
          {column.processor(item)}
        </div>
      ))}
    </div>
  );
}

ListItem.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      getClassName: PropTypes.func,
      width: PropTypes.number,
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape().isRequired,
};
function Table({ cashMode, title, columns, data, itemsPerPage }) {
  const getLocalization = useGetLocalization();

  const [page, setPage] = useState(0);
  const [pageItems, setPageItems] = useState(data.slice(0, itemsPerPage));

  const changePage = (isForward) => {
    const newPage = isForward ? page + 1 : page - 1;
    setPageItems(data.slice(newPage * itemsPerPage, (newPage + 1) * itemsPerPage));
    setPage(newPage);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const pageForward = () => changePage(true);
  const pageBack = () => changePage(false);

  const canPageForward = () => !!data[(page + 1) * itemsPerPage];

  const canPageBackward = () => page > 0;

  return (
    <>
      <section className="headline-1 mb4-sm">{title}</section>
      {cashMode === CASH_MODE_ENUM.OPEN && (
        <section className="headline-5 mb4-sm">{`${getLocalization(
          'msg.final-pick-up-must-be-performed-on-all-tills-to-close-the-store',
        )}.`}</section>
      )}
      {columns.map((column) => (
        <div
          key={`col-${uuidv4()}`}
          className={`ncss-col-sm-${column.width || 1} headline-4 ${
            column.headerClassName ? column.headerClassName : ''
          }`}
        >
          {column.header}
        </div>
      ))}
      {pageItems.map((item, index) => (
        <ListItem key={JSON.stringify(item)} columns={columns} index={index} item={item} />
      ))}
      <section className="d-sm-flx flx-jc-sm-c pt2-sm">
        <ButtonDirection
          ariaLabel="left arrow"
          direction="left"
          id={ID_BACKWARD_BUTTON}
          isDisabled={!canPageBackward()}
          onClick={pageBack}
        />
        <section className="ncss-col-sm-8">{`${getLocalization('lbl.page')} ${
          page + 1
        }/${totalPages}`}</section>
        <ButtonDirection
          ariaLabel="right arrow"
          direction="right"
          id={ID_FORWARD_BUTTON}
          isDisabled={!canPageForward()}
          onClick={pageForward}
        />
      </section>
    </>
  );
}

Table.defaultProps = {
  columns: [
    {
      getClassName: () => '',
      header: '',
      processor: (item) => JSON.stringify(item),
      width: 1,
    },
  ],
  itemsPerPage: 7,
};

Table.propTypes = {
  cashMode: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      getClassName: PropTypes.func,
      header: PropTypes.string,
      processor: PropTypes.func,
      // Sum of all columns' width attributes should be 12
      width: PropTypes.number,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  itemsPerPage: PropTypes.number,
  title: PropTypes.string.isRequired,
};

function PickupDeclarationButton({ tillData, isDisabled }) {
  const navigate = useNavigate();
  const getLocalization = useGetLocalization();
  const { tillNumber } = tillData;

  const finalPickupButton = (
    <ButtonSubmit
      buttonClassName="ncss-col-sm-12 full mb0-sm"
      buttonSize="small"
      id={`${ID_FINAL_PICKUP_BUTTON}${tillNumber}`}
      isDisabled={isDisabled}
      label={getLocalization('lbl.final-pickup')}
      sectionClassName="ncss-col-sm-12 full"
      onSubmit={() => {}}
    />
  );

  if (isDisabled) {
    return finalPickupButton;
  }

  return (
    <Button
      appearance="primary"
      className="u-no-ws"
      id={`${ID_FINAL_PICKUP_LINK}${tillNumber}`}
      label={getLocalization('lbl.final-pickup')}
      onClick={() => navigate(PATH_PICKUP_DECLARATION, { state: { tillData } })}
      size="medium"
    >
      {finalPickupButton}
    </Button>
  );
}

PickupDeclarationButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  tillData: PropTypes.shape().isRequired,
};

function Statuses({ cashMode, data }) {
  const getLocalization = useGetLocalization();

  const getTillStatusLabel = getTillStatusLocalization({ getLocalization });

  const getTillAction = (tillData) =>
    match(tillData.status)(
      ['CLOSED', () => <PickupDeclarationButton isDisabled={false} tillData={tillData} />],
      [
        'ACTIVE',
        () => (
          <article className="body-3">
            {getLocalization('msg.perform-tillout-to-enable-pickup-for-this-till')}
          </article>
        ),
      ],
      ['PICKED_UP', <PickupDeclarationButton isDisabled tillData={tillData} />],
      [Default, null],
    );

  if (data.length === 0) {
    return (
      <p className="text-color-accent typography-body-1">
        {getLocalization('msg.there-are-no-tills-available')}
      </p>
    );
  }

  const columns = [
    {
      header: getLocalization('lbl.till-number'),
      processor: ({ tillNumber }) => `${getLocalization('lbl.till')} ${tillNumber}`,
      width: 3,
    },
    {
      getClassName: getTillStatusColorClassName,
      header: getLocalization('lbl.status'),
      processor: getTillStatusLabel,
      width: 3,
    },
    {
      processor: getTillAction,
      width: 6,
    },
  ];

  return (
    <Table
      cashMode={cashMode}
      columns={columns}
      data={data}
      title={getLocalization('lbl.till-status')}
    />
  );
}

Statuses.propTypes = {
  cashMode: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function EndOfDayTable({ cashMode, data }) {
  return <Statuses cashMode={cashMode} data={data} />;
}

EndOfDayTable.propTypes = {
  cashMode: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default EndOfDayTable;
