import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_PICKUP_AMOUNT } from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function CashPickupForm({
  formErrors,
  newCurrentFloat,
  isDisabled,
  pickupAmount,
  pickupAmountError,
  setPickupAmount,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  tillCeiling,
  tillCeilingError,
  currency,
  setCurrency,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <MultipleCurrencyInput
        isRequired
        currency={currency}
        error={!!pickupAmountError}
        errorMessage={pickupAmountError}
        id={ID_PICKUP_AMOUNT}
        label={getLocalization('lbl.pickup-amount')}
        name="Pickup Amount"
        setAmount={setPickupAmount}
        setCurrency={setCurrency}
        showErrorMessage={!!pickupAmountError}
        value={pickupAmount}
      />
      <MultipleCurrencyInput
        isReadOnly
        currency={currency}
        id="newCurrentFloat"
        label={getLocalization('msg.new-current-float')}
        name="New Current Float"
        setCurrency={setCurrency}
        value={newCurrentFloat}
      />
      <MultipleCurrencyInput
        isReadOnly
        currency={currency}
        error={!!tillCeilingError}
        errorMessage={tillCeilingError}
        id="Till Ceiling"
        label={getLocalization('lbl.till-ceiling')}
        name="Till Ceiling"
        setCurrency={setCurrency}
        showErrorMessage={!!tillCeilingError}
        value={tillCeiling}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.pick-up-cash')}
        messageError={formErrors || submitError}
        messageSuccess={submitError || formErrors ? emptyString : submitSuccess}
        onSubmit={submit}
      />
    </>
  );
}

CashPickupForm.defaultProps = {
  submitError: '',
  submitSuccess: '',
};

CashPickupForm.propTypes = {
  currency: PropTypes.string.isRequired,
  formErrors: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  newCurrentFloat: PropTypes.string.isRequired,
  pickupAmount: PropTypes.number.isRequired,
  pickupAmountError: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setPickupAmount: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string,
  tillCeiling: PropTypes.number.isRequired,
  tillCeilingError: PropTypes.string.isRequired,
};

export default CashPickupForm;
