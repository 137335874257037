const getFloatVarianceMessage = (
  oldDefaultFloatAmount,
  newDefaultFloatAmount,
  floatVarianceLabel,
  postfix,
  prefix,
) =>
  `${floatVarianceLabel}: ${prefix || ''}${(newDefaultFloatAmount - oldDefaultFloatAmount).toFixed(
    2,
  )}${postfix || ''}`;

export default getFloatVarianceMessage;
