import PropTypes from 'prop-types';
import React from 'react';

import LoadingIndicator from './LoadingIndicator';

function ContentLoads({ children, error, isLoading }) {
  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <h5 className="typography-body-1 text-color-error">{error}</h5>;
  }

  return children;
}

ContentLoads.defaultProps = {
  error: null,
};

ContentLoads.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

export default ContentLoads;
