import {
  ButtonStyled,
  DialogStyled,
  toggleSiblingNodes,
  IconButton,
  TextStyled,
} from '@nike/nike-design-system-components';
import { Info } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useGetLocalization } from '../../hooks';
import { ID_MODAL_ROOT } from '../../utils/dom-element-ids';

function InformationModal({ modalTitle, informationList, iconId }) {
  const getLocalization = useGetLocalization();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, true, undefined);
  };

  const closeModal = () => {
    setIsOpen(false);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, false, undefined);
  };

  const infoList = informationList.map(({ item, definition }) => (
    <Fragment key={`info-list-${uuidv4()}`}>
      <TextStyled appearance="heading4" bold="true" display="inline">
        {`${getLocalization(item)}`}
      </TextStyled>
      {`${getLocalization(definition)}`}
      <br />
    </Fragment>
  ));

  const actions = () => (
    <DialogStyled>
      <ButtonStyled appearance="primary" ariaLabel="close-dialog" onClick={closeModal}>
        Close Dialog
      </ButtonStyled>
    </DialogStyled>
  );
  return (
    <>
      <IconButton
        Icon={<Info />}
        ariaLabel="info button"
        id={iconId}
        size="small"
        type="button"
        onClick={openModal}
      />
      <DialogStyled
        className="dialogModal"
        closeModal={closeModal}
        footerContent={actions}
        isOpen={isOpen}
        slots={{ FooterSlot: actions }}
        title={modalTitle}
      >
        <div className="ta-sm-l">{infoList}</div>
      </DialogStyled>
    </>
  );
}

InformationModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  informationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  iconId: PropTypes.string.isRequired,
};

export default InformationModal;
