import React from 'react';

// Icon credit to Diogo Marquetti (https://icon-icons.com/icon/bank/122546)
function Banking() {
  return <svg height="15px" viewBox="0 0 23.63 23.64" width="23px" xmlns="http://www.w3.org/2000/svg">
    <title>Banking</title>
    <g data-name="Camada 2" id="Camada_2">
      <g data-name="Camada 1" id="Camada_1-2">
        <path d="M23.13,19.47h-.89V17.8a.55.55,0,0,0-.55-.55H20.55V7h2.59a.5.5,0,0,0,.23-.94L12.05.06a.5.5,0,0,0-.47,0L.27,6.07A.5.5,0,0,0,.5,7H3.09V17.25H1.94a.55.55,0,0,0-.55.55v1.67H.5A.5.5,0,0,0,0,20v2.17a.5.5,0,0,0,.5.5H23.13a.5.5,0,0,0,.5-.5V20A.5.5,0,0,0,23.13,19.47Zm-3.59-2.21h-.79V7h.79ZM7.86,7V17.25h-2V7Zm1,0h.79V17.25H8.86Zm1.79,0H13V17.25H10.65ZM14,7h.79V17.25H14Zm1.79,0h2V17.25h-2Zm-4-5.94L21.13,6H2.51ZM4.09,7h.79V17.25H4.09ZM2.49,18.35H21.14v1.07H2.49Zm20.14,3.29H1V20.47h.72a.55.55,0,0,0,.22,0H21.69a.55.55,0,0,0,.22,0h.72Z" fill="#000000" />
        <path d="M11.82,5.28A1.48,1.48,0,1,0,10.34,3.8,1.48,1.48,0,0,0,11.82,5.28Zm0-2a.48.48,0,1,1-.48.48A.48.48,0,0,1,11.82,3.32Z" fill="#000000" />
      </g>
    </g>
  </svg>
}

export default Banking;
