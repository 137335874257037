import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { validateCashSafesResponseShape, validateStoreSafesResponseShape } from './shapes';
import {
  filterClosedOrPickedUpTills,
  retrieveDefaultSafeId,
  getPositiveOrZeroAmount,
} from './storeServiceUtils';
import { HOST_API } from '../../../constants/app.const';
import {
  RESOURCE_STORE_SAFES,
  RESOURCE_CASH_SAFES,
  RESOURCE_CASH_SAFE_RECONCILIATIONS,
  RESOURCE_BANK_DEPOSIT,
  RESOURCE_CASH_TILLS,
  RESOURCE_STORE_TILLS,
  RESOURCE_CASH_TILL_ASSOCIATIONS,
  RESOURCE_BANK_SUMMARIES,
  RESOURCE_BANK_DEPOSIT_AMOUNT,
} from '../../../constants/resources.const';
import { getLocalStartDateToUTC, getLocalEndDateToUTC } from '../../../utils/timeUtils';

export const storeApi = createApi({
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOST_API}`,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().globals.tokens;

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    /** QUERIES */
    getCashSafe: builder.query({
      invalidatesTags: ['cashSafe'],
      query: ({ safeId }) => ({
        responseHandler: validateCashSafesResponseShape,
        url: `${RESOURCE_CASH_SAFES}/${safeId}`,
      }),
    }),
    getCashSafeFromStoreId: builder.query({
      providesTags: ['cashSafe'],
      queryFn: async ({ storeId }, api, options, fetchWithBQ) => {
        const {
          data: { safes },
        } = await fetchWithBQ({
          responseHandler: validateStoreSafesResponseShape,
          url: `${RESOURCE_STORE_SAFES}/${storeId}`,
        });

        const firstSafeId = retrieveDefaultSafeId(safes);

        return fetchWithBQ({
          responseHandler: validateCashSafesResponseShape,
          url: `${RESOURCE_CASH_SAFES}/${firstSafeId}`,
        });
      },
    }),
    getDepositAmountFromStoreID: builder.query({
      queryFn: async ({ safeId, storeId, currency = 'USD' }, api, options, fetchWithBQ) => {
        const {
          data: { defaultFloat, currentFloat },
        } = await fetchWithBQ({
          //   responseHandler: validateCashSafesResponseShape,
          url: `${RESOURCE_CASH_SAFES}/${safeId}`,
        });
        // stuff
        const amountBackToSafeLocal = getPositiveOrZeroAmount(defaultFloat, currentFloat);
        const {
          data: { currencyCode, depositAmount } = { currencyCode: currency, depositAmount: 0 },
        } = await fetchWithBQ({
          //   responseHandler: validateCashSafesResponseShape,
          url: `${RESOURCE_BANK_DEPOSIT_AMOUNT}/${storeId}`,
        });
        const newDepositAmount = parseFloat(
          getPositiveOrZeroAmount(depositAmount, amountBackToSafeLocal).toFixed(2),
        );
        return { data: { amountBackToSafeLocal, currencyCode, newDepositAmount } };
      },
    }),
    getExistingDepositSlips: builder.query({
      providesTags: ['existingDepositSlip'],
      queryFn: async (_, api, options, fetchWithBQ) => {
        const bankDepositDateBeforeEq = getLocalEndDateToUTC(new Date());
        const bankDepositDateAfterEq = getLocalStartDateToUTC(new Date(2000, 0, 1));

        const data = await fetchWithBQ(
          `${RESOURCE_BANK_SUMMARIES}?filter=bankDepositDateBeforeEq(${bankDepositDateBeforeEq})&filter=bankDepositDateAfterEq(${bankDepositDateAfterEq})`,
        );

        return { data: data.data.objects.map((summary) => summary.depositSlip) };
      },
    }),
    getStoreClosedAndPickedUpTills: builder.query({
      query: ({ storeId }) => ({
        url: `${RESOURCE_STORE_TILLS}/${storeId}`,
      }),
      transformResponse: (response) => {
        return filterClosedOrPickedUpTills(response?.tills);
      },
      providesTags: ['tills'],
    }),
    getStoreSafes: builder.query({
      invalidatesTags: ['cashSafe'],
      query: ({ storeId }) => ({
        responseHandler: validateStoreSafesResponseShape,
        url: `${RESOURCE_STORE_SAFES}/${storeId}`,
      }),
    }),
    getStoreTills: builder.query({
      query: ({ storeId }) => ({
        url: `${RESOURCE_STORE_TILLS}/${storeId}`,
      }),
      providesTags: ['tills'],
    }),
    getTillDetail: builder.query({
      query: ({ tillId }) => ({
        url: `${RESOURCE_CASH_TILLS}/${tillId}`,
      }),
      providesTags: ['tills'],
    }),

    /** MUTATIONS */
    bankDeposit: builder.mutation({
      invalidatesTags: ['existingDepositSlip'],
      query: ({
        currencyCode,
        depositAmount,
        depositSlip,
        expectedDepositAmount,
        varianceExplanation,
        varianceReason,
        amountBackToSafe,
      }) => ({
        body: {
          currencyCode,
          depositAmount,
          depositSlip,
          expectedDepositAmount,
          varianceExplanation,
          varianceReason,
          amountBackToSafe,
        },
        method: 'POST',
        url: RESOURCE_BANK_DEPOSIT,
      }),
    }),
    reconcileSafe: builder.mutation({
      invalidatesTags: ['cashSafe'],
      query: ({ currencyCode, currentFloat, safeNumber, varianceExplanation, varianceReason }) => ({
        body: { currencyCode, currentFloat, safeNumber, varianceExplanation, varianceReason },
        method: 'POST',
        url: RESOURCE_CASH_SAFE_RECONCILIATIONS,
      }),
    }),
    associateTill: builder.mutation({
      query: ({
        currencyCode,
        newCurrentFloat,
        tillNumber,
        varianceReason,
        workstationNumber,
      }) => ({
        url: `${RESOURCE_CASH_TILL_ASSOCIATIONS}`,
        method: 'POST',
        body: { currencyCode, newCurrentFloat, tillNumber, varianceReason, workstationNumber },
      }),
    }),
  }),
  reducerPath: 'storeApi',
  tagTypes: ['cashSafe', 'tills', 'existingDepositSlip'],
});

export const {
  /** QUERIES */
  useGetStoreSafesQuery,
  useGetCashSafeFromStoreIdQuery,
  useGetCashSafeQuery,
  useGetExistingDepositSlipsQuery,
  useGetTillDetailQuery,
  useGetStoreTillsQuery,
  useGetStoreClosedAndPickedUpTillsQuery,
  useGetDepositAmountFromStoreIDQuery,

  /** MUTATION */
  useReconcileSafeMutation,
  useAssociateTillMutation,
  useBankDepositMutation,
} = storeApi;
