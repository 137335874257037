import React from 'react';

import { useGetLocalization } from '../../hooks';
import Button from './Button';

export default function RefreshButton() {
  const getLocalization = useGetLocalization();

  return (
    <Button
      label={getLocalization('lbl.refresh')}
      sectionClassName="d-sm-flx flx-jc-sm-c mt3-sm"
      onClick={() => {
        window.location.reload();
      }}
    />
  );
}
