import { isSome } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ButtonDirection from './ButtonDirection';
import { useGetLocalization } from '../../hooks';

function ListItem({ callBack, columns, index, item, Open, rowIsOpenable, textDisabled }) {
  const [isOpen, setIsOpen] = useState(false);
  const bgColor = index % 2 ? 'bg-primary-grey' : 'bg-white';
  const cursor = rowIsOpenable ? 'u-cursor-pointer' : '';
  const textColor = textDisabled ? 'text-color-disabled' : '';
  const containerClasses = `va-sm-m u-full-width p2-sm ${bgColor} ${cursor} ${textColor}`;

  const tableColumns = columns.map((column) => (
    <div
      className={`ncss-col-sm-${column.width || 1} ${
        column.getClassName ? column.getClassName(item) : ''
      }`}
      key={`col-${uuidv4()}`}
    >
      {column.processor({ ...item, rowIsOpenable })}
    </div>
  ));

  return rowIsOpenable ? (
    <div>
      <button className={containerClasses} type="button" onClick={() => setIsOpen(!isOpen)}>
        {tableColumns}
      </button>
      {isOpen && rowIsOpenable && <Open callBack={callBack} className={bgColor} item={item} />}
    </div>
  ) : (
    <div className={containerClasses}>{tableColumns}</div>
  );
}

ListItem.defaultProps = {
  rowIsOpenable: true,
  textDisabled: false,
};

ListItem.propTypes = {
  callBack: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      getClassName: PropTypes.func,
      width: PropTypes.number,
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape().isRequired,
  // 'Open' component should be able to apply background color as styling in order to keep the gray-white ledger alternation look
  Open: PropTypes.func.isRequired,
  rowIsOpenable: PropTypes.bool,
  textDisabled: PropTypes.bool,
};

function Table({
  title,
  callBack,
  columns,
  data,
  Open,
  itemsPerPage,
  rowsAreOpenable,
  showPagination,
  tableClassName,
}) {
  const getLocalization = useGetLocalization();

  const [page, setPage] = useState(0);
  const [pageItems, setPageItems] = useState(data.slice(0, itemsPerPage));

  const changePage = (isForward) => {
    const newPage = isForward ? page + 1 : page - 1;
    setPageItems(data.slice(newPage * itemsPerPage, (newPage + 1) * itemsPerPage));
    setPage(newPage);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const pageForward = () => changePage(true);
  const pageBack = () => changePage(false);

  const canPageForward = () => !!data[(page + 1) * itemsPerPage];

  const canPageBackward = () => page > 0;

  return (
    <div className={tableClassName}>
      <section className="headline-1">{title}</section>
      {columns.map((column) => (
        <div
          className={`ncss-col-sm-${column.width || 1} headline-4 ${
            column.headerClassName ? column.headerClassName : ''
          }`}
          key={`header-${column.header}`}
        >
          <div>{column.header}</div>
          {column.extraInfo ? (
            <div className={column.extraInfoClassName}>{column.extraInfo && column.extraInfo}</div>
          ) : null}
        </div>
      ))}
      {pageItems.map((item, index) => (
        <ListItem
          key={JSON.stringify(item)}
          Open={Open}
          callBack={callBack}
          columns={columns}
          index={index}
          item={item}
          rowIsOpenable={isSome(item.isOpenable) ? item.isOpenable : rowsAreOpenable}
          textDisabled={isSome(item.isDisabled) ? item.isDisabled : false}
        />
      ))}
      {showPagination && (
        <section className="d-sm-flx flx-jc-sm-c pt2-sm">
          <ButtonDirection
            ariaLabel="Page Back"
            direction="left"
            isDisabled={!canPageBackward()}
            onClick={pageBack}
          />
          <section className="ncss-col-sm-8">{`${getLocalization('lbl.page')} ${
            page + 1
          }/${totalPages}`}</section>
          <ButtonDirection
            ariaLabel="Page Forward"
            direction="right"
            isDisabled={!canPageForward()}
            onClick={pageForward}
          />
        </section>
      )}
    </div>
  );
}

Table.defaultProps = {
  callBack: () => undefined,
  columns: [
    {
      getClassName: () => '',
      extraInfo: null,
      extraInfoClassName: null,
      header: '',
      processor: (item) => JSON.stringify(item),
      width: 1,
    },
  ],
  itemsPerPage: 10,
  Open: (data) => JSON.stringify(data),
  rowsAreOpenable: true,
  showPagination: true,
  tableClassName: '',
};

Table.propTypes = {
  callBack: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      getClassName: PropTypes.func,
      extraInfo: PropTypes.shape({}),
      extraInfoClassName: PropTypes.string,
      header: PropTypes.string,
      processor: PropTypes.func,
      // Sum of all columns' width attributes should be 12
      width: PropTypes.number,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  itemsPerPage: PropTypes.number,
  Open: PropTypes.func,
  rowsAreOpenable: PropTypes.bool,
  showPagination: PropTypes.bool,
  tableClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Table;
