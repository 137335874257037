export function getFromSession(key) {
  return sessionStorage.getItem(key);
}
export function saveInSession(key, val) {
  sessionStorage.setItem(key, val);
}
export function removeFromSession(key) {
  sessionStorage.removeItem(key);
}

export function clearSession() {
  sessionStorage.clear();
}
