import { DropDownStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

import { useGetLocalization } from '../../../../hooks';
import { AnyType } from '../../../../utils/propTypesUtils';

export function DropDown({
  control,
  defaultValue,
  id,
  includeSelectAnOption,
  isRequired,
  label,
  name,
  onChange,
  options,
  supportText,
}) {
  const getLocalization = useGetLocalization();
  const {
    field: { onChange: controllerOnChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
  });

  const dropDownProps = {
    id,
    label,
    name,
    options: includeSelectAnOption
      ? [{ label: getLocalization('msg.component-select-an-option'), value: '' }, ...options]
      : options,
    supportText,
  };

  return (
    <DropDownStyled
      ref={ref}
      error={!!error}
      errorMessage={error?.message}
      requiredIndicator={isRequired ? '*' : ''}
      value={value}
      onBlur={onBlur}
      onChange={(event) => {
        // must be before controller onChange
        if (onChange) {
          onChange(event);
        }
        controllerOnChange(event);
      }}
      {...dropDownProps}
    />
  );
}

DropDown.defaultProps = {
  defaultValue: null,
  includeSelectAnOption: true,
  isRequired: false,
  onChange: null,
  supportText: '',
};

const dropDownOptionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
});

DropDown.propTypes = {
  control: AnyType.isRequired, // react hook form control object
  defaultValue: dropDownOptionShape,
  id: PropTypes.string.isRequired,
  includeSelectAnOption: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(dropDownOptionShape).isRequired,
  supportText: PropTypes.string,
};
