import React from 'react';

import AddSafe from './add';
import AdjustSafe from './adjust';
import SafeLoan from './loan';
import SafeReceipt from './receipt';
import { ReconcileSafe } from './reconcile/ReconcileSafe';
import ReconciliationSummaries from './reconciliation-summaries';
import { SECURITY_ROLES_ENUM } from '../../../constants/roles.const';
import {
  PATH_SAFE_ADD,
  PATH_SAFE_ADJUST,
  PATH_SAFE_LOAN,
  PATH_SAFE_RECEIPT,
  PATH_SAFE_RECONCILIATION,
  PATH_RECONCILIATION_SUMMARIES,
} from '../../../constants/routes.const';
import { useGetLocalization } from '../../../hooks';
import {
  ID_ADD_SAFE_BUTTON,
  ID_ADJUST_SAFE_BUTTON,
  ID_SAFE_LOAN_BUTTON,
  ID_SAFE_RECEIPT_BUTTON,
  ID_SAFE_RECONCILIATION_BUTTON,
  ID_SAFE_RECONCILIATION_SUMMARY_BUTTON,
} from '../../../utils/dom-element-ids';
import { PermissionsContainer, Placeholder, Subheader } from '../../reusable';

function Safes() {
  const getLocalization = useGetLocalization();

  return (
    <>
      <Subheader>{getLocalization('lbl.safes')}</Subheader>
      <PermissionsContainer
        fallback={getLocalization('msg.no-permission')}
        requiredLevel={SECURITY_ROLES_ENUM.LEVEL_40}
      >
        <Placeholder
          id={ID_ADD_SAFE_BUTTON}
          name={getLocalization('lbl.add-safe')}
          redirect={PATH_SAFE_ADD}
        />
        <Placeholder
          id={ID_ADJUST_SAFE_BUTTON}
          name={getLocalization('lbl.adjust-safe')}
          redirect={PATH_SAFE_ADJUST}
        />
        <Placeholder
          id={ID_SAFE_LOAN_BUTTON}
          name={getLocalization('lbl.safe-loan')}
          redirect={PATH_SAFE_LOAN}
        />
        <Placeholder
          id={ID_SAFE_RECEIPT_BUTTON}
          name={getLocalization('lbl.safe-receipt')}
          redirect={PATH_SAFE_RECEIPT}
        />
        <Placeholder
          id={ID_SAFE_RECONCILIATION_BUTTON}
          data={{ isEndOfDayProcess: false }}
          name={getLocalization('lbl.safe-reconciliation')}
          redirect={PATH_SAFE_RECONCILIATION}
        />
        <Placeholder
          id={ID_SAFE_RECONCILIATION_SUMMARY_BUTTON}
          name={getLocalization('lbl.safe-reconciliation-summary')}
          redirect={PATH_RECONCILIATION_SUMMARIES}
        />
      </PermissionsContainer>
    </>
  );
}

export { AddSafe, AdjustSafe, ReconcileSafe, ReconciliationSummaries, SafeLoan, SafeReceipt };
export default Safes;
