import PropTypes from 'prop-types';
import React from 'react';

import { useGetStoreInfo } from '../../globals';
import { getFormattedCurrency } from '../../utils/currencyUtils';

export function MultipleCurrencyText({ value }) {
  const { currency, locale } = useGetStoreInfo();
  const formattedCurrency = getFormattedCurrency(locale, currency, value);
  return <span>{formattedCurrency}</span>;
}

export function GetMultipleCurrencyText(value) {
  const { currency, locale } = useGetStoreInfo();
  const formattedCurrency = getFormattedCurrency(locale, currency, value);
  return formattedCurrency;
}

MultipleCurrencyText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
