import PropTypes from 'prop-types';
import React from 'react';
import { Route, useLocation, Routes } from 'react-router-dom';

import { securityRoleRouteAccess } from '../../constants/roles.const';
import { useGetLocalization } from '../../hooks';
import { useHasRequiredRole } from '../../hooks/useHasRequiredRole';
import { CashModeProvider, CASH_MODE_ROUTES_ACCESS, useCashModeContext } from '../context';
import {
  ActionDisabled,
  ErrorMessage,
  FeatureUnavailable,
  LoadingMessage,
  PermissionDenied,
} from '../reusable';

// TODO: remove when auth refactor occurs
function PrivateContextWrapper({ element }) {
  return <CashModeProvider>{element}</CashModeProvider>;
}

PrivateContextWrapper.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
};

function PrivateRoute({ element, isEnabled, ...rest }) {
  const { cashMode, cashModeError } = useCashModeContext();
  const getLocalization = useGetLocalization();
  const { pathname } = useLocation();

  const hasRequiredRole = useHasRequiredRole();
  const hasRole = hasRequiredRole(securityRoleRouteAccess[pathname] || []);
  const isGuardedPermissionRoute = pathname in securityRoleRouteAccess;
  const userDoesNotHavePermission = isGuardedPermissionRoute && !hasRole;

  if (!cashMode) {
    return cashModeError ? (
      <ErrorMessage rawError={cashModeError}>{getLocalization('msg.generic-error')}</ErrorMessage>
    ) : (
      <LoadingMessage />
    );
  }

  if (!isEnabled) {
    return <FeatureUnavailable />;
  }

  if (userDoesNotHavePermission) {
    return <PermissionDenied />;
  }

  const isGuardedCashModeRoute = pathname in CASH_MODE_ROUTES_ACCESS[cashMode];
  const hasCashModeAccess = CASH_MODE_ROUTES_ACCESS[cashMode][pathname];
  const actionNotAllowedInCashMode = isGuardedCashModeRoute && !hasCashModeAccess;
  if (actionNotAllowedInCashMode) {
    return <ActionDisabled />;
  }

  return (
    <Routes>
      <Route {...rest} element={element} />
    </Routes>
  );
}

PrivateRoute.defaultProps = {
  isEnabled: true,
};

PrivateRoute.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  isEnabled: PropTypes.bool,
};

function PrivateRouteWrapper({ element, isEnabled, ...rest }) {
  return (
    <PrivateContextWrapper element={element}>
      <PrivateRoute element={element} isEnabled={isEnabled} {...rest} />
    </PrivateContextWrapper>
  );
}

PrivateRouteWrapper.defaultProps = {
  element: () => <div />,
  isEnabled: true,
};

PrivateRouteWrapper.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]),
  isEnabled: PropTypes.bool,
};

export default PrivateRouteWrapper;
