import React from 'react';

import { useGetLocalization } from '../hooks';

function Unauthorized() {
  const getLocalization = useGetLocalization();
  return (
    <main className="ncss-col-sm-12 ta-sm-c">
      <h1 className="display-3 mt10-sm mb4-sm text-color-error">
        {getLocalization('lbl.unauthorized')}
      </h1>
      <p className="body-1">{getLocalization('msg.you-are-not-authorized-to-view-this-page')}</p>
      <p className="body-1">{getLocalization('msg.please-renew-your-credentials')}</p>
    </main>
  );
}

export default Unauthorized;
