import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_LOAN_AMOUNT, ID_TILL_CEILING } from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function AcceptLoanForm({
  formErrors,
  getCurrentFloat,
  isDisabled,
  loanAmount,
  loanAmountError,
  setLoanAmount,
  setTillCeiling,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  tillCeiling,
  tillCeilingError,
  currency,
  setCurrency,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <MultipleCurrencyInput
        isRequired
        currency={currency}
        error={!!loanAmountError}
        errorMessage={loanAmountError}
        id={ID_LOAN_AMOUNT}
        label={getLocalization('lbl.loan-amount')}
        message={getCurrentFloat}
        name="Loan Amount"
        setAmount={setLoanAmount}
        setCurrency={setCurrency}
        showErrorMessage={!!loanAmountError}
        value={loanAmount}
      />
      <MultipleCurrencyInput
        isReadOnly
        currency={currency}
        error={!!tillCeilingError}
        errorMessage={tillCeilingError}
        id={ID_TILL_CEILING}
        label={getLocalization('lbl.till-ceiling')}
        name="Till Ceiling"
        setAmount={setTillCeiling}
        setCurrency={setCurrency}
        showErrorMessage={!!tillCeilingError}
        value={tillCeiling}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.accept-loan')}
        messageError={formErrors || submitError}
        messageSuccess={submitError || formErrors ? emptyString : submitSuccess}
        onSubmit={submit}
      />
    </>
  );
}

AcceptLoanForm.propTypes = {
  currency: PropTypes.string.isRequired,
  formErrors: PropTypes.string.isRequired,
  getCurrentFloat: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  loanAmount: PropTypes.number.isRequired,
  loanAmountError: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setLoanAmount: PropTypes.func.isRequired,
  setTillCeiling: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  tillCeiling: PropTypes.number.isRequired,
  tillCeilingError: PropTypes.string.isRequired,
};

export default AcceptLoanForm;
