import isNil from 'lodash.isnil';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

function Messages({ success, error, rawError }) {
  return (
    <>
      <SuccessMessage>{success}</SuccessMessage>
      {!isNil(error) && error !== '' ? (
        <ErrorMessage rawError={rawError}>{error}</ErrorMessage>
      ) : null}
    </>
  );
}

Messages.propTypes = {
  error: PropTypes.string,
  rawError: ErrorMessage.propTypes.rawError,
  success: PropTypes.string,
};

Messages.defaultProps = {
  error: '',
  rawError: null,
  success: '',
};

export default Messages;
