import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import SafeReceiptForm from './SafeReceiptForm';
import { useGetStoreInfo } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import { getFormattedCurrency } from '../../../../utils/currencyUtils';
import { getSafes, safeReceipt, getSafeDetail } from '../../../../utils/service-calls';
import { LoadingIndicator, Subheader } from '../../../reusable';

function SafeReceipt() {
  const getLocalization = useGetLocalization();
  const { currency, locale } = useGetStoreInfo();

  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const SAFE_RECEIPT_SUCCESSFUL = getLocalization('msg.safe-receipt-successful');
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const NO_SAFE_DATA = getLocalization('msg.no-safe-exists');
  const PLEASE_RESOLVE_ERRORS = getLocalization('msg.form-errors');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.safe-receipt-failed')}: ${message}`;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState('');

  const [currencyCode, setCurrencyCode] = useState('');
  const [safeName, setSafeName] = useState('');
  const [safeNumber, setSafeNumber] = useState(0);
  const [adjustmentAmount, setAdjustmentAmount] = useState(0.0);
  const [currentFloat, setCurrentFloat] = useState(0.0);

  const [defaultSafeData, setDefaultSafeData] = useState();
  const [safeDataError, setSafeDataError] = useState('');
  const [defaultSafeDataError, setDefaultSafeDataError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [adjustmentAmountError, setAdjustmentAmountError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);

  const retrieveSafeDetail = (safeId) => {
    setSubmitLoading(true);
    getSafeDetail(safeId)
      .then(({ data }) => {
        setCurrentFloat(data.currentFloat);
        setDefaultSafeData(data);
        return setIsFormVisible(true);
      })
      .catch((err) => setDefaultSafeDataError(err.message))
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    setSubmitLoading(true);
    getSafes()
      .then(({ data }) => {
        const defaultSafe = data.safes[data.safes.length - 1];
        setCurrencyCode(defaultSafe.currencyCode);
        setSafeName(defaultSafe.safeName);
        setSafeNumber(defaultSafe.safeNumber);
        return retrieveSafeDetail(defaultSafe.id);
      })
      .catch((err) => setDefaultSafeDataError(err.message))
      .finally(() => setSubmitLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const adjustmentAmountErrors = match()(
      [() => typeof adjustmentAmount !== 'number', REQUIRED_FIELD],
      [() => adjustmentAmount < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const safeDataErrors = match()(
      [() => defaultSafeData === undefined, NO_SAFE_DATA],
      [() => !!setDefaultSafeDataError, defaultSafeDataError],
      [Default, ''],
    );
    setAdjustmentAmountError(adjustmentAmountErrors);
    setSafeDataError(safeDataErrors);
    setIsDisabled(!!(adjustmentAmountErrors || safeDataErrors));
    setResponseError(isDisabled ? PLEASE_RESOLVE_ERRORS : '');
  }, [
    adjustmentAmount,
    defaultSafeData,
    defaultSafeDataError,
    isDisabled,
    NO_SAFE_DATA,
    PLEASE_RESOLVE_ERRORS,
    POSITIVE_NUMBER,
    REQUIRED_FIELD,
  ]);

  const submit = () => {
    setSubmitLoading(true);
    setSubmitSuccess('');
    setSubmitError('');

    safeReceipt({
      adjustmentAmount,
      currencyCode,
      safeNumber,
    })
      .then(() => setSubmitSuccess(SAFE_RECEIPT_SUCCESSFUL))
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  const getCurrentFloatMessage = () =>
    `${getLocalization('lbl.current-float')}: ${getFormattedCurrency(
      locale,
      currency,
      currentFloat,
    )}`;

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.safe-receipt')}</Subheader>
      {isFormVisible ? (
        <SafeReceiptForm
          adjustmentAmount={adjustmentAmount}
          adjustmentAmountError={adjustmentAmountError}
          currencyCode={currencyCode}
          currentFloatMessage={getCurrentFloatMessage()}
          isDisabled={isDisabled}
          responseError={responseError}
          safeDataError={safeDataError}
          safeName={safeName}
          safeNumber={safeNumber}
          setAdjustmentAmount={setAdjustmentAmount}
          setCurrencyCode={setCurrencyCode}
          submit={submit}
          submitError={submitError}
          submitLoading={submitLoading}
          submitSuccess={submitSuccess}
        />
      ) : (
        <LoadingIndicator />
      )}
    </article>
  );
}

export default SafeReceipt;
