import { fixedDecimalCount } from './currencyUtils';

function createTenderData(tillDetails) {
  // TODO What should we *really* do if we ingest corrupt/missing till data?
  if (tillDetails === undefined) return null;

  const {
    acceptedLoanAmount,
    currentFloat,
    defaultFloat = 0,
    virtualTenders,
    currencyCode,
    foreignTenders,
  } = tillDetails;

  const actualAmount = currentFloat - defaultFloat;

  const cashTender = {
    acceptedLoanAmount,
    actualAmount,
    currentCount: 0, // eventually replace 0 with count data
    currentFloat,
    defaultFloat,
    primaryCurrency: true,
    subTenderType: currencyCode,
    tenderType: 'CASH',
  };

  const foreignCashTenders = foreignTenders
    .filter((tender) => tender.tenderType === 'CASH' && tender.subTenderType)
    .map((tender) => {
      return {
        ...tender,
        actualAmount: Number(
          fixedDecimalCount((tender.currentFloat ?? 0) - (tender.defaultFloat ?? 0)),
        ),
      };
    });

  const nonCashVirtualTenders = virtualTenders.filter((tender) => tender.tenderType !== 'CASH');

  return [cashTender, ...foreignCashTenders, ...nonCashVirtualTenders];
}

export default createTenderData;
