import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from './App';
import { AuthMessaging } from './AuthMessaging';
import { Loader } from './components/reusable';
import { setupStore } from './redux/store';

export const store = setupStore();

function AppWithAuth() {
  return (
    <AuthMessaging appName="Nike Cash" loader={<Loader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthMessaging>
  );
}

ReactDOM.render(<AppWithAuth />, document.getElementById('root'));
