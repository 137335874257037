import { ButtonStyled } from '@nike/nike-design-system-components';
import { Maybe } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../hooks';
import { ID_SUBMIT_BUTTON } from '../../utils/dom-element-ids';
import ContentLoads from './ContentLoads';
import Messages from './Messages';

function ButtonSubmit({
  buttonClassName,
  buttonSize,
  id,
  isDisabled,
  isSubmitting,
  label,
  messageError,
  messageRawError,
  messageSuccess,
  onSubmit,
  sectionClassName,
}) {
  const getLocalization = useGetLocalization();
  const effectiveLabel = Maybe.of(label).orElseGet(() => getLocalization('lbl.submit'));
  return (
    <ContentLoads isLoading={isSubmitting}>
      <section className={sectionClassName}>
        <Messages
          error={messageError}
          rawError={messageRawError}
          // Note: An error will automatically hide a success message!
          success={messageError || messageRawError ? null : messageSuccess}
        />
        <ButtonStyled
          ariaLabel={effectiveLabel}
          className={buttonClassName}
          disabled={isDisabled}
          id={id}
          size={buttonSize}
          type="button"
          onClick={onSubmit}
        >
          {effectiveLabel}
        </ButtonStyled>
      </section>
    </ContentLoads>
  );
}

ButtonSubmit.defaultProps = {
  buttonClassName: 'mb2-sm',
  buttonSize: 'large',
  id: ID_SUBMIT_BUTTON,
  isDisabled: false,
  isSubmitting: false,
  messageError: '',
  messageRawError: null,
  messageSuccess: '',
  sectionClassName: 'ncss-col-sm-12 full',
};

ButtonSubmit.propTypes = {
  buttonClassName: PropTypes.string,
  buttonSize: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string.isRequired,
  messageError: PropTypes.string,
  messageRawError: Messages.propTypes.rawError,
  messageSuccess: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  sectionClassName: PropTypes.string,
};

export default ButtonSubmit;
