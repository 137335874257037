import { useState, useEffect } from 'react';

import { useGetTargetOrigin } from './useGetTargetOrigin';

const initialState = {
  lastMessage: undefined,
  messages: [],
};

export const useRetailLaunchpadMessaging = (isTruePortal) => {
  const getTargetOrigins = useGetTargetOrigin();
  const [messageState, setMessageState] = useState(initialState);

  function postMessage(message) {
    const docRef = document.referrer;
    if (getTargetOrigins.length && getTargetOrigins.includes(docRef)) {
      window.parent.postMessage(message, docRef);
    }
  }

  function updateState(data) {
    setMessageState((prevState) => ({
      lastMessage: data,
      messages: prevState.messages.concat(data),
    }));
  }

  // TODO: prevent useEffect from running when not TRUE
  useEffect(() => {
    const onWindowMessage = (e) => updateState(e.data);

    if (isTruePortal) {
      window.addEventListener('message', onWindowMessage);
    }
    return () => {
      window.removeEventListener('message', onWindowMessage);
    };
  }, [isTruePortal]);

  return {
    messageState,
    postMessage,
  };
};
