export const NIKE_STORES = [
  { label: 'AFG', value: 'AFG' },
  { label: 'ALA', value: 'ALA' },
  { label: 'AND', value: 'AND' },
  { label: 'ARE', value: 'ARE' },
  { label: 'ARG', value: 'ARG' },
  { label: 'ARM', value: 'ARM' },
  { label: 'ASM', value: 'ASM' },
  { label: 'AUS', value: 'AUS' },
  { label: 'AUT', value: 'AUT' },
  { label: 'AZE', value: 'AZE' },
  { label: 'BEL', value: 'BEL' },
  { label: 'BGR', value: 'BGR' },
  { label: 'BHR', value: 'BHR' },
  { label: 'BIH', value: 'BIH' },
  { label: 'BLR', value: 'BLR' },
  { label: 'BOL', value: 'BOL' },
  { label: 'BRA', value: 'BRA' },
  { label: 'BVT', value: 'BVT' },
  { label: 'CAN', value: 'CAN' },
  { label: 'CHE', value: 'CHE' },
  { label: 'CHL', value: 'CHL' },
  { label: 'CHN', value: 'CHN' },
  { label: 'CYP', value: 'CYP' },
  { label: 'CZE', value: 'CZE' },
  { label: 'DEU', value: 'DEU' },
  { label: 'DMA', value: 'DMA' },
  { label: 'DNK', value: 'DNK' },
  { label: 'DZA', value: 'DZA' },
  { label: 'ESP', value: 'ESP' },
  { label: 'EST', value: 'EST' },
  { label: 'FIN', value: 'FIN' },
  { label: 'FLK', value: 'FLK' },
  { label: 'FRA', value: 'FRA' },
  { label: 'GBR', value: 'GBR' },
  { label: 'GEO', value: 'GEO' },
  { label: 'GRC', value: 'GRC' },
  { label: 'HKG', value: 'HKG' },
  { label: 'HRV', value: 'HRV' },
  { label: 'HUN', value: 'HUN' },
  { label: 'IND', value: 'IND' },
  { label: 'IRL', value: 'IRL' },
  { label: 'ISR', value: 'ISR' },
  { label: 'ITA', value: 'ITA' },
  { label: 'JOR', value: 'JOR' },
  { label: 'JPN', value: 'JPN' },
  { label: 'KAZ', value: 'KAZ' },
  { label: 'KGZ', value: 'KGZ' },
  { label: 'KOR', value: 'KOR' },
  { label: 'KWT', value: 'KWT' },
  { label: 'LBN', value: 'LBN' },
  { label: 'LTU', value: 'LTU' },
  { label: 'LUX', value: 'LUX' },
  { label: 'LVA', value: 'LVA' },
  { label: 'MAC', value: 'MAC' },
  { label: 'MDA', value: 'MDA' },
  { label: 'MEX', value: 'MEX' },
  { label: 'MKD', value: 'MKD' },
  { label: 'MYS', value: 'MYS' },
  { label: 'NLD', value: 'NLD' },
  { label: 'NOR', value: 'NOR' },
  { label: 'NZL', value: 'NZL' },
  { label: 'OMN', value: 'OMN' },
  { label: 'POL', value: 'POL' },
  { label: 'PRT', value: 'PRT' },
  { label: 'QAT', value: 'QAT' },
  { label: 'ROU', value: 'ROU' },
  { label: 'RUS', value: 'RUS' },
  { label: 'SAU', value: 'SAU' },
  { label: 'SGP', value: 'SGP' },
  { label: 'SRB', value: 'SRB' },
  { label: 'SVK', value: 'SVK' },
  { label: 'SVN', value: 'SVN' },
  { label: 'SWE', value: 'SWE' },
  { label: 'THA', value: 'THA' },
  { label: 'TKM', value: 'TKM' },
  { label: 'TUR', value: 'TUR' },
  { label: 'TWN', value: 'TWN' },
  { label: 'UKR', value: 'UKR' },
  { label: 'URY', value: 'URY' },
  { label: 'USA', value: 'USA' },
  { label: 'UZB', value: 'UZB' },
  { label: 'ZAF', value: 'ZAF' },
];
