import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

import { AnyType } from '../../../../utils/propTypesUtils';

/**
 * @typedef {Object} TextFieldProps
 * @property {import('react-hook-form').Control} TextFieldProps.control React Hook Form control variable
 * @property {string} TextFieldProps.defaultValue The default value of the text field
 * @property {string} TextFieldProps.name The name for React Hook Form to consume */

/**
 *
 * @param {import('@nike/nike-design-system-components/types/src/components/TextInput/TextField/TextField').TextFieldProps & TextFieldProps} rest Nike TextField props
 */
export function TextField({ control, defaultValue, name, ...rest }) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
  });

  return (
    <TextFieldStyled
      ref={ref}
      error={!!error}
      errorMessage={error?.message}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  );
}

TextField.defaultProps = {
  defaultValue: null,
};

TextField.propTypes = {
  control: AnyType.isRequired, // react hook form control object
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};
