import React from 'react';

import { useGetProfile } from '../../globals';
import { useGetLocalization } from '../../hooks';
import { useGetStoreTillsQuery } from '../../redux/services/store/storeService';
import { ID_SAFE_LOANS_STATUS_INFO } from '../../utils/dom-element-ids';
import { ErrorMessage, InformationModal, Loader, Table } from '../reusable';
import { GetMultipleCurrencyText } from '../reusable/MultipleCurrencyText';

function SafeLoanStatus() {
  const getLocalization = useGetLocalization();
  const { storeId } = useGetProfile();
  const {
    data: { tills } = {},
    error: { message: tillsError } = {},
    isFetching: isTillsFetching,
    isLoading: isTillsLoading,
  } = useGetStoreTillsQuery({ storeId });

  const tillLoans = tills
    ? tills.reduce(
        (acc, { loansForTheDay }) =>
          loansForTheDay ? [...acc, ...Object.values(loansForTheDay)] : acc,
        [],
      )
    : [];

  if (isTillsLoading || isTillsFetching) return <Loader />;

  const getClassCenter = () => 'ta-sm-c';

  return (
    <div className="mt12-sm mb5-sm">
      {tillsError ? (
        <ErrorMessage rawError={tillsError}>
          {getLocalization('msg.error-fetching-tills')}
        </ErrorMessage>
      ) : (
        <Table
          columns={[
            {
              getClassName: getClassCenter,
              header: getLocalization('lbl.till-number'),
              headerClassName: 'ta-sm-c',
              processor: ({ tillNumber }) => tillNumber,
              width: 3,
            },
            {
              getClassName: getClassCenter,
              header: getLocalization('lbl.loan-amount'),
              headerClassName: 'ta-sm-c',
              processor: ({ loanAmount }) => GetMultipleCurrencyText(loanAmount),
              width: 3,
            },
            {
              extraInfo: (
                <InformationModal
                  modalTitle={getLocalization('lbl.safe-loans-status')}
                  informationList={[
                    {
                      item: 'lbl.accepted',

                      definition: 'lbl.accepted-definition',
                    },
                    {
                      item: 'lbl.pending',

                      definition: 'lbl.pending-definition',
                    },
                  ]}
                  iconId={ID_SAFE_LOANS_STATUS_INFO}
                />
              ),
              extraInfoClassName: 'ml2-sm',
              header: getLocalization('lbl.status'),
              headerClassName: 'd-sm-iflx flx-jc-sm-c flx-ai-sm-c',
              processor: ({ status }) => getLocalization(`lbl.${status.toLowerCase()}`),
              width: 4,
            },
          ]}
          data={tillLoans}
          itemsPerPage={tillLoans.length}
          rowsAreOpenable={false}
          showPagination={false}
          tableClassName="ta-sm-c"
          title={getLocalization('lbl.safe-loans-status')}
        />
      )}
    </div>
  );
}

export default SafeLoanStatus;
