import { match, Default } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import TillInForm from './TillInForm';
import { useGetLocalization } from '../../../../hooks';
import { updateItemById } from '../../../../utils/arrayUtils';
import { updateTillPayload } from '../../../../utils/dataUtils';
import { getTills, getTillDetail, tillIn } from '../../../../utils/service-calls';
import { ErrorMessage, Subheader, TillSelect } from '../../../reusable';

const tillStatus = 'ACTIVE';

function TillIn() {
  const getLocalization = useGetLocalization();
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const SUBMIT_SUCCESS = getLocalization('msg-till-successfully-activated');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.till-activation-failed')}: ${message}`;

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState(null);

  const [selectedTill, setSelectedTill] = useState(null);
  const [retrievedTillError, setRetrievedTillError] = useState(null);
  const [retrievingTills, setRetrievingTills] = useState(true);

  const [tillData, setTillData] = useState({
    currencyCode: '',
    status: '',
    tillNumber: '',
    workstationNumber: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const filterTills = (tills) =>
    tills
      .filter(({ status }) =>
        match(status)(['CLOSED', true], ['PICKED_UP', true], ['ACTIVE', false], [Default, false]),
      )
      .filter((till) => till.workstationNumber);

  const retrieveTillsForTillIn = (data) => {
    const updatedPayload = updateTillPayload(data, tillStatus);
    const updatedTills = updateItemById(availableTills, updatedPayload);
    setAvailableTills(filterTills(updatedTills));
  };

  useEffect(() => {
    const fetchData = async () =>
      getTills()
        .then(({ data }) => setAvailableTills(filterTills(data.tills)))
        .catch((err) => setAvailableTillsError(err))
        .finally(() => setRetrievingTills(false));
    fetchData();
  }, []);

  const getWorkstationNumberError = () => (!tillData.workstationNumber ? REQUIRED_FIELD : '');

  const getFormErrors = () => getWorkstationNumberError() && ERRORS;

  const shouldSubmitBeDisabled = () => !!getFormErrors();

  const retrieveSelectedTill = (tillId) => {
    setSubmitSuccess('');
    setSelectedTill(null);
    setSubmitLoading(true);
    return getTillDetail(tillId)
      .then(({ data }) => {
        const { currencyCode, workstationNumber, tillNumber, status } = data;
        setTillData({
          currencyCode,
          status,
          tillNumber,
          workstationNumber,
        });
        return setSelectedTill(tillId);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const submit = () => {
    setSubmitError('');
    setSubmitLoading(true);
    return tillIn(tillData)
      .then(async (res) => {
        await retrieveTillsForTillIn(res.data);
        return setSubmitSuccess(SUBMIT_SUCCESS);
      })
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.till-in')}</Subheader>
      {availableTillsError ? (
        <ErrorMessage rawError={availableTillsError}>
          {getLocalization('msg.error-loading-tills')}
        </ErrorMessage>
      ) : (
        <TillSelect
          availableTills={availableTills}
          retrieveSelectedTill={retrieveSelectedTill}
          retrievingTills={retrievingTills}
          selectedTill={selectedTill}
        />
      )}
      {retrievedTillError ? (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.error-loading-requested-till')}
        </ErrorMessage>
      ) : (
        selectedTill && (
          <TillInForm
            formErrors={getFormErrors()}
            isSubmitDisabled={shouldSubmitBeDisabled()}
            submit={submit}
            submitError={submitError}
            submitLoading={submitLoading}
            submitSuccess={submitSuccess}
            workstationNumber={tillData.workstationNumber.toString()}
            workstationNumberError={getWorkstationNumberError()}
          />
        )
      )}
    </article>
  );
}

export default TillIn;
