import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import AmountsTable from './AmountsTable';
import { PATH_END_OF_DAY } from '../../../../constants/routes.const';
import { useGetLocalization } from '../../../../hooks';
import {
  ID_FINAL_PICKUP_TABLE,
  ID_BACK_TO_TILL_SUMMARY_ANCHOR,
} from '../../../../utils/dom-element-ids';
import { ButtonSubmit } from '../../../reusable';

function PickupDeclarationForm({
  backToTillSummaryEnabled,
  isDisabled,
  pickupAmount,
  setVirtualTenders,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  tillNumber,
  virtualTenders,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <AmountsTable
        rowsAreOpenable
        id={ID_FINAL_PICKUP_TABLE}
        isDisabled={isDisabled || submitLoading}
        modalTitle={getLocalization('lbl.till-final-pickup-declaration')}
        pickupAmount={pickupAmount}
        setVirtualTenders={setVirtualTenders}
        submit={submit}
        submitError={submitError}
        submitLoading={submitLoading}
        submitSuccess={submitSuccess}
        tillNumber={tillNumber}
        virtualTenders={virtualTenders}
      />
      <br />
      {backToTillSummaryEnabled && (
        <Link
          id={ID_BACK_TO_TILL_SUMMARY_ANCHOR}
          to={{
            pathname: PATH_END_OF_DAY,
          }}
        >
          <ButtonSubmit
            buttonClassName="mb2-sm"
            buttonSize="large"
            label={getLocalization('lbl.back-to-till-summary')}
            sectionClassName="ncss-col-sm-12 full"
            onSubmit={() => {}}
          />
        </Link>
      )}
    </>
  );
}

PickupDeclarationForm.propTypes = {
  backToTillSummaryEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  pickupAmount: PropTypes.number.isRequired,
  setVirtualTenders: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  tillNumber: PropTypes.number.isRequired,
  virtualTenders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default PickupDeclarationForm;
