import { DatePickerStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../hooks';

function DateInput({
  errorMessage,
  id,
  isDisabled,
  isRequired,
  label,
  locale,
  maxDate,
  minDate,
  onChange,
  value,
}) {
  const getLocalization = useGetLocalization();
  return (
    <DatePickerStyled
      error={errorMessage || (isRequired && !value)}
      errorMessage={errorMessage}
      iconTitle={getLocalization('lbl.calendar-icon')}
      id={id}
      label={label}
      locale={locale}
      max={maxDate}
      min={minDate}
      name={id}
      readOnly={isDisabled}
      requiredIndicator={isRequired ? '*' : ''}
      value={value}
      onChange={({ target: { value: newValue } }) => onChange(newValue)}
    />
  );
}

DateInput.defaultProps = {
  errorMessage: '',
  isDisabled: false,
  isRequired: false,
  locale: 'US',
  // Dates MUST be in this format
  maxDate: '9999-12-31',
  minDate: '0000-01-01',
};

DateInput.propTypes = {
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  locale: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DateInput;
