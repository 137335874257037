import { useI18n } from '@nike/i18n-react';
import { useCallback } from 'react';

import translationConfig from '../translationConfig.json';

export const useGetLocalization = () => {
  const { i18nString } = useI18n();

  return useCallback(
    (key, icuTokens = null, icuOptions = null) => {
      return i18nString({ ...translationConfig[key], icuTokens, icuOptions });
    },
    [i18nString],
  );
};
