import PropTypes from 'prop-types';
import React from 'react';

import { Loader } from './Loader';
import { useGetLocalization } from '../../hooks';

function LoadingIndicator({ className }) {
  const getLocalization = useGetLocalization();
  return <Loader title={getLocalization('lbl.loading-indicator')} className={className} />;
}

LoadingIndicator.defaultProps = {
  className: '',
};

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};

export default LoadingIndicator;
