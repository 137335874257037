import isEmpty from 'lodash.isempty';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useGetStore } from './useGetStore';
import { useGetAuthData } from '../AuthMessaging';
import {
  useSetGlobalLangLocale,
  useSetGlobalProfile,
  useSetGlobalStoreInfo,
  useSetGlobalTokens,
} from '../globals';
import { setItemInSessionStorage } from '../utils/localStorage';

export function useWatchGlobals() {
  const setGlobalLangLocale = useSetGlobalLangLocale();
  const setGlobalProfile = useSetGlobalProfile();
  const setGlobalTokens = useSetGlobalTokens();
  const setGlobalStoreInfo = useSetGlobalStoreInfo();
  const getStore = useGetStore();
  const { langLocale, profile, tokens } = useGetAuthData();

  useDeepCompareEffect(() => {
    if (!isEmpty(langLocale)) {
      setGlobalLangLocale(langLocale);
    }
  }, [setGlobalLangLocale, langLocale]);

  useDeepCompareEffect(() => {
    if (!isEmpty(tokens)) {
      setGlobalTokens(tokens);
      setItemInSessionStorage('accessToken', tokens.accessToken);
    }
  }, [setGlobalTokens, tokens]);

  useDeepCompareEffect(() => {
    if (!isEmpty(profile)) {
      setGlobalProfile(profile);
      setItemInSessionStorage('storeId', profile.storeId);
      setItemInSessionStorage('country', profile.country);
      getStore(profile.country, profile.storeNumber).then((storeInfo) => {
        setGlobalStoreInfo(storeInfo);
      });
    }
  }, [setGlobalProfile, profile]);
}
