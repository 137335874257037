import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import {
  isDateAfter,
  isDateBefore,
  isPresent,
  isValidDate,
  validate,
} from '../../../common/summary-form-validation';
import { ButtonSubmit, InputDate } from '../../../reusable';

function TillVarianceSummaryForm({
  country,
  endDate,
  formState,
  maxDate,
  minDate,
  onSubmit,
  setEndDate,
  setStartDate,
  startDate,
  dateFormat,
}) {
  const getLocalization = useGetLocalization();

  const START_DATE_LABEL = getLocalization('lbl.earliest-search-date');
  const END_DATE_LABEL = getLocalization('lbl.latest-search-date');
  const SEARCH_LABEL = getLocalization('lbl.search');

  const startDateError = validate(
    startDate,
    [isPresent, `${START_DATE_LABEL} ${getLocalization('msg.is-a-required-field')}.`],
    [
      isValidDate(dateFormat),
      `${START_DATE_LABEL} ${getLocalization('msg.must-be-in-format')} ${dateFormat}`,
    ],
    [
      isDateBefore(dateFormat, endDate),
      `${START_DATE_LABEL} ${getLocalization('msg.must-be-before')} ${END_DATE_LABEL}`,
    ],
    [
      isDateBefore(dateFormat, maxDate),
      `${START_DATE_LABEL} ${getLocalization('msg.must-be-on-or-before')} ${maxDate}`,
    ],
    [
      isDateAfter(dateFormat, minDate),
      `${START_DATE_LABEL} ${getLocalization('msg.must-be-on-or-after')} ${minDate}`,
    ],
  );

  const endDateError = validate(
    endDate,
    [isPresent, `${END_DATE_LABEL} ${getLocalization('msg.is-a-required-field')}.`],
    [
      isValidDate(dateFormat),
      `${END_DATE_LABEL} ${getLocalization('msg.must-be-in-format')} ${dateFormat}`,
    ],
    [
      isDateAfter(dateFormat, startDate),
      `${END_DATE_LABEL} ${getLocalization('msg.must-be-after')} ${START_DATE_LABEL}`,
    ],
    [
      isDateBefore(dateFormat, maxDate),
      `${END_DATE_LABEL} ${getLocalization('msg.must-be-on-or-before')} ${maxDate}`,
    ],
    [
      isDateAfter(dateFormat, minDate),
      `${END_DATE_LABEL} ${getLocalization('msg.must-be-on-or-after')} ${minDate}`,
    ],
  );

  const hasErrors = [startDateError, endDateError].some((s) => s !== '');

  const isDisabled = (aFormState) => aFormState === 'SUBMITTING';

  return (
    <>
      <InputDate
        isRequired
        errorMessage={startDateError}
        id="TillVarianceSummaryStartDate"
        isDisabled={isDisabled(formState)}
        label={START_DATE_LABEL}
        locale={country}
        maxDate={maxDate}
        minDate={minDate}
        value={startDate}
        onChange={setStartDate}
      />
      <InputDate
        isRequired
        errorMessage={endDateError}
        id="TillVarianceSummaryEndDate"
        isDisabled={isDisabled(formState)}
        label={END_DATE_LABEL}
        locale={country}
        maxDate={maxDate}
        minDate={minDate}
        value={endDate}
        onChange={setEndDate}
      />
      <ButtonSubmit
        key="TillVarianceSummarySubmitButton"
        isDisabled={isDisabled(formState) || hasErrors}
        isSubmitting={formState === 'SUBMITTING'}
        label={SEARCH_LABEL}
        onSubmit={onSubmit}
      />
    </>
  );
}

TillVarianceSummaryForm.propTypes = {
  country: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default TillVarianceSummaryForm;
