import { TextStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../hooks/useGetLocalization';

export function BrowserError({ reason }) {
  const getLocalization = useGetLocalization();
  const getMessage = () =>
    reason === 'incognito'
      ? getLocalization('msg.browser-error-incognito')
      : getLocalization('msg.browser-error-unsupported');

  return <TextStyled className="header-center-slot pt12-sm">{getMessage()}</TextStyled>;
}

BrowserError.propTypes = {
  reason: PropTypes.oneOf(['incognito', 'unsupported']).isRequired,
};
