import {
  PATH_ACCEPT_LOAN,
  PATH_BANK_DEPOSIT,
  PATH_BANK_SUMMARY,
  PATH_CASH_PICKUP,
  PATH_END_OF_DAY,
  PATH_PICKUP_DECLARATION,
  PATH_RECONCILIATION_SUMMARIES,
  PATH_SAFE_ADD,
  PATH_SAFE_ADJUST,
  PATH_SAFE_LOAN,
  PATH_SAFE_RECEIPT,
  PATH_SAFE_RECONCILIATION,
  PATH_TILL_ADD,
  PATH_TILL_ADJUST,
  PATH_TILL_ASSOCIATION,
  PATH_TILL_AUDIT,
  PATH_TILL_IN,
  PATH_TILL_STATUS,
  PATH_TILL_OUT,
  PATH_TILL_VARIANCE_SUMMARY,
} from './routes.const';

const LEVEL_10 = ['GeneralBOH', 'GeneralFOH'];

const LEVEL_20 = ['Cashier', 'ShippingReceivingAssociate'];

const LEVEL_30 = ['CustomerService', 'InventoryAdjuster'];

const LEVEL_40 = ['Admin', 'KeyHolder'];

const LEVEL_50 = ['AssistantManager', 'AssistantManagerBOH', 'AssistantManagerFOH'];

const LEVEL_60 = ['ManagerBOH', 'ManagerFOH', 'StoreManager'];

const LEVEL_99 = ['HelpDesk', 'SupportBOH', 'SupportFOH'];

export const SECURITY_ROLES_ENUM = {
  LEVEL_10,
  LEVEL_20,
  LEVEL_30,
  LEVEL_40,
  LEVEL_50,
  LEVEL_60,
  LEVEL_99,
};

export const securityRoleRouteAccess = {
  // Cash
  [PATH_END_OF_DAY]: SECURITY_ROLES_ENUM.LEVEL_40,

  // Banking
  [PATH_BANK_DEPOSIT]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_BANK_SUMMARY]: SECURITY_ROLES_ENUM.LEVEL_40,

  // Safe
  [PATH_SAFE_ADD]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_SAFE_ADJUST]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_SAFE_LOAN]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_SAFE_RECEIPT]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_SAFE_RECONCILIATION]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_RECONCILIATION_SUMMARIES]: SECURITY_ROLES_ENUM.LEVEL_40,

  // Till
  [PATH_ACCEPT_LOAN]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_TILL_ADD]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_TILL_ADJUST]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_TILL_ASSOCIATION]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_CASH_PICKUP]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_PICKUP_DECLARATION]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_TILL_AUDIT]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_TILL_IN]: SECURITY_ROLES_ENUM.LEVEL_20,
  [PATH_TILL_STATUS]: SECURITY_ROLES_ENUM.LEVEL_40,
  [PATH_TILL_OUT]: SECURITY_ROLES_ENUM.LEVEL_20,
  [PATH_TILL_VARIANCE_SUMMARY]: SECURITY_ROLES_ENUM.LEVEL_40,
};
