export const RESOURCE_ADJUST_SAFE = '/store/cash_safe_adjustments/v1';
export const RESOURCE_ATHLETE_AUTH = '/store/athlete_jwts/v1';
export const RESOURCE_BANK_DEPOSIT_AMOUNT = '/store/bank_deposit_amount/v1';
export const RESOURCE_BANK_DEPOSIT = '/store/bank_deposits/v1';
export const RESOURCE_BANK_SUMMARIES = '/store/bank_summary/v1';
export const RESOURCE_CASH_MODE = '/store/cash_mode/v1';
export const RESOURCE_CASH_SAFE_ADJUSTMENTS = '/store/cash_safe_adjustments/v1';
export const RESOURCE_CASH_SAFE_LOANS = '/store/cash_safe_loans/v1';
export const RESOURCE_CASH_SAFE_RECEIPTS = '/store/safe_receipts/v1';
export const RESOURCE_CASH_SAFE_RECONCILIATIONS = '/store/cash_safe_reconciliations/v1';
export const RESOURCE_CASH_SAFES = '/store/cash_safes/v1';
export const RESOURCE_CASH_TILL_ADJUSTMENTS = '/store/cash_till_adjustments/v1';
export const RESOURCE_CASH_TILL_ASSOCIATIONS = '/store/cash_till_associations/v1';
export const RESOURCE_CASH_TILL_AUDITS = '/store/cash_till_audits/v1';
export const RESOURCE_CASH_TILL_INS = '/store/cash_till_ins/v1';
export const RESOURCE_CASH_TILL_LOANS = '/store/cash_till_loans/v1';
export const RESOURCE_CASH_TILL_OUTS = '/store/cash_till_outs/v1';
export const RESOURCE_CASH_TILLS = '/store/cash_tills/v1';
export const RESOURCE_STORE_SAFES = '/store/store_safes/v1';
export const RESOURCE_STORE_TILLS = '/store/store_tills/v1';
export const RESOURCE_STOREVIEWS = '/store/store_views/v2';
export const RESOURCE_TILL_PICKUP = '/store/till_pickup/v1';
export const RESOURCE_TILL_VARIANCE_SUMMARIES = '/store/till_variance_summary/v1';
export const RESOURCE_UPDATE_CASH_MODE = '/store/cash_mode/v1';
