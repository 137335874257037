import { TextStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useHasRequiredRole } from '../../../hooks/useHasRequiredRole';
import { ID_PERMISSIONS_HEADER } from '../../../utils/dom-element-ids';

export function PermissionsContainer({ children, fallback, requiredLevel }) {
  const hasRequiredRole = useHasRequiredRole();
  const hasRole = hasRequiredRole(requiredLevel);

  if (!hasRole) {
    return fallback ? (
      <TextStyled id={ID_PERMISSIONS_HEADER} appearance="heading4">
        {fallback}
      </TextStyled>
    ) : null;
  }

  return children;
}

PermissionsContainer.defaultProps = {
  children: null,
  fallback: null,
  requiredLevel: [],
};

PermissionsContainer.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.string || PropTypes.node,
  requiredLevel: PropTypes.arrayOf(PropTypes.string),
};
