export const PATH_ACCEPT_LOAN = '/backoffice/acceptloan';
export const PATH_BANK_DEPOSIT = '/backoffice/bankdeposit';
export const PATH_BANK_SUMMARY = '/backoffice/banksummary';
export const PATH_BANKING = '/backoffice/banking';
export const PATH_CASH = '/backoffice/cash';
export const PATH_CASH_PICKUP = '/backoffice/cashpickup';
export const PATH_CLOSE_STORE = '/backoffice/closeStore';
export const PATH_END_OF_DAY = '/backoffice/endofday';
export const PATH_FRONT_OFFICE_HOME = '/frontoffice/home';
export const PATH_PICKUP_DECLARATION = '/backoffice/pickupdeclaration';
export const PATH_PROFILE = '/backoffice/profile';
export const PATH_RECONCILIATION_SUMMARIES = '/backoffice/reconciliationsummaries';
export const PATH_SAFE_ADD = '/backoffice/safeadd';
export const PATH_SAFE_ADJUST = '/backoffice/safeadjust';
export const PATH_SAFE_LOAN = '/backoffice/safeloan';
export const PATH_SAFE_RECEIPT = '/backoffice/safereceipt';
export const PATH_SAFE_RECONCILIATION = '/backoffice/safereconciliation';
export const PATH_SAFES = '/backoffice/safes';
export const PATH_TILL_ADD = '/backoffice/tilladd';
export const PATH_TILL_ADJUST = '/backoffice/tilladjust';
export const PATH_TILL_ASSOCIATION = '/backoffice/tillassociation';
export const PATH_TILL_AUDIT = '/backoffice/tillaudit';
export const PATH_TILL_IN = '/backoffice/tillin';
export const PATH_TILL_OUT = '/backoffice/tillOut';
export const PATH_TILL_VARIANCE_SUMMARY = '/backoffice/tillvariancesummary';
export const PATH_TILL_STATUS = '/backoffice/tillStatus';
export const PATH_TILLS = '/backoffice/tills';
export const PATH_UNAUTHORIZED = '/unauthorized';
