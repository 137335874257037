import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import isString from 'lodash.isstring';
import PropTypes from 'prop-types';
import React from 'react';

import { ID_SUCCESS_MESSAGE } from '../../utils/dom-element-ids';

function isEmptyString(value) {
  return isString(value) && isEmpty(value.trim());
}

export default function SuccessMessage({ children }) {
  // Some success values are initialized as an empty string, so ignore those
  if (isNil(children) || isEmpty(children) || isEmptyString(children)) {
    return null;
  }

  return (
    <p
      className="bg-success u-rounded p2-sm prl4-sm mt4-sm mb4-sm text-color-primary-light ta-sm-c"
      id={ID_SUCCESS_MESSAGE}
    >
      <i className="g72-check mr2-sm" /> {children}
    </p>
  );
}

SuccessMessage.propTypes = {
  children: PropTypes.node,
};
