import {
  ButtonStyled,
  DialogStyled,
  toggleSiblingNodes,
  IconButton,
  TextStyled,
} from '@nike/nike-design-system-components';
import { Info } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetLocalization } from '../../hooks';
import { ID_MODAL_ROOT, ID_OPEN_VARIANCE_REASON_MODAL_BUTTON } from '../../utils/dom-element-ids';

function VarianceReasonModal({ modalTitle, additionalReasons }) {
  const getLocalization = useGetLocalization();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, true, undefined);
  };

  const closeModal = () => {
    setIsOpen(false);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, false, undefined);
  };

  const addOnReasons = additionalReasons
    ? additionalReasons.map(({ reason, definition }) => (
        <>
          <br />
          <TextStyled appearance="heading4" bold="true" display="inline">
            {`${getLocalization(reason)}`}
          </TextStyled>
          {`${getLocalization(definition)}`}
        </>
      ))
    : null;

  const actions = () => (
    <DialogStyled>
      <ButtonStyled appearance="primary" ariaLabel="close-dialog" onClick={closeModal}>
        Close Dialog
      </ButtonStyled>
    </DialogStyled>
  );
  return (
    <>
      <IconButton
        Icon={<Info />}
        ariaLabel="info button"
        id={ID_OPEN_VARIANCE_REASON_MODAL_BUTTON}
        size="small"
        type="button"
        onClick={openModal}
      />
      <DialogStyled
        className="dialogModal"
        closeModal={closeModal}
        footerContent={actions}
        isOpen={isOpen}
        slots={{ FooterSlot: actions }}
        title={modalTitle}
      >
        <div className="ta-sm-l">
          <TextStyled appearance="heading4" bold="true" display="inline">
            {`${getLocalization('lbl.variance-short')}`}
          </TextStyled>
          {`${getLocalization('lbl.variance-short-definition')}`}
          <br />
          <TextStyled appearance="heading4" bold="true" display="inline">
            {`${getLocalization('lbl.variance-over')}`}
          </TextStyled>
          {`${getLocalization('lbl.variance-over-definition')}`}
          {addOnReasons}
        </div>
      </DialogStyled>
    </>
  );
}

VarianceReasonModal.defaultProps = {
  additionalReasons: null,
};

VarianceReasonModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  additionalReasons: PropTypes.arrayOf(PropTypes.shape()),
};

export default VarianceReasonModal;
