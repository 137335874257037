import { differenceInMilliseconds, getTime, isValid } from 'date-fns';

export function convertTimeFromMs(timestamp) {
  if (!isValid(timestamp)) {
    throw new Error('input requires timestamp in ms');
  }
  return getTime(timestamp);
}

export function getCurrentMs() {
  return getTime(Date.now());
}

export function getDuration(startTime, endTime, inSecs = false) {
  const duration = differenceInMilliseconds(endTime, startTime);
  return inSecs ? Math.floor(duration / 1000) : duration;
}

export function secToMs(sec) {
  return sec * 1000;
}

export function minToMs(min) {
  return min * 60 * 1000;
}

export function msToMin(ms) {
  return ms / 1000 / 60;
}
