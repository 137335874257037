export const pickUpRequest = (tenders) =>
  tenders.map(
    ({
      currentCount,
      currentFloat,
      actualCount,
      actualAmount,
      defaultFloat,
      tenderType,
      subTenderType,
      primaryCurrency,
      variance,
      varianceReason,
      varianceExplanation,
    }) => ({
      currentCount,
      currentFloat,
      defaultFloat: primaryCurrency ? defaultFloat : 0,
      pickedUpAmount: actualAmount ?? currentFloat,
      pickedUpCount: actualCount ?? currentCount,
      primaryCurrency,
      subTenderType,
      tenderType,
      variance,
      varianceReason,
      varianceExplanation,
    }),
  );
