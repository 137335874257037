import React from 'react';

import AcceptLoan from './accept-loan';
import AddTill from './add';
import AdjustTill from './adjust';
import { TillAssociation } from './associate-and-count/TillAssociation';
import CashPickup from './cash-pickup';
import PickupDeclaration from './pickup-declaration';
import TillAudit from './till-audit';
import TillIn from './till-in';
import TillOut from './till-out';
import TillVarianceSummary from './variance-summary';
import { SECURITY_ROLES_ENUM } from '../../../constants/roles.const';
import {
  PATH_ACCEPT_LOAN,
  PATH_PICKUP_DECLARATION,
  PATH_TILL_ADD,
  PATH_TILL_ADJUST,
  PATH_TILL_ASSOCIATION,
  PATH_TILL_AUDIT,
  PATH_TILL_IN,
  PATH_TILL_OUT,
  PATH_TILL_VARIANCE_SUMMARY,
} from '../../../constants/routes.const';
import { useGetLocalization } from '../../../hooks';
import {
  ID_ACCEPT_LOAN_BUTTON,
  ID_ADD_TILL_BUTTON,
  ID_ADJUST_TILL_BUTTON,
  ID_TILL_ASSOCIATE_AND_COUNT_BUTTON,
  ID_TILL_AUDIT_BUTTON,
  ID_TILL_IN_BUTTON,
  ID_TILL_OUT_BUTTON,
  ID_TILL_VARIANCE_SUMMARY_BUTTON,
  ID_FINAL_PICKUP_BUTTON,
} from '../../../utils/dom-element-ids';
import { PermissionsContainer, Placeholder, Subheader } from '../../reusable';

function Tills() {
  const getLocalization = useGetLocalization();

  return (
    <>
      <Subheader>{getLocalization('lbl.tills')}</Subheader>
      <PermissionsContainer requiredLevel={SECURITY_ROLES_ENUM.LEVEL_40}>
        <Placeholder
          id={ID_ACCEPT_LOAN_BUTTON}
          name={getLocalization('lbl.accept-loan')}
          redirect={PATH_ACCEPT_LOAN}
        />
        <Placeholder
          id={ID_ADD_TILL_BUTTON}
          name={getLocalization('lbl.till-add')}
          redirect={PATH_TILL_ADD}
        />
        <Placeholder
          id={ID_ADJUST_TILL_BUTTON}
          name={getLocalization('lbl.till-adjust')}
          redirect={PATH_TILL_ADJUST}
        />
        <Placeholder
          id={ID_TILL_ASSOCIATE_AND_COUNT_BUTTON}
          name={getLocalization('lbl.associate-and-count')}
          redirect={PATH_TILL_ASSOCIATION}
        />
        {/* <Placeholder
          id={ID_CASH_PICKUP_BUTTON}
          name={getLocalization('lbl.midday-cash-pickup')}
          redirect={PATH_CASH_PICKUP}
        /> */}
        <Placeholder
          id={ID_FINAL_PICKUP_BUTTON}
          name={getLocalization('lbl.till-final-pickup-declaration')}
          redirect={PATH_PICKUP_DECLARATION}
        />
        <Placeholder
          id={ID_TILL_AUDIT_BUTTON}
          name={getLocalization('lbl.till-audit')}
          redirect={PATH_TILL_AUDIT}
        />
      </PermissionsContainer>
      <PermissionsContainer
        fallback={getLocalization('msg.no-permission')}
        requiredLevel={SECURITY_ROLES_ENUM.LEVEL_20}
      >
        <Placeholder
          id={ID_TILL_IN_BUTTON}
          name={getLocalization('lbl.till-in')}
          redirect={PATH_TILL_IN}
        />
        <Placeholder
          id={ID_TILL_OUT_BUTTON}
          name={getLocalization('lbl.till-out')}
          redirect={PATH_TILL_OUT}
        />
      </PermissionsContainer>
      <PermissionsContainer requiredLevel={SECURITY_ROLES_ENUM.LEVEL_40}>
        <Placeholder
          id={ID_TILL_VARIANCE_SUMMARY_BUTTON}
          name={getLocalization('lbl.till-variance-summary')}
          redirect={PATH_TILL_VARIANCE_SUMMARY}
        />
      </PermissionsContainer>
    </>
  );
}

export {
  Tills,
  AcceptLoan,
  AddTill,
  AdjustTill,
  CashPickup,
  PickupDeclaration,
  TillAssociation,
  TillAudit,
  TillIn,
  TillOut,
  TillVarianceSummary,
};
