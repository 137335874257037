const getIsFeatureEnabled = (feature) => {
  const features = {
    banking: true,
    cash: true,
    eod: true,
    profile: true,
    safes: true,
    sod: true,
    tills: true,
  };

  return features[feature];
};

export default getIsFeatureEnabled;
