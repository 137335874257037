import { Profile } from '@nike/nike-design-system-icons';
import React from 'react';
import './NavBar.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Banking, Cash, Safes, Tills } from '../../assets/icons';
import {
  PATH_BANKING,
  PATH_CASH,
  PATH_PROFILE,
  PATH_SAFES,
  PATH_TILLS,
} from '../../constants/routes.const';
import { useGetLocalization } from '../../hooks';
import { ID_BANKING, ID_CASH, ID_PROFILE, ID_SAFES, ID_TILLS } from '../../utils/dom-element-ids';
import getIsFeatureEnabled from '../../utils/get-is-feature-enabled';

function NavBar() {
  const push = useNavigate();
  const { pathname } = useLocation();
  const getLocalization = useGetLocalization();
  const pages = [
    {
      feature: 'cash',
      Icon: Cash,
      id: ID_CASH,
      name: getLocalization('lbl.cash-home'),
      url: PATH_CASH,
    },
    {
      feature: 'banking',
      Icon: Banking,
      id: ID_BANKING,
      name: getLocalization('lbl.banking'),
      url: PATH_BANKING,
    },
    {
      feature: 'safes',
      Icon: Safes,
      id: ID_SAFES,
      name: getLocalization('lbl.safes'),
      url: PATH_SAFES,
    },
    {
      feature: 'tills',
      Icon: Tills,
      id: ID_TILLS,
      name: getLocalization('lbl.tills'),
      url: PATH_TILLS,
    },
    {
      Icon: Profile,
      id: ID_PROFILE,
      name: getLocalization('lbl.profile'),
      url: PATH_PROFILE,
    },
  ];

  return (
    <nav className="navbar u-full-width ta-sm-c" style={{ height: '85px' }}>
      {pages.map(({ feature, Icon, name, url, id }) =>
        !feature || getIsFeatureEnabled(feature) ? (
          <Link
            key={url}
            id={id}
            style={{ color: pathname === url && '#FA5400', width: '100px' }}
            to={url}
            onClick={() => push(url)}
          >
            <Icon title={name} />
            {id === ID_CASH ? (
              name.split(' ').map((word) => (
                <p key={`${url}-${word}`} className="body-4 mt-2-sm u-bold">
                  {word}
                </p>
              ))
            ) : (
              <p className="body-3 mt-2-sm u-bold">{name}</p>
            )}
          </Link>
        ) : (
          <span style={{ width: '100px' }} />
        ),
      )}
    </nav>
  );
}

export default NavBar;
