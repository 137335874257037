import { UI_NAME } from '../constants/app.const';

export const getItemFromLocalStorage = (key) => localStorage.getItem(`${UI_NAME}-${key}`);

export const removeItemFromLocalStorage = (key) => localStorage.removeItem(`${UI_NAME}-${key}`);

export const setItemInLocalStorage = (key, value) =>
  localStorage.setItem(`${UI_NAME}-${key}`, value);

// eslint-disable-next-line consistent-return
export const getItemFromSessionStorage = (key) => {
  try {
    return sessionStorage.getItem(`${UI_NAME}-${key}`);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error in getItemFromSessionStorage [${key}]`, err);
  }
};

export const removeItemFromSessionStorage = (key) => sessionStorage.removeItem(`${UI_NAME}-${key}`);

export const setItemInSessionStorage = (key, value) =>
  sessionStorage.setItem(`${UI_NAME}-${key}`, value);
