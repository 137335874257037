export const config = {
  local: {
    AUTH_URL: 'https://multiregion-cash-service.retail-cash-test.nikecloud.com',
    TARGET_ORIGIN: ['http://localhost:3000', 'http://localhost:3001/'],
  },
  development: {
    AUTH_URL: 'https://multiregion-cash-service.retail-cash-test.nikecloud.com',
    TARGET_ORIGIN: [
      'https://true-test.nike.com/',
      'https://rtl-lchpad-2.rbh-retail-launchpad-test.nikecloud.com/',
    ],
  },
  production: {
    AUTH_URL: 'https://multiregion-cash-service.retail-cash-prod.nikecloud.com',
    TARGET_ORIGIN: [
      'https://true.nike.com/',
      'https://rtl-lchpad-2.rbh-retail-launchpad-prod.nikecloud.com/',
    ],
  },
};

export function getEnvConfig(env) {
  return config[env.toLowerCase()] || config.local;
}
