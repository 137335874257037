import { getItemFromSessionStorage } from './localStorage';
import countryInfo from './static/country-info';

export const getFormattedCurrency = (localeCode, currencyCode, number) =>
  // TODO; bring currency and country in from global state
  new Intl.NumberFormat(localeCode, { currency: currencyCode, style: 'currency' }).format(number);

const country = getItemFromSessionStorage('country') || 'en';

export const getDecimalCount = () => {
  const decimalCount = countryInfo[country]?.currency.digitsAfterDecimal;
  return decimalCount || decimalCount === 0 ? decimalCount : 2;
};

export const fixedDecimalCount = (value) => value.toFixed(getDecimalCount());
