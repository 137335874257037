import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

function Input({
  error,
  errorMessage,
  id,
  isDisabled,
  isRequired,
  label,
  maxLength,
  minLength,
  onBlur,
  onChange,
  onFocus,
  showCharacterCount,
  type,
  value,
}) {
  return (
    <TextFieldStyled
      error={error || (isRequired && !value)}
      errorMessage={errorMessage}
      id={id}
      label={label}
      maxLength={maxLength}
      minLength={minLength}
      name={id}
      readOnly={isDisabled}
      requiredIndicator={isRequired ? '*' : ''}
      showCharacterCount={showCharacterCount}
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={({ target: { value: newValue } }) => onChange(newValue)}
      onFocus={onFocus}
    />
  );
}

Input.defaultProps = {
  error: false,
  errorMessage: '',
  isDisabled: false,
  isRequired: false,
  maxLength: 100,
  minLength: undefined,
  onBlur: () => {},
  onFocus: () => {},
  showCharacterCount: false,
  type: 'text',
};

Input.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  showCharacterCount: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default Input;
