import { Default, match } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import CashPickupForm from './CashPickupForm';
import { useGetLocalization } from '../../../../hooks';
import { getTills, getTillDetail, tillPickup } from '../../../../utils/service-calls';
import { retrieveOpenTills } from '../../../../utils/tillUtils';
import { ErrorMessage, LoadingIndicator, Messages, Subheader, TillSelect } from '../../../reusable';

function CashPickup() {
  const getLocalization = useGetLocalization();
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const REGISTER_NOT_IN_LIST = getLocalization('msg.register-not-in-list');
  const PICKUP_AMOUNT_LESS_THAN_CURRENT_FLOAT = getLocalization(
    'msg.pickup-amount-less-than-current-float',
  );
  const SUBMIT_SUCCESS = getLocalization('msg.till-cash-pickup-submit-success');
  const CURRENT_FLOAT_LESS_THAN_TILL_CEILING = getLocalization(
    'msg.current-float-less-than-till-ceiling',
  );
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.failed-to-pick-up-cash')}: ${message}`;

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState(null);
  const [selectedTill, setSelectedTill] = useState(null);
  const [retrievedTillError, setRetrievedTillError] = useState(null);
  const [retrievingTills, setRetrievingTills] = useState(true);
  const [currency, setCurrency] = useState('');

  const [retrievingTillDetails, setRetrievingTillDetails] = useState(false);

  const [pickupAmount, setPickupAmount] = useState(0.0);

  const [tillCeiling, setTillCeiling] = useState(0.0);
  const [currentFloat, setCurrentFloat] = useState(0.0);
  const [defaultFloat, setDefaultFloat] = useState(0.0);
  const [newCurrentFloat, setNewCurrentFloat] = useState(0.0);

  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const getPickupAmountError = () =>
    match()(
      [() => typeof pickupAmount !== 'number', REQUIRED_FIELD],
      [() => pickupAmount <= 0, POSITIVE_NUMBER],
      [() => newCurrentFloat <= 0, PICKUP_AMOUNT_LESS_THAN_CURRENT_FLOAT],
      [Default, ''],
    );

  const getTillCeilingError = () =>
    match()(
      [() => newCurrentFloat > tillCeiling, CURRENT_FLOAT_LESS_THAN_TILL_CEILING],
      [Default, ''],
    );

  const getFormError = () => (getPickupAmountError() || getTillCeilingError() ? ERRORS : '');

  const isSubmitDisabled = () => !!(getPickupAmountError() || getTillCeilingError());

  useEffect(() => {
    setNewCurrentFloat((currentFloat - pickupAmount).toFixed(2));
  }, [currentFloat, newCurrentFloat, pickupAmount]);

  useEffect(() => {
    setRetrievingTills(true);
    getTills()
      .then(({ data }) => setAvailableTills(retrieveOpenTills(data.tills)))
      .catch((err) => setAvailableTillsError(err))
      .finally(() => {
        setRetrievingTills(false);
      });
  }, []);

  const reset = () => {
    setPickupAmount(0.0);
    setSelectedTill(null);
    setSubmitSuccess('');
    setSubmitError('');
  };
  const retrieveSelectedTill = (tillId) => {
    reset();
    setRetrievingTillDetails(true);
    return getTillDetail(tillId)
      .then(({ data }) => {
        setCurrency(data.currencyCode);
        setCurrentFloat(data.currentFloat);
        setDefaultFloat(data.defaultFloat ? data.defaultFloat : 0);
        setTillCeiling(data.tillCeiling);
        return setSelectedTill(data);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => setRetrievingTillDetails(false));
  };

  const submit = () => {
    setSubmitLoading(true);
    const postBody = {
      currencyCode: currency,
      pickUps: [
        {
          defaultFloat,
          originalFloat: currentFloat,
          pickedUpAmount: pickupAmount,
          tenderType: 'CASH',
        },
      ],
      tillNumber: selectedTill.tillNumber,
    };
    tillPickup(postBody)
      .then(() => {
        reset();
        return setSubmitSuccess(SUBMIT_SUCCESS);
      })
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.midday-cash-pickup')}</Subheader>
      {availableTillsError && (
        <ErrorMessage rawError={availableTillsError}>
          {getLocalization('msg.error-fetching-tills')}
        </ErrorMessage>
      )}

      {!retrievingTills && availableTills.length === 0 ? (
        <ErrorMessage>{getLocalization('msg.there-are-no-active-tills-available')}</ErrorMessage>
      ) : (
        <TillSelect
          availableTills={availableTills}
          retrieveSelectedTill={retrieveSelectedTill}
          retrievingTills={retrievingTills}
          selectedTill={selectedTill?.id}
          supportText={REGISTER_NOT_IN_LIST}
        />
      )}

      {retrievingTillDetails ? (
        <LoadingIndicator />
      ) : (
        <>
          {retrievedTillError ? (
            <ErrorMessage rawError={retrievedTillError}>
              {getLocalization('msg.error-loading-requested-till')}
            </ErrorMessage>
          ) : (
            selectedTill && (
              <CashPickupForm
                currency={currency}
                currentFloat={currentFloat}
                formErrors={getFormError()}
                isDisabled={isSubmitDisabled()}
                newCurrentFloat={newCurrentFloat}
                pickupAmount={pickupAmount}
                pickupAmountError={getPickupAmountError()}
                setCurrency={setCurrency}
                setPickupAmount={setPickupAmount}
                submit={submit}
                submitError={submitError}
                submitLoading={submitLoading}
                tillCeiling={tillCeiling}
                tillCeilingError={getTillCeilingError()}
              />
            )
          )}
          <Messages success={submitSuccess} />
        </>
      )}
    </article>
  );
}

export default CashPickup;
