import { match, Default } from '@nike/rcf-fp';

export const getTillStatusLocalization =
  ({ getLocalization }) =>
  ({ status }) =>
    match(status)(
      ['ACTIVE', `${getLocalization('lbl.active')}`],
      ['CLOSED', `${getLocalization('lbl.closed')}`],
      ['PICKED_UP', `${getLocalization('lbl.picked-up')}`],
      [Default, `${getLocalization('lbl.closed')}`],
    );
