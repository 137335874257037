import { ButtonStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CASH_MODE_ALLOWED_EVENTS, useCashModeContext } from '../context';

function Placeholder({ data, id, handleClick, name, redirect }) {
  const push = useNavigate();
  const { cashMode } = useCashModeContext();

  const isEventDisabledInCurrentCashMode = !CASH_MODE_ALLOWED_EVENTS[cashMode]?.[id] || false;

  return (
    <article className="va-sm-t ta-sm-l">
      <div style={{ marginBottom: '15px' }}>
        <ButtonStyled
          appearance="primary"
          background="light"
          className="ta-sm-c my-btn-class"
          disableRipple={false}
          disabled={isEventDisabledInCurrentCashMode}
          id={id}
          size="large"
          type="button"
          onClick={async () => {
            if (handleClick) return handleClick();
            return push(redirect, { data: data || {} });
          }}
        >
          {name}
        </ButtonStyled>
      </div>
    </article>
  );
}

Placeholder.defaultProps = {
  data: {},
  handleClick: null,
  redirect: null,
};

Placeholder.propTypes = {
  data: PropTypes.shape(),
  handleClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  redirect: PropTypes.string,
};

export default Placeholder;
