export const retrieveDefaultSafeId = (safes) => {
  const defaultSafeId = safes[safes.length - 1]?.id;
  if (!defaultSafeId) {
    throw Error('msg.no-safe-exists');
  }

  return defaultSafeId;
};

export const filterClosedOrPickedUpTills = (tills) =>
  tills.filter(
    ({ status }) => status !== 'ACTIVE' && (status === 'CLOSED' || status === 'PICKED_UP'),
  );

export const getPositiveOrZeroAmount = (num1, num2) => {
  const amount = num1 - num2;
  return amount > 0 ? amount : 0;
};
