import { ButtonStyled, DropDownStyled, TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import { NIKE_STORES } from '../../constants/countries.const';

export function Login({ appName, onSubmit }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="ncss-container fixed-fluid">
        <div className="ncss-row d-sm-flx flx-jc-sm-c flx-ac-sm-c">
          <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 mt6-sm mb6-sm">
            <h1 className="headline-4">
              <i className="g72-swoosh mr3-sm" />
              Sign in to {appName}
            </h1>
          </div>
        </div>
        <div className="ncss-row d-sm-flx flx-jc-sm-c flx-ac-sm-c">
          <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4">
            <Controller
              name="athleteNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextFieldStyled
                  {...field}
                  error={errors.athleteNumber?.type === 'required'}
                  errorMessage="Number is required"
                  id="athleteNumber"
                  label="Athlete Number"
                  maxLength={40}
                  minLength={1}
                  name="athleteNumber"
                  placeholder="Athlete Number"
                  requiredIndicator="*"
                  type="text"
                  ariaLive="polite"
                />
              )}
            />
          </div>
        </div>
        <div className="ncss-row d-sm-flx flx-jc-sm-c flx-ac-sm-c">
          <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4">
            <Controller
              name="athletePassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextFieldStyled
                  {...field}
                  error={errors.athletePassword?.type === 'required'}
                  errorMessage="Password is required"
                  id="athletePassword"
                  label="Athlete Password"
                  maxLength={40}
                  minLength={1}
                  name="athletePassword"
                  placeholder="Athlete Password"
                  requiredIndicator="*"
                  type="password"
                  ariaLive="polite"
                />
              )}
            />
          </div>
        </div>
        <div className="ncss-row d-sm-flx flx-jc-sm-c flx-ac-sm-c">
          <div className="ncss-col-sm-6 ncss-col-md-3 ncss-col-lg-2">
            <Controller
              name="selectedCountry"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DropDownStyled
                  {...field}
                  error={errors.selectedCountry?.type === 'required'}
                  errorMessage="Country is required"
                  name="selectedCountry"
                  id="selectedCountry"
                  label="Store Country"
                  requiredIndicator="*"
                  placeholder="Store Country"
                  autoComplete="off"
                  options={NIKE_STORES}
                  ariaLive="polite"
                />
              )}
            />
          </div>
          <div className="ncss-col-sm-6 ncss-col-md-3 ncss-col-lg-2">
            <Controller
              name="selectedStore"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextFieldStyled
                  {...field}
                  error={errors.selectedStore?.type === 'required'}
                  errorMessage="Store Number is required"
                  id="selectedStore"
                  label="Store Number"
                  maxLength={40}
                  minLength={1}
                  name="selectedStore"
                  placeholder="Store Number"
                  requiredIndicator="*"
                  type="number"
                  ariaLive="polite"
                />
              )}
            />
          </div>
        </div>
        <div className="ncss-row d-sm-flx flx-jc-sm-c flx-ac-sm-c">
          <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 mt3-sm">
            <ButtonStyled
              id="submitButton"
              appearance="primary"
              background="light"
              size="large"
              ariaLabel="Sign In"
              type="submit"
            >
              Sign In
            </ButtonStyled>
          </div>
        </div>
      </div>
    </form>
  );
}

Login.propTypes = {
  appName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
