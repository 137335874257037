import React from 'react';

import { useGetLocalization } from '../../hooks';
import { Subheader } from '../reusable';

function CloseStore() {
  const getLocalization = useGetLocalization();

  return <Subheader>{getLocalization('msg.store-close-complete')}</Subheader>;
}

export default CloseStore;
