import { DialogStyled, TextStyled, toggleSiblingNodes } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetLocalization } from '../../../../hooks';
import {
  ID_MODAL_ROOT,
  ID_MODAL_GO_BACK_BUTTON,
  ID_MODAL_DO_NOT_SUBMIT_DEPOSIT_BUTTON,
  ID_OPEN_MODAL_OK_BUTTON,
} from '../../../../utils/dom-element-ids';
import { ButtonSubmit } from '../../../reusable';

function BankDepositConfirmationModal({ modalTitle, submit }) {
  const getLocalization = useGetLocalization();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, true, undefined);
  };

  const closeModal = () => {
    setIsOpen(false);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, false, undefined);
  };

  return (
    <>
      <ButtonSubmit
        id={ID_OPEN_MODAL_OK_BUTTON}
        label={getLocalization('lbl.proceed-to-safe-reconciliation')}
        size="large"
        onSubmit={openModal}
      />
      <DialogStyled isOpen={isOpen} title={modalTitle}>
        <TextStyled className="headline-4 p5-sm full">
          {getLocalization('msg.bank-deposit-confirmation')}
        </TextStyled>
        <ButtonSubmit
          id={ID_MODAL_DO_NOT_SUBMIT_DEPOSIT_BUTTON}
          label={getLocalization('lbl.do-not-submit-deposit')}
          size="large"
          onSubmit={submit}
          sectionClassName="ncss-col-sm-12"
        />
        <ButtonSubmit
          id={ID_MODAL_GO_BACK_BUTTON}
          label={getLocalization('lbl.go-back')}
          size="large"
          onSubmit={closeModal}
          sectionClassName="ncss-col-sm-12"
        />
      </DialogStyled>
    </>
  );
}

BankDepositConfirmationModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default BankDepositConfirmationModal;
