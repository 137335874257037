import {
  DialogActionsStyled,
  DialogStyled,
  ListStyled,
  TextStyled,
  toggleSiblingNodes,
} from '@nike/nike-design-system-components';
import { isSome } from '@nike/rcf-fp';
import Big from 'big.js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetLocalization } from '../../../../hooks';
import {
  ID_MODAL_ROOT,
  ID_MODAL_SUBMIT_BUTTON,
  ID_OPEN_MODAL_SUBMIT_BUTTON,
} from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyText } from '../../../reusable';

const emptyString = ' ';

const getLocalVarianceReason = (varianceReason) => {
  switch (varianceReason) {
    case 'Short':
      return 'lbl.variance-short';
    case 'Over':
      return 'lbl.variance-over';
    case 'Negative Till Balance':
      return 'lbl.variance-negative-till-balance';
    case 'No Variance':
    default:
      return 'lbl.no-variance';
  }
};

function PickupDeclarationModal({
  isDisabled,
  modalTitle,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  tillNumber,
  virtualTenders,
}) {
  const getLocalization = useGetLocalization();
  const [isOpen, setIsOpen] = useState(false);
  const { varianceReason, varianceExplanation } = virtualTenders[0];

  const openModal = () => {
    setIsOpen(true);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, true, undefined);
  };

  const closeModal = () => {
    setIsOpen(false);
    toggleSiblingNodes(`#${ID_MODAL_ROOT}`, false, undefined);
  };

  const actions = (
    <DialogActionsStyled>
      <ButtonSubmit
        appearance="primary"
        ariaLabel="close-dialog"
        buttonSize="small"
        isSubmitting={submitLoading}
        label={getLocalization('lbl.submit')}
        messageError={submitError}
        messageSuccess={submitError ? emptyString : submitSuccess}
        onSubmit={submit}
      />
    </DialogActionsStyled>
  );

  const { acceptedLoanAmount } = virtualTenders[0];
  const isAcceptedLoan = isSome(acceptedLoanAmount) && acceptedLoanAmount > 0;
  const acceptedLoan = new Big(acceptedLoanAmount).toFixed(2);
  const actualAmount = new Big(virtualTenders[0].actualAmount).toFixed(2);
  const bankDepositContribution = isAcceptedLoan
    ? new Big(virtualTenders[0].actualAmount - acceptedLoanAmount).toFixed(2)
    : new Big(virtualTenders[0].actualAmount).toFixed(2);
  const defaultFloat = new Big(virtualTenders[0].defaultFloat).toFixed(2);

  return (
    <>
      <ButtonSubmit
        id={ID_OPEN_MODAL_SUBMIT_BUTTON}
        isDisabled={isDisabled}
        label={getLocalization('lbl.submit')}
        messageError={submitError}
        messageSuccess={submitError ? emptyString : submitSuccess}
        size="large"
        onSubmit={openModal}
      />
      <DialogStyled
        closeModal={closeModal}
        footerContent={actions}
        isOpen={isOpen}
        title={modalTitle}
      >
        <ListStyled className="ncss-container flx-dir-*-c" listStyle="none">
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.till-number')}:
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">{tillNumber}</TextStyled>
          </li>
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.media-description')}:
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">Cash</TextStyled>
          </li>
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.total-cash-counted')}:
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              <MultipleCurrencyText value={actualAmount} />
            </TextStyled>
          </li>
          {isAcceptedLoan && (
            <li className="ncss-row">
              <TextStyled className="headline-4 ncss-col-sm-6 full">
                {getLocalization('lbl.loan-amount-to-return-to-safe')}:
              </TextStyled>
              <TextStyled className="headline-4 ncss-col-sm-6 full">
                <MultipleCurrencyText value={acceptedLoan} />
              </TextStyled>
            </li>
          )}
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.bank-deposit-contribution')}:
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              <MultipleCurrencyText value={bankDepositContribution} />
            </TextStyled>
          </li>
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.closing-float')}:{' '}
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              <MultipleCurrencyText value={defaultFloat} />
            </TextStyled>
          </li>
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.variance-reason')}:{' '}
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization(getLocalVarianceReason(varianceReason))}
            </TextStyled>
          </li>
          <li className="ncss-row">
            <TextStyled className="headline-4 ncss-col-sm-6 full">
              {getLocalization('lbl.variance-explanation')}:{' '}
            </TextStyled>
            <TextStyled className="headline-4 ncss-col-sm-6 full">{varianceExplanation}</TextStyled>
          </li>
        </ListStyled>
        <ButtonSubmit
          id={ID_MODAL_SUBMIT_BUTTON}
          isDisabled={isDisabled}
          isSubmitting={submitLoading}
          label={getLocalization('lbl.submit')}
          size="large"
          onSubmit={() => {
            submit();
            closeModal();
          }}
        />
      </DialogStyled>
    </>
  );
}

PickupDeclarationModal.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  tillNumber: PropTypes.number.isRequired,
  virtualTenders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default PickupDeclarationModal;
