import { DropDownStyled } from '@nike/nike-design-system-components';
import { Default, match, sort } from '@nike/rcf-fp';
import React, { useEffect, useState } from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_LOAN_SELECT } from '../../../../utils/dom-element-ids';
import { getTills, getTillDetail, acceptLoan } from '../../../../utils/service-calls';
import { retrieveOpenTills } from '../../../../utils/tillUtils';
import { ErrorMessage, LoadingIndicator, Messages, Subheader, TillSelect } from '../../../reusable';
import AcceptLoanForm from './AcceptLoanForm';

function AcceptLoan() {
  const getLocalization = useGetLocalization();

  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const SUCCESSFULLY_ACCEPTED_LOAN = getLocalization('msg.successfully-accepted-loan');
  const CURRENT_FLOAT_LESS_THAN = getLocalization('msg.current-float-less-than');
  const NO_SAFE_LOAN_ERROR = getLocalization('msg.no-safe-loan-error');
  const getSubmitErrorMessage = (message) =>
    `${getLocalization('msg.no-safe-loan-error')}: ${message}`;

  const [availableTills, setAvailableTills] = useState([]);
  const [availableTillsError, setAvailableTillsError] = useState('');
  const [selectedTill, setSelectedTill] = useState(null);
  const [retrievedTillError, setRetrievedTillError] = useState('');
  const [retrievingTills, setRetrievingTills] = useState(true);

  const [retrievingTillDetails, setRetrievingTillDetails] = useState(false);

  const [availableSafeLoans, setAvailableSafeLoans] = useState([]);
  const [selectedSafeLoan, setSelectedSafeLoan] = useState(null);

  const [loanAmount, setLoanAmount] = useState();
  const [loanAmountError, setLoanAmountError] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');

  const [tillCeiling, setTillCeiling] = useState();
  const [tillCeilingError, setTillCeilingError] = useState();
  const [currentFloat, setCurrentFloat] = useState();

  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formError, setFormError] = useState('');

  const safeLoanOnChange = (newValue) => {
    setSelectedSafeLoan(null);
    setSubmitSuccess('');
    setSubmitError('');
    const safeLoan = availableSafeLoans.find((item) => item.pendingLoanId === newValue);
    setSelectedSafeLoan(safeLoan);
  };

  const toSafeLoanArray = (safeLoanMap) =>
    safeLoanMap
      ? Object.keys(safeLoanMap).map((key) => ({ pendingLoanId: key, ...safeLoanMap[key] }))
      : [];

  const calculateNewCurrentFloat = () =>
    `${getLocalization('msg.new-current-float')}: $${(loanAmount + currentFloat).toFixed(2)}`;

  const getSafeLoansError = () =>
    availableSafeLoans && availableSafeLoans.length > 0 ? '' : NO_SAFE_LOAN_ERROR;

  useEffect(() => {
    if (selectedSafeLoan?.tillNumber) {
      setLoanAmount(selectedSafeLoan.loanAmount);
    }
  }, [selectedSafeLoan]);

  useEffect(() => {
    const loanAmountErrors = match()(
      [() => typeof loanAmount !== 'number', REQUIRED_FIELD],
      [() => loanAmount <= 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const tillCeilingErrors = match()(
      [() => currentFloat + loanAmount > tillCeiling, CURRENT_FLOAT_LESS_THAN],
      [Default, ''],
    );
    setFormError(loanAmountErrors || tillCeilingErrors ? ERRORS : '');
    setLoanAmountError(loanAmountErrors);
    setTillCeilingError(tillCeilingErrors);
    setSubmitDisabled(!!(loanAmountErrors || tillCeilingErrors));
  }, [
    CURRENT_FLOAT_LESS_THAN,
    ERRORS,
    POSITIVE_NUMBER,
    REQUIRED_FIELD,
    currentFloat,
    loanAmount,
    tillCeiling,
  ]);

  useEffect(() => {
    setRetrievingTills(true);

    getTills()
      .then(({ data }) => setAvailableTills(retrieveOpenTills(data.tills)))
      .catch((err) => setAvailableTillsError(err.message))
      .finally(() => {
        setRetrievingTills(false);
      });
  }, []);

  const retrieveSelectedTill = (tillId) => {
    setSelectedTill(null);
    setRetrievingTillDetails(true);

    return getTillDetail(tillId)
      .then(({ data }) => {
        const sortedSafeLoans = sort(
          toSafeLoanArray(data.pendingLoans),
          (a, b) => a.safeNumber < b.safeNumber,
        );
        setAvailableSafeLoans(sortedSafeLoans);
        setSelectedSafeLoan(null);
        setCurrentFloat(data.currentFloat);
        setTillCeiling(data.tillCeiling);
        return setSelectedTill(data);
      })
      .catch((err) => setRetrievedTillError(err))
      .finally(() => {
        setRetrievingTillDetails(false);
      });
  };

  const submit = () => {
    setSubmitLoading(true);
    setSubmitSuccess(null);
    setSubmitError('');

    acceptLoan({
      currencyCode,
      loanAmount,
      pendingLoanId: selectedSafeLoan.pendingLoanId,
      tillNumber: selectedSafeLoan.tillNumber,
    })
      .then(() => {
        setSubmitDisabled(true);
        setAvailableSafeLoans(
          availableSafeLoans.filter(
            (safeLoan) => safeLoan.pendingLoanId !== selectedSafeLoan.pendingLoanId,
          ),
        );
        setCurrentFloat(currentFloat + loanAmount);
        setSelectedSafeLoan(null);
        return setSubmitSuccess(SUCCESSFULLY_ACCEPTED_LOAN);
      })
      .catch(({ message }) => setSubmitError(getSubmitErrorMessage(message)))
      .finally(() => setSubmitLoading(false));
  };

  return (
    <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.accept-loan')}</Subheader>
      {availableTillsError && (
        <span>
          {getLocalization('msg.error-fetching-tills')}: {availableTillsError}
        </span>
      )}
      <TillSelect
        availableTills={availableTills}
        retrieveSelectedTill={retrieveSelectedTill}
        retrievingTills={retrievingTills}
        selectedTill={selectedTill?.id}
      />
      {retrievingTillDetails && <LoadingIndicator />}
      {retrievedTillError && (
        <ErrorMessage rawError={retrievedTillError}>
          {getLocalization('msg.error-loading-requested-till')}
        </ErrorMessage>
      )}

      {!retrievedTillError && !submitSuccess && selectedTill && (
        <>
          <DropDownStyled
            error={!selectedSafeLoan?.pendingLoanId}
            errorMessage={getSafeLoansError()}
            id={ID_LOAN_SELECT}
            label={getLocalization('lbl.safe-loans')}
            name={ID_LOAN_SELECT}
            options={availableSafeLoans.map((item) => ({
              label: `${getLocalization('lbl.safe')} ${item.safeNumber} ${getLocalization(
                'lbl.to-till',
              )} ${item.tillNumber} - ${item.currencyCode} - ${item.loanAmount}`,
              value: item.pendingLoanId,
            }))}
            requiredIndicator="*"
            value={selectedSafeLoan?.pendingLoanId}
            onChange={({ target: { value: newValue } }) => safeLoanOnChange(newValue)}
          />
          {selectedSafeLoan && (
            <AcceptLoanForm
              currency={currencyCode}
              currentFloat={currentFloat}
              formErrors={formError}
              getCurrentFloat={calculateNewCurrentFloat(currentFloat, loanAmount)}
              isDisabled={submitDisabled}
              loanAmount={loanAmount}
              loanAmountError={loanAmountError}
              setCurrency={setCurrencyCode}
              setLoanAmount={setLoanAmount}
              setTillCeiling={setTillCeiling}
              submit={submit}
              submitError={submitError}
              submitLoading={submitLoading}
              submitSuccess={' '}
              tillCeiling={tillCeiling}
              tillCeilingError={tillCeilingError}
            />
          )}
        </>
      )}

      <Messages success={submitSuccess} />
    </article>
  );
}

export default AcceptLoan;
