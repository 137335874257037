import { z } from 'zod';

export const getBaseSchema = (fields) =>
  z.object({
    currentFloat: z
      .number()
      .min(0, fields.currentFloat.MIN)
      .max(fields.currentFloat.MAX_AMOUNT, fields.currentFloat.MAX),
    originalFloat: z.number(),
    selectTill: z.string().min(1, fields.isRequired.MIN),
    tillNumber: z.number(),
    varianceReason: z.string(),
    workstationNumber: z
      .string()
      .min(1, fields.isRequired.MIN)
      .regex(/^[0-9]*$/, fields.workstationNumber.NUMBER),
  });

export const getSchema = ({ fields, variance }) =>
  getBaseSchema(fields)
    .refine(
      ({ varianceReason, currentFloat, originalFloat }) => {
        const difference = currentFloat - originalFloat;
        return varianceReason === variance.none.REASON ? difference === 0 : difference !== 0;
      },
      {
        message: variance.none.MESSAGE,
        path: ['varianceReason'],
      },
    )
    .refine(({ workstationNumber }) => parseInt(workstationNumber, 10) > 0, {
      message: 'must be positive number',
      path: ['workstationNumber'],
    });
