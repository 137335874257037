import { createSlice } from '@reduxjs/toolkit';

const globalsSlice = createSlice({
  initialState: {
    langLocale: {
      ncssLang: 'en-us',
      userLang: 'en',
    },
    profile: {
      athleteNumber: null,
      country: null,
      storeId: null,
      storeNumber: null,
      storeRoles: null,
    },
    storeInfo: {
      currency: null,
      id: null,
      locale: null,
      timezone: null,
    },
    tokens: {
      accessToken: null,
      accessTokenExpires: null,
      refreshToken: null,
      refreshTokenExpiration: null,
    },
    bankDepositCount: 0,
  },
  name: 'globals',
  reducers: {
    setLangLocale(state, { payload } = {}) {
      state.langLocale = { ...payload };
    },
    setProfile(state, { payload } = {}) {
      state.profile = payload;
    },
    setStoreInfo(state, { payload } = {}) {
      state.storeInfo = payload;
    },
    setTokens(state, { payload } = {}) {
      state.tokens = payload;
    },
    setBankDepositCount(state, { payload } = {}) {
      state.bankDepositCount = payload;
    },
  },
});

export const { setLangLocale, setProfile, setStoreInfo, setTokens, setBankDepositCount } =
  globalsSlice.actions;
export const { reducer } = globalsSlice;
