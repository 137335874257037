import React, { useEffect, useState } from 'react';

import { getSafes } from '../../../../utils/service-calls';
import { LoadingIndicator } from '../../../reusable';
import AddSafe from './AddSafe';

function Index() {
  const [isLoading, setIsLoading] = useState(true);
  const [nextSafeNumber, setNextSafeNumber] = useState();
  const [nextSafeError, setNextSafeError] = useState('');

  useEffect(() => {
    setIsLoading(true);

    getSafes()
      .then(({ data }) =>
        setNextSafeNumber(
          data.safes.length ? Math.max(...data.safes.map((safe) => safe.safeNumber)) + 1 : 888,
        ),
      )
      .catch(({ message }) => setNextSafeError(message))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <article className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AddSafe
          nextSafeError={nextSafeError}
          safeNumber={nextSafeNumber}
          setNextSafeNumber={setNextSafeNumber}
        />
      )}
    </article>
  );
}

export default Index;
