import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { InputDate } from '../../../reusable';

function DateRangeSelect({ country, endDate, setEndDate, setStartDate, startDate }) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <InputDate
        isRequired
        id="ReconciliationSummariesStartDate"
        label={getLocalization('lbl.earliest-search-date')}
        locale={country}
        value={startDate}
        onChange={setStartDate}
      />
      <InputDate
        isRequired
        id="ReconciliationSummariesEndDate"
        label={getLocalization('lbl.latest-search-date')}
        locale={country}
        value={endDate}
        onChange={setEndDate}
      />
    </>
  );
}

DateRangeSelect.propTypes = {
  country: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default DateRangeSelect;
