import { z } from 'zod';

export const getBaseSchema = (fields) =>
  z.object({
    amountBackToSafe: z.number(),
    depositSlip: z.string().min(1, { message: fields.depositSlip.MIN }),
    existingDepositSlips: z.array(z.string()),
    expectedDepositAmount: z.number(),
    depositAmount: z
      .number()
      .nonnegative(fields.depositAmount.NONNEGATIVE)
      .max(fields.depositAmount.MAX_AMOUNT, fields.depositAmount.MAX),
    varianceExplanation: z.string().optional(),
  });

export const getSchema = ({ fields, deposit }) =>
  getBaseSchema(fields).refine(
    ({ depositSlip, existingDepositSlips }) => {
      return !existingDepositSlips.includes(depositSlip);
    },
    {
      message: deposit.slipDuplicate.MESSAGE,
      path: ['depositSlip'],
    },
  );

export const getVarianceReason = (difference, varianceConfigs) => {
  if (difference < 0) {
    return varianceConfigs.short;
  }
  if (difference > 0) {
    return varianceConfigs.over;
  }
  return varianceConfigs.none;
};
