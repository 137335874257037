import {
  ID_BANK_DEPOSIT_BUTTON,
  ID_BANK_SUMMARY_BUTTON,
  ID_ADD_SAFE_BUTTON,
  ID_ADJUST_SAFE_BUTTON,
  ID_SAFE_LOAN_BUTTON,
  ID_SAFE_RECEIPT_BUTTON,
  ID_SAFE_RECONCILIATION_BUTTON,
  ID_SAFE_RECONCILIATION_SUMMARY_BUTTON,
  ID_ACCEPT_LOAN_BUTTON,
  ID_ADD_TILL_BUTTON,
  ID_ADJUST_TILL_BUTTON,
  ID_TILL_ASSOCIATE_AND_COUNT_BUTTON,
  ID_CASH_PICKUP_BUTTON,
  ID_FINAL_PICKUP_BUTTON,
  ID_TILL_AUDIT_BUTTON,
  ID_TILL_IN_BUTTON,
  ID_TILL_OUT_BUTTON,
  ID_TILL_VARIANCE_SUMMARY_BUTTON,
  ID_END_OF_DAY_BUTTON,
  ID_START_OF_DAY_BUTTON,
} from '../../../utils/dom-element-ids';

export const CASH_MODE_ALLOWED_EVENTS = {
  CLOSED: {
    // Cash
    [ID_START_OF_DAY_BUTTON]: true,
    [ID_END_OF_DAY_BUTTON]: false,

    // Banking
    [ID_BANK_DEPOSIT_BUTTON]: false,
    [ID_BANK_SUMMARY_BUTTON]: true,

    // Safe
    [ID_ADD_SAFE_BUTTON]: true,
    [ID_ADJUST_SAFE_BUTTON]: true,
    [ID_SAFE_LOAN_BUTTON]: false,
    [ID_SAFE_RECEIPT_BUTTON]: true,
    [ID_SAFE_RECONCILIATION_BUTTON]: false,
    [ID_SAFE_RECONCILIATION_SUMMARY_BUTTON]: true,

    // Till
    [ID_ACCEPT_LOAN_BUTTON]: false,
    [ID_ADD_TILL_BUTTON]: true,
    [ID_ADJUST_TILL_BUTTON]: true,
    [ID_TILL_ASSOCIATE_AND_COUNT_BUTTON]: false,
    [ID_CASH_PICKUP_BUTTON]: false,
    [ID_FINAL_PICKUP_BUTTON]: false,
    [ID_TILL_AUDIT_BUTTON]: false,
    [ID_TILL_IN_BUTTON]: false,
    [ID_TILL_OUT_BUTTON]: false,
    [ID_TILL_VARIANCE_SUMMARY_BUTTON]: false,
  },
  OPEN: {
    // Cash
    [ID_START_OF_DAY_BUTTON]: false,
    [ID_END_OF_DAY_BUTTON]: true,

    // Banking
    [ID_BANK_DEPOSIT_BUTTON]: true,
    [ID_BANK_SUMMARY_BUTTON]: true,

    // Safe
    [ID_ADD_SAFE_BUTTON]: true,
    [ID_ADJUST_SAFE_BUTTON]: true,
    [ID_SAFE_LOAN_BUTTON]: true,
    [ID_SAFE_RECEIPT_BUTTON]: true,
    [ID_SAFE_RECONCILIATION_BUTTON]: true,
    [ID_SAFE_RECONCILIATION_SUMMARY_BUTTON]: true,

    // Till
    [ID_ACCEPT_LOAN_BUTTON]: true,
    [ID_ADD_TILL_BUTTON]: true,
    [ID_ADJUST_TILL_BUTTON]: true,
    [ID_TILL_ASSOCIATE_AND_COUNT_BUTTON]: true,
    [ID_CASH_PICKUP_BUTTON]: true,
    [ID_FINAL_PICKUP_BUTTON]: true,
    [ID_TILL_AUDIT_BUTTON]: true,
    [ID_TILL_IN_BUTTON]: true,
    [ID_TILL_OUT_BUTTON]: true,
    [ID_TILL_VARIANCE_SUMMARY_BUTTON]: true,
  },
};
