import React from 'react';

import { useGetLocalization } from '../../../hooks';

export function ContactSupportMessage() {
  const getLocalization = useGetLocalization();

  return (
    <h1 className="display-3 text-color-error mb3-sm">
      {getLocalization('msg.an-error-occurred-please-try-again')}
    </h1>
  );
}
