/* eslint-disable react/no-array-index-key */
import escapeHtml from 'lodash.escape';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import { CSVLink } from 'react-csv';

import { useGetLocalization } from '../../../../hooks';
// import { ButtonSubmit } from '../../../reusable';
import { ID_BACKWARD_BUTTON, ID_FORWARD_BUTTON } from '../../../../utils/dom-element-ids';
import { ButtonDirection, Button } from '../../../reusable';

function TillVarianceSummaryTable({ tillVarianceSummaries }) {
  const [page, setPage] = useState(1);
  const paginatedTillVarianceSummaries = {};

  const rowsPerPage = 7;
  let paginatedDataPage = 1;

  tillVarianceSummaries.forEach((summary) => {
    if (!paginatedTillVarianceSummaries[paginatedDataPage]) {
      paginatedTillVarianceSummaries[paginatedDataPage] = [];
    }
    const paginatedDataPageLength =
      Object.keys(paginatedTillVarianceSummaries[paginatedDataPage]).length + 1;
    if (paginatedDataPageLength <= rowsPerPage) {
      paginatedTillVarianceSummaries[paginatedDataPage].push(summary);
    }
    if (paginatedDataPageLength === 7) {
      paginatedDataPage += 1;
    }
  });
  const totalPages = Object.keys(paginatedTillVarianceSummaries).length;

  const canPageForward = () => !!paginatedTillVarianceSummaries[page + 1];
  const canPageBackward = () => page > 1;

  const getLocalization = useGetLocalization();

  const varianceReasonLookupTable = {
    Short: getLocalization('lbl.variance-short'),
    Over: getLocalization('lbl.variance-over'),
    'No Variance': getLocalization('lbl.no-variance'),
    'Negative Till Balance': getLocalization('lbl.variance-negative-till-balance'),
  };
  const translateVarianceReason = (reason) => varianceReasonLookupTable[reason] ?? reason;
  const translateTillVarianceSummaryRecord = ({ varianceReason, ...rest }) => ({
    ...rest,
    varianceReason: translateVarianceReason(varianceReason),
  });
  const tillVarianceSummariesTranslated = paginatedTillVarianceSummaries[page].map(
    translateTillVarianceSummaryRecord,
  );

  const COLUMNS = [
    { key: 'localeDate', label: getLocalization('lbl.date-time') },
    { key: 'tillNumber', label: getLocalization('lbl.till') },
    { key: 'localeAmountReceived', label: getLocalization('lbl.received'), textAlign: 'right' },
    {
      key: 'localeAmountExpected',
      label: getLocalization('lbl.expected'),
      textAlign: 'right',
    },
    { key: 'localeVariance', label: getLocalization('lbl.variance'), textAlign: 'right' },
    { key: 'varianceReason', label: getLocalization('lbl.reason') },
    { key: 'varianceExplanation', label: getLocalization('lbl.variance-explanation') },
    { key: 'athleteInfo', label: getLocalization('lbl.athlete') },
  ];

  const headerCell = (fieldName, idx, value, textAlign = 'center') => (
    <th
      key={`TillVarianceSummaryTable_th_${fieldName}_${idx}`}
      style={{
        background: 'black',
        color: 'white',
        fontSize: '20px',
        textAlign,
      }}
    >
      {escapeHtml(value)}
    </th>
  );

  const dataCell = (fieldName, idx, value, textAlign = 'center', color = '#111') => (
    <td
      key={`TillVarianceSummaryTable_td_${fieldName}_${idx}`}
      style={{
        background: '#FAFAFA',
        border: '1px solid #E5E5E5',
        color,
        textAlign,
      }}
    >
      {escapeHtml(value)}
    </td>
  );

  return (
    <>
      <h1 className="text-color-accent">{getLocalization('lbl.search-results')}</h1>
      <table style={{ border: '1px solid #E5E5E5', borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr key="TillVarianceSummaryTable_Header_0">
            {COLUMNS.map(({ key, label, textAlign }, columnIdx) =>
              headerCell(key, columnIdx, label, textAlign),
            )}
          </tr>
        </thead>
        <tbody>
          {tillVarianceSummariesTranslated.map((summary, summaryIdx) => (
            <tr key={`TillVarianceSummaryTable_Data_${summaryIdx}`}>
              {COLUMNS.map(({ key, textAlign }, columnIdx) =>
                dataCell(
                  key,
                  columnIdx,
                  summary[key],
                  textAlign,
                  key === 'localeVariance' ? summary.varianceColor : null,
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div
          className="pagination-pages-row"
          style={{ display: 'inline-flex', paddingTop: '12px' }}
        >
          <ButtonDirection
            ariaLabel="left arrow"
            direction="left"
            id={ID_BACKWARD_BUTTON}
            isDisabled={!canPageBackward()}
            onClick={() => setPage(page - 1)}
          />
          {Object.keys(paginatedTillVarianceSummaries).map((summary, pageIndex) => (
            <Button
              ariaLabel={(pageIndex + 1).toString()}
              label={(pageIndex + 1).toString()}
              key={`TillVarianceSummaryTable_PageButton_${pageIndex}`}
              onClick={() => setPage(pageIndex + 1)}
              appearance={page === pageIndex + 1 ? 'primary' : 'secondary'}
            >
              {pageIndex + 1}
            </Button>
          ))}
          <ButtonDirection
            ariaLabel="right arrow"
            direction="right"
            id={ID_FORWARD_BUTTON}
            isDisabled={!canPageForward()}
            onClick={() => setPage(page + 1)}
          />
        </div>
      )}
      {/* <article className="va-sm-t ta-sm-c ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
        <CSVLink
          key="CsvLink"
          data={tillVarianceSummariesTranslated}
          filename="till-variance-summary.csv"
          headers={COLUMNS}
          target="_blank"
        >
          <ButtonSubmit
            key="CsvExportButton"
            label={getLocalization('lbl.export')}
            onSubmit={() => {}}
          />
        </CSVLink>
      </article> */}
    </>
  );
}

TillVarianceSummaryTable.propTypes = {
  tillVarianceSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      athleteName: PropTypes.string.isRequired,
      athleteNumber: PropTypes.string.isRequired,
      createTime: PropTypes.string.isRequired,
      currencyCode: PropTypes.string.isRequired,
      localeAmountExpected: PropTypes.string.isRequired,
      localeAmountReceived: PropTypes.string.isRequired,
      localeVariance: PropTypes.string.isRequired,
      tillNumber: PropTypes.number.isRequired,
      varianceReason: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TillVarianceSummaryTable;
