import { NikeI18nProvider } from '@nike/i18n-react';
import React from 'react';

import RouteContainer from './components/container/RouteContainer';
import { Loader } from './components/reusable';
import LanguagePicker from './components/reusable/LanguagePicker';
import { UI_NAME } from './constants/app.const';
import updatedTranslations, { supportedLanguages } from './constants/supportedLanguages';
import './App.css';
import {
  useGetLangLocale,
  useGetProfile,
  useGetStoreInfo,
  useSetGlobalLangLocale,
} from './globals';
import { useWatchGlobals } from './hooks';
import { ID_MODAL_ROOT } from './utils/dom-element-ids';
import { setApplicationAttrs } from './utils/newRelic';

export function App() {
  useWatchGlobals();
  const { locale } = useGetStoreInfo();
  const { ncssLang, userLang } = useGetLangLocale();
  const setGlobalLangLocale = useSetGlobalLangLocale();
  const { athleteNumber, country, storeNumber } = useGetProfile();

  const language = userLang || 'en';

  if (!locale) {
    return <Loader />;
  }

  setApplicationAttrs({
    athleteNumber,
    country,
    storeNumber,
  });

  const retrieveSelectedLanguage = (ncssCode, langId) => {
    const langLocale = {
      ncssLang: ncssCode,
      userLang: langId,
    };
    setGlobalLangLocale(langLocale);
  };

  const link = document.getElementById('ncss-lang-link');
  link.setAttribute(
    'href',
    `https://www.nike.com/static/ncss/4.3/dotcom/desktop/css/ncss.${ncssLang}.min.css`,
  );

  return (
    <NikeI18nProvider
      currentLanguageTag={language}
      supportedLanguages={supportedLanguages}
      translations={updatedTranslations}
    >
      <header className="header mt5-sm mb5-sm">
        <div className="header-left-slot" />
        <div className="header-center-slot">
          <h1 className="display-2 text-color-accent">{UI_NAME}</h1>
        </div>
        <div className="header-right-slot pr4-sm">
          <LanguagePicker
            className="dialogModal"
            modalTitle="Language"
            selectedLanguage={language}
            retrieveSelectedLanguage={retrieveSelectedLanguage}
          />
        </div>
      </header>
      <RouteContainer />
      <div id={ID_MODAL_ROOT} />
    </NikeI18nProvider>
  );
}
