export const ID_ACCEPT_LOAN_BUTTON = 'acceptLoanButton';
export const ID_ACTUAL_AMOUNT = 'actualAmount';
export const ID_ADD_SAFE_BUTTON = 'addSafeButton';
export const ID_ADD_TILL_BUTTON = 'addTillButton';
export const ID_ADJUST_SAFE_BUTTON = 'adjustSafeButton';
export const ID_ADJUST_TILL_BUTTON = 'adjustTillButton';
export const ID_ADJUSTMENT_AMOUNT = 'adjustmentAmount';
export const ID_ASSOCIATE_TILL_BUTTON = 'associateTillButton';
export const ID_ASSOCIATE_TILL_LINK = 'associateTillLink';
export const ID_ATHLETE_NUMBER_INPUT = 'athleteNumber';
export const ID_ATHLETE_PASSWORD_INPUT = 'athletePassword';
export const ID_BACK_TO_TILL_SUMMARY_ANCHOR = 'backToTillSummaryAnchor';
export const ID_BACKWARD_BUTTON = 'backwardButton';
export const ID_BANK_DEPOSIT_BUTTON = 'bankDepositButton';
export const ID_BANK_DEPOSIT_SUBMIT_BUTTON = 'bankDepositSubmitButton';
export const ID_BANK_SUMMARY_BUTTON = 'bankSummaryButton';
export const ID_BANKING = 'banking';
export const ID_CASH = 'cash';
export const ID_CASH_PICKUP_BUTTON = 'cashPickupButton';
export const ID_CLOSE_STORE_BUTTON = 'closeStoreButton';
export const ID_CURRENCY_CODE_INPUT = 'currencyCodeInput';
export const ID_CURRENT_FLOAT = 'currentFloat';
export const ID_DEFAULT_FLOAT = 'defaultFloat';
export const ID_DEPOSIT_AMOUNT = 'depositAmount';
export const ID_DEPOSIT_SLIP = 'depositSlip';
export const ID_END_OF_DAY_BUTTON = 'endOfDayButton';
export const ID_FINAL_PICKUP_BUTTON = 'finalPickupButton';
export const ID_FINAL_PICKUP_LINK = 'finalPickupLink';
export const ID_FINAL_PICKUP_TABLE = 'finalPickupTable';
export const ID_FORWARD_BUTTON = 'forwardButton';
export const ID_LOAN_AMOUNT = 'loanAmount';
export const ID_LOAN_SELECT = 'loanSelect';
export const ID_LOGOUT_BUTTON = 'logoutButton';
export const ID_MODAL_ROOT = 'modal-root';
export const ID_MODAL_GO_BACK_BUTTON = 'modalGoBackButton';
export const ID_MODAL_DO_NOT_SUBMIT_DEPOSIT_BUTTON = 'modalDoNotSubmitDepositButton';
export const ID_MODAL_SUBMIT_BUTTON = 'modalSubmitButton';
export const ID_OPEN_MODAL_OK_BUTTON = 'openModalOkButton';
export const ID_OPEN_MODAL_SUBMIT_BUTTON = 'openModalSubmitButton';
export const ID_OPEN_VARIANCE_REASON_MODAL_BUTTON = 'openVarianceReasonModalButton';
export const ID_OPEN_LANG_MODAL_BUTTON = 'openLangModalButton';
export const ID_PICKUP_AMOUNT = 'pickupAmount';
export const ID_PROCEED_TO_SAFE_RECONCILIATION_BUTTON = 'proceedToSafeReconciliationButton';
export const ID_PROFILE = 'profile';
export const ID_SAFE_FLOOR = 'safeFloor';
export const ID_SAFE_LOAN_BUTTON = 'safeLoanButton';
export const ID_SAFE_LOANS_STATUS_INFO = 'safe-loans-status-info';
export const ID_SAFE_NAME = 'safeName';
export const ID_SAFE_NUMBER = 'safeNumber';
export const ID_SAFE_RECEIPT_BUTTON = 'safeReceiptButton';
export const ID_SAFE_RECONCILIATION_BUTTON = 'safeReconciliationButton';
export const ID_SAFE_RECONCILIATION_SUMMARY_BUTTON = 'safeReconciliationSummaryButton';
export const ID_SAFES = 'safes';
export const ID_SELECTED_STORE_INPUT = 'selectedStore';
export const ID_SUCCESS_MESSAGE = 'successMessage';
export const ID_CASH_HOME_ANCHOR = 'cashHomeAnchor';
export const ID_TILL_STATUS_ANCHOR = 'tillStatusAnchor';
export const ID_START_OF_DAY_BUTTON = 'startOfDayButton';
export const ID_STORE_COUNTRY_INPUT = 'selectedCountry';
export const ID_STORE_TILL_SELECT = 'storeTillSelect';
export const ID_SUBMIT_BUTTON = 'submitButton';
export const ID_TILL_ASSOCIATE_AND_COUNT_BUTTON = 'tillAssociateAndCountButton';
export const ID_TILL_AUDIT_BUTTON = 'tillAuditButton';
export const ID_TILL_CEILING = 'tillCeiling';
export const ID_TILL_IN_BUTTON = 'tillInButton';
export const ID_TILL_NUMBER = 'tillNumber';
export const ID_TILL_OUT_BUTTON = 'tillOutButton';
export const ID_TILL_VARIANCE_SUMMARY_BUTTON = 'tillVarianceSummaryButton';
export const ID_TILLS = 'tills';
export const ID_PERMISSIONS_HEADER = 'permissionsHeader';
export const ID_VARIANCE_EXPLANATION = 'varianceExplanation';
export const ID_VARIANCE_REASONS = 'varianceReasons';
export const ID_WARNING_MESSAGE = 'warningMessage';
export const ID_WORKSTATION_NUMBER = 'workstationNumber';
