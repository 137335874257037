import React from 'react';

import { useGetLocalization } from '../../hooks';
import { Subheader } from './Subheader';

function ActionDisabled() {
  const getLocalization = useGetLocalization();

  return <Subheader>{getLocalization('msg.action-not-allowed-in-cash-mode')}</Subheader>;
}

export default ActionDisabled;
