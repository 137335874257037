import { z } from 'zod';

import { validateResponseShape } from '../../../utils/serviceUtils';

const safeShape = z.object({
  currencyCode: z.string(),
  id: z.string(),
  safeName: z.string(),
  safeNumber: z.number(),
});

const storeSafesResponseShape = z.object({
  links: z.object({
    self: z.string(),
  }),
  resourceType: z.literal('store/store_safes'),
  safes: safeShape.array(),
});

const cashSafesResponseShape = z.object({
  currencyCode: z.string(),
  currentFloat: z.number(),
  defaultFloat: z.number(),
  id: z.string(),
  links: z.object({
    self: z.string(),
  }),
  resourceType: z.literal('store/cash_safes'),
  safeFloor: z.number(),
  safeName: z.string(),
  safeNumber: z.number(),
});

export const validateStoreSafesResponseShape = validateResponseShape(storeSafesResponseShape);
export const validateCashSafesResponseShape = validateResponseShape(cashSafesResponseShape);
