import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_LOAN_AMOUNT, ID_SAFE_FLOOR } from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function SafeLoanForm({
  formErrors,
  getCurrentFloat,
  isDisabled,
  loanAmount,
  loanAmountError,
  safeFloor,
  safeFloorError,
  setLoanAmount,
  setSafeFloor,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  currency,
  setCurrency,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <MultipleCurrencyInput
        isRequired
        currency={currency}
        error={!!loanAmountError}
        errorMessage={loanAmountError}
        id={ID_LOAN_AMOUNT}
        label={getLocalization('lbl.loan-amount')}
        message={getCurrentFloat}
        name="Loan Amount"
        setAmount={setLoanAmount}
        setCurrency={setCurrency}
        showErrorMessage={!!loanAmountError}
        value={loanAmount}
      />
      <MultipleCurrencyInput
        isReadOnly
        currency={currency}
        error={!!safeFloorError}
        errorMessage={safeFloorError}
        id={ID_SAFE_FLOOR}
        label={getLocalization('lbl.safe-floor')}
        message={getLocalization('lbl.safe-floor')}
        name="Safe Floor"
        setAmount={setSafeFloor}
        setCurrency={setCurrency}
        showErrorMessage={!!safeFloorError}
        value={safeFloor}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled || submitLoading || !!submitSuccess}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.loan-from-safe')}
        messageError={formErrors || submitError}
        messageSuccess={submitError || formErrors ? emptyString : submitSuccess}
        onSubmit={submit}
      />
    </>
  );
}

SafeLoanForm.propTypes = {
  currency: PropTypes.string.isRequired,
  formErrors: PropTypes.string.isRequired,
  getCurrentFloat: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  loanAmount: PropTypes.number.isRequired,
  loanAmountError: PropTypes.string.isRequired,
  safeFloor: PropTypes.number.isRequired,
  safeFloorError: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setLoanAmount: PropTypes.func.isRequired,
  setSafeFloor: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
};

export default SafeLoanForm;
