import React, { useEffect, useState } from 'react';

import TillStatusTable from './TillStatusTable';
import { PATH_CASH } from '../../../../constants/routes.const';
import { useGetLocalization } from '../../../../hooks';
import { ID_CASH_HOME_ANCHOR } from '../../../../utils/dom-element-ids';
import { getTills } from '../../../../utils/service-calls';
import {
  ButtonNavigateTo,
  ErrorMessage,
  LoadingIndicator,
  Subheader,
  SuccessMessage,
} from '../../../reusable';

function TillStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [tillData, setTillData] = useState([]);
  const [tillDataError, setTillDataError] = useState(null);
  const [displayStartOfDayTable, setDisplayStartOfDayTable] = useState(false);

  const getLocalization = useGetLocalization();

  useEffect(() => {
    setIsLoading(true);
    getTills()
      .then(({ data }) => setTillData(data.tills))
      .then(() => setDisplayStartOfDayTable(true))
      .catch((err) => setTillDataError(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-4 p4-sm">
      <Subheader>{getLocalization('lbl.start-of-day')}</Subheader>
      {isLoading && <LoadingIndicator />}
      {tillDataError && (
        <ErrorMessage rawError={tillDataError}>
          {getLocalization('msg.error-fetching-tills')}
        </ErrorMessage>
      )}
      {displayStartOfDayTable && <TillStatusTable data={tillData} />}
      <SuccessMessage>{getLocalization('msg.sod-open')}</SuccessMessage>
      <ButtonNavigateTo
        label={getLocalization('lbl.cash-home')}
        linkId={ID_CASH_HOME_ANCHOR}
        pathname={PATH_CASH}
      />
    </article>
  );
}

export default TillStatus;
