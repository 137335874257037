// calculates midnight in relation to the user's local timezone and UTC time
export function getLocalStartDateToUTC(startDate, now = new Date()) {
  const offsetHours = now.getTimezoneOffset() / 60;
  const utcStartDate = new Date(startDate);
  utcStartDate.toUTCString();
  utcStartDate.setUTCHours(offsetHours, 0, 0, 0);
  return utcStartDate;
}

// calculates 11:59:59pm in relation to the user's local timezone and UTC time
export function getLocalEndDateToUTC(endDate, now = new Date()) {
  const offsetHours = now.getTimezoneOffset() / 60;
  const utcEndDate = new Date(endDate);
  const eleventhHour = 23 + offsetHours;
  utcEndDate.setUTCHours(eleventhHour, 59, 59, 999);
  return utcEndDate;
}

// converts UTC date into Store's local timestamps
export function getStoreLocaleDate(utcTime, storeLocale, storeTimeZone) {
  return new Date(utcTime).toLocaleString(storeLocale, { timeZone: storeTimeZone });
}
