import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ButtonSubmit } from '../../../reusable';

const emptyString = ' ';

function TillOutForm({
  formErrors,
  isDisabled,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
}) {
  const getLocalization = useGetLocalization();
  return (
    <ButtonSubmit
      isDisabled={isDisabled || submitLoading}
      isSubmitting={submitLoading}
      label={getLocalization('lbl.till-out')}
      messageError={formErrors || submitError}
      messageSuccess={submitError || formErrors ? emptyString : submitSuccess}
      onSubmit={submit}
    />
  );
}

TillOutForm.propTypes = {
  formErrors: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
};
export default TillOutForm;
