import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setStoreInfo } from './reducer';

export function useSetGlobalStoreInfo() {
  const dispatch = useDispatch();

  return useCallback(
    (storeInfo) => {
      dispatch(setStoreInfo(storeInfo));
    },
    [dispatch],
  );
}
