import {
  PATH_ACCEPT_LOAN,
  PATH_BANK_DEPOSIT,
  PATH_BANK_SUMMARY,
  PATH_CASH_PICKUP,
  PATH_END_OF_DAY,
  PATH_PICKUP_DECLARATION,
  PATH_RECONCILIATION_SUMMARIES,
  PATH_SAFE_ADD,
  PATH_SAFE_ADJUST,
  PATH_SAFE_LOAN,
  PATH_SAFE_RECEIPT,
  PATH_SAFE_RECONCILIATION,
  PATH_TILL_ADD,
  PATH_TILL_ADJUST,
  PATH_TILL_ASSOCIATION,
  PATH_TILL_AUDIT,
  PATH_TILL_IN,
  PATH_TILL_STATUS,
  PATH_TILL_OUT,
  PATH_TILL_VARIANCE_SUMMARY,
} from '../../../constants/routes.const';

export const CASH_MODE_ROUTES_ACCESS = {
  CLOSED: {
    // Cash
    [PATH_END_OF_DAY]: false,

    // Banking
    [PATH_BANK_DEPOSIT]: false,
    [PATH_BANK_SUMMARY]: true,

    // Safe
    [PATH_SAFE_ADD]: true,
    [PATH_SAFE_ADJUST]: true,
    [PATH_SAFE_LOAN]: false,
    [PATH_SAFE_RECEIPT]: true,
    [PATH_SAFE_RECONCILIATION]: false,
    [PATH_RECONCILIATION_SUMMARIES]: true,

    // Till
    [PATH_ACCEPT_LOAN]: false,
    [PATH_TILL_ADD]: true,
    [PATH_TILL_ADJUST]: true,
    [PATH_TILL_ASSOCIATION]: false,
    [PATH_CASH_PICKUP]: false,
    [PATH_PICKUP_DECLARATION]: false,
    [PATH_TILL_AUDIT]: false,
    [PATH_TILL_IN]: false,
    [PATH_TILL_STATUS]: false,
    [PATH_TILL_OUT]: false,
    [PATH_TILL_VARIANCE_SUMMARY]: false,
  },
  OPEN: {
    // Cash
    [PATH_END_OF_DAY]: true,

    // Banking
    [PATH_BANK_DEPOSIT]: true,
    [PATH_BANK_SUMMARY]: true,

    // Safe
    [PATH_SAFE_ADD]: true,
    [PATH_SAFE_ADJUST]: true,
    [PATH_SAFE_LOAN]: true,
    [PATH_SAFE_RECEIPT]: true,
    [PATH_SAFE_RECONCILIATION]: true,
    [PATH_RECONCILIATION_SUMMARIES]: true,

    // Till
    [PATH_ACCEPT_LOAN]: true,
    [PATH_TILL_ADD]: true,
    [PATH_TILL_ADJUST]: true,
    [PATH_TILL_ASSOCIATION]: true,
    [PATH_CASH_PICKUP]: true,
    [PATH_PICKUP_DECLARATION]: true,
    [PATH_TILL_AUDIT]: true,
    [PATH_TILL_IN]: true,
    [PATH_TILL_STATUS]: true,
    [PATH_TILL_OUT]: true,
    [PATH_TILL_VARIANCE_SUMMARY]: true,
  },
};
