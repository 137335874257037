import { TextFieldStyled } from '@nike/nike-design-system-components';
import { match, Default } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useGetLocalization } from '../../../../hooks';
import {
  ID_CURRENT_FLOAT,
  ID_DEFAULT_FLOAT,
  ID_SAFE_FLOOR,
  ID_SAFE_NAME,
  ID_SAFE_NUMBER,
} from '../../../../utils/dom-element-ids';
import { addSafe, getSafes } from '../../../../utils/service-calls';
import {
  ButtonSubmit,
  ErrorMessage,
  Input,
  MultipleCurrencyInput,
  Subheader,
} from '../../../reusable';

function AddSafe({ safeNumber, nextSafeError, setNextSafeNumber }) {
  const getLocalization = useGetLocalization();

  const POSITIVE_NUMBER = getLocalization('msg.number-must-be-positive');
  const LESS_THAN_SAFE_FLOOR = getLocalization('msg.must-be-greater-than-safe-floor');
  const REQUIRED_FIELD = getLocalization('msg.required-field');
  const ERRORS = getLocalization('msg.form-errors');
  const SAFE_NAME_LABEL = getLocalization('lbl.safe-name');
  const DEFAULT_FLOAT_LABEL = getLocalization('lbl.default-float');
  const CURRENT_FLOAT_LABEL = getLocalization('lbl.current-float');
  const SAFE_FLOOR_LABEL = getLocalization('lbl.safe-floor');
  const ADD_SAFE_LABEL = getLocalization('lbl.add-safe');
  const SAFE_NUMBER_LABEL = getLocalization('lbl.safe-number');
  const SUBMIT_SUCCESS = getLocalization('msg.added-safe');
  const SUBMIT_ERROR = getLocalization('msg.add-safe-failed');
  const EXISTING_SAFE = getLocalization('msg.safe-already-exists');
  const SAFE_LOADING_ERROR = getLocalization('msg.safe-loading-error');

  const [currencyCode, setCurrencyCode] = useState('');
  const [safeName, setSafeName] = useState('');
  const [defaultFloat, setDefaultFloat] = useState();
  const [currentFloat, setCurrentFloat] = useState();
  const [safeFloor, setSafeFloor] = useState();
  const [numberOfSafes, setNumberOfSafes] = useState(0);
  const [numberOfSafesError, setNumberOfSafesError] = useState('');

  const [safeNameError, setSafeNameError] = useState('');
  const [defaultFloatError, setDefaultFloatError] = useState('');
  const [currentFloatError, setCurrentFloatError] = useState('');
  const [safeFloorError, setSafeFloorError] = useState('');
  const [formError, setFormError] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submit = () => {
    // Reset state
    setSubmitLoading(true);
    setSubmitSuccess(false);

    const payload = {
      currencyCode,
      currentFloat,
      defaultFloat,
      safeFloor,
      safeName,
      safeNumber,
    };

    addSafe(payload)
      .then(() => {
        setSubmitSuccess(true);
        return setNextSafeNumber(safeNumber + 1);
      })
      .catch((error) => setSubmitError(error))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    setSubmitLoading(true);

    getSafes()
      .then(({ data }) => setNumberOfSafes(data.safes.length))
      .catch((err) => setNumberOfSafesError(err.message))
      .finally(() => setSubmitLoading(false));
  }, []);

  useEffect(() => {
    const nameErrors = match()([() => safeName === '', REQUIRED_FIELD], [Default, '']);
    const defaultFloatErrors = match()(
      [() => typeof defaultFloat !== 'number', REQUIRED_FIELD],
      [() => defaultFloat < safeFloor, LESS_THAN_SAFE_FLOOR],
      [() => Number(defaultFloat) < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const currentFloatErrors = match()(
      [() => typeof currentFloat !== 'number', REQUIRED_FIELD],
      [() => currentFloat < safeFloor, LESS_THAN_SAFE_FLOOR],
      [() => Number(currentFloat) < 0, POSITIVE_NUMBER],
      [Default, ''],
    );
    const safeFloorErrors = match()(
      [() => typeof safeFloor !== 'number', REQUIRED_FIELD],
      [() => Number(safeFloor < 0), POSITIVE_NUMBER],
      [Default, ''],
    );
    const safeNumberErrors = match()([() => numberOfSafes > 0, EXISTING_SAFE], [Default, '']);
    setFormError(
      defaultFloatErrors || currentFloatErrors || nameErrors || safeFloorErrors || safeNumberErrors
        ? ERRORS
        : '',
    );
    setSafeNameError(nameErrors);
    setDefaultFloatError(defaultFloatErrors);
    setCurrentFloatError(currentFloatErrors);
    setSafeFloorError(safeFloorErrors);
    setNumberOfSafesError(safeNumberErrors);
    setSubmitError(null);
  }, [
    currentFloat,
    defaultFloat,
    safeFloor,
    safeName,
    numberOfSafes,
    ERRORS,
    EXISTING_SAFE,
    LESS_THAN_SAFE_FLOOR,
    POSITIVE_NUMBER,
    REQUIRED_FIELD,
  ]);

  return (
    <>
      <Subheader>{ADD_SAFE_LABEL}</Subheader>
      {nextSafeError ? (
        <ErrorMessage rawError={nextSafeError}>{SAFE_LOADING_ERROR}</ErrorMessage>
      ) : (
        <>
          <TextFieldStyled
            readOnly
            error={!!numberOfSafesError}
            errorMessage={numberOfSafesError}
            id={ID_SAFE_NUMBER}
            label={SAFE_NUMBER_LABEL}
            name="safeNumber"
            placeholder={`${SAFE_NUMBER_LABEL} ${safeNumber}`}
            type="number"
            value={safeNumber}
          />
          <Input
            isRequired
            error={!!safeNameError}
            errorMessage={safeNameError}
            id={ID_SAFE_NAME}
            label={SAFE_NAME_LABEL}
            value={safeName}
            onChange={setSafeName}
          />
          <MultipleCurrencyInput
            isRequired
            currency={currencyCode}
            error={!!defaultFloatError}
            errorMessage={defaultFloatError}
            id={ID_DEFAULT_FLOAT}
            label={DEFAULT_FLOAT_LABEL}
            name={DEFAULT_FLOAT_LABEL}
            setAmount={setDefaultFloat}
            setCurrency={setCurrencyCode}
            showErrorMessage={!!defaultFloatError}
            value={defaultFloat}
            onChange={({ value: newValue }) => setDefaultFloat(newValue)}
          />
          <MultipleCurrencyInput
            isRequired
            currency={currencyCode}
            errorMessage={currentFloatError}
            id={ID_CURRENT_FLOAT}
            label={CURRENT_FLOAT_LABEL}
            name={CURRENT_FLOAT_LABEL}
            setAmount={setCurrentFloat}
            setCurrency={setCurrencyCode}
            showErrorMessage={!!currentFloatError}
            value={currentFloat}
            onChange={({ value: newValue }) => setCurrentFloat(newValue)}
          />
          <MultipleCurrencyInput
            isRequired
            currency={currencyCode}
            errorMessage={safeFloorError}
            id={ID_SAFE_FLOOR}
            label={SAFE_FLOOR_LABEL}
            name={SAFE_FLOOR_LABEL}
            setAmount={setSafeFloor}
            setCurrency={setCurrencyCode}
            showErrorMessage={!!safeFloorError}
            value={safeFloor}
            onChange={({ value: newValue }) => setSafeFloor(newValue)}
          />
          <br />
          <ButtonSubmit
            isDisabled={submitLoading || formError || submitSuccess}
            isSubmitting={submitLoading}
            label={ADD_SAFE_LABEL}
            messageError={submitError ? SUBMIT_ERROR : formError}
            messageRawError={submitError}
            messageSuccess={submitSuccess ? SUBMIT_SUCCESS : null}
            onSubmit={submit}
          />
        </>
      )}
    </>
  );
}

AddSafe.defaultProps = {
  nextSafeError: '',
};

AddSafe.propTypes = {
  nextSafeError: PropTypes.string,
  safeNumber: PropTypes.number.isRequired,
  setNextSafeNumber: PropTypes.func.isRequired,
};

export default AddSafe;
