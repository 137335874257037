import { Either, Maybe } from '@nike/rcf-fp';
import { isMatch, isBefore, isAfter, parse } from 'date-fns';

export const isPresent = (value) =>
  Maybe.of(value)
    .filter((v) => v !== '')
    .isSome();

export const isValidDate = (dateFormat) => (value) => isMatch(value, dateFormat);

export const isDateBefore = (dateFormat, otherDate) => (date) => {
  const eitherOtherDate = Either.ofTry(() => parse(otherDate, dateFormat, new Date()));
  const eitherDate = Either.ofTry(() => parse(date, dateFormat, new Date()));
  return eitherOtherDate.isLeft() || eitherDate.isLeft()
    ? true
    : date === otherDate || isBefore(eitherDate.getRight(), eitherOtherDate.getRight());
};

export const isDateAfter = (dateFormat, otherDate) => (date) => {
  const eitherOtherDate = Either.ofTry(() => parse(otherDate, dateFormat, new Date()));
  const eitherDate = Either.ofTry(() => parse(date, dateFormat, new Date()));
  return eitherOtherDate.isLeft() || eitherDate.isLeft()
    ? true
    : date === otherDate || isAfter(eitherDate.getRight(), eitherOtherDate.getRight());
};

export const validate = (value, ...validators) =>
  validators.reduce((acc, [predicate, msg]) => (acc === '' && !predicate(value) ? msg : acc), '');
