import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_DEFAULT_FLOAT, ID_SAFE_FLOOR, ID_SAFE_NUMBER } from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function AdjustSafeForm({
  defaultFloatChangeMessage,
  newDefaultFloat,
  newDefaultFloatError,
  setNewDefaultFloat,
  newSafeFloor,
  newSafeFloorError,
  setNewSafeFloor,
  safeFloorChangeMessage,
  submitLoading,
  submitSuccess,
  isDisabled,
  adjustSafe,
  submitError,
  safeNumber,
  safeName,
  safeDataError,
  currencyCode,
  setCurrency,
  responseError,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <TextFieldStyled
        readOnly
        error={!!safeDataError}
        errorMessage={safeDataError}
        id={ID_SAFE_NUMBER}
        label={getLocalization('lbl.safe-number')}
        name="safeNumber"
        placeholder={`${getLocalization('lbl.safe')}: ${safeName} ${getLocalization(
          'lbl.number',
        )}: ${safeNumber}`}
      />
      <MultipleCurrencyInput
        isRequired
        currency={currencyCode}
        error={!!newDefaultFloatError}
        errorMessage={newDefaultFloatError}
        id={ID_DEFAULT_FLOAT}
        label={getLocalization('lbl.default-float')}
        message={defaultFloatChangeMessage}
        name="defaultFloat"
        setAmount={setNewDefaultFloat}
        setCurrency={setCurrency}
        setValue={setNewDefaultFloat}
        showErrorMessage={!!newDefaultFloatError}
        value={newDefaultFloat}
      />
      <MultipleCurrencyInput
        isRequired
        currency={currencyCode}
        error={!!newSafeFloorError}
        errorMessage={newSafeFloorError}
        id={ID_SAFE_FLOOR}
        label={getLocalization('lbl.safe-floor')}
        message={safeFloorChangeMessage}
        name="safeFloor"
        setAmount={setNewSafeFloor}
        setCurrency={setCurrency}
        setValue={setNewSafeFloor}
        showErrorMessage={!!newSafeFloorError}
        value={newSafeFloor}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled || submitLoading || !!submitSuccess}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.adjust-safe')}
        messageError={`${responseError} ${submitError}`}
        messageSuccess={submitError || responseError ? emptyString : submitSuccess}
        onSubmit={adjustSafe}
      />
    </>
  );
}

AdjustSafeForm.propTypes = {
  adjustSafe: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  defaultFloatChangeMessage: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  newDefaultFloat: PropTypes.number.isRequired,
  newDefaultFloatError: PropTypes.string.isRequired,
  newSafeFloor: PropTypes.number.isRequired,
  newSafeFloorError: PropTypes.string.isRequired,
  responseError: PropTypes.string.isRequired,
  safeDataError: PropTypes.string.isRequired,
  safeFloorChangeMessage: PropTypes.string.isRequired,
  safeName: PropTypes.string.isRequired,
  safeNumber: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setNewDefaultFloat: PropTypes.func.isRequired,
  setNewSafeFloor: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
};

export default AdjustSafeForm;
