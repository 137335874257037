import { z } from 'zod';

import { getDecimalCount } from '../../../../utils/currencyUtils';

export const VARIANCE_REASON_ENUM = {
  SHORT_VARIANCE: 'Short',
  OVER_VARIANCE: 'Over',
  NO_VARIANCE: 'No Variance',
  NEGATIVE_TILL_BALANCE_VARIANCE: 'Negative Till Balance',
};

export const getBaseSchema = (fields) =>
  z.object({
    actualAmount: z
      .number()
      .nonnegative(fields.pickupAmount.NONNEGATIVE)
      .max(fields.pickupAmount.MAX_AMOUNT, fields.pickupAmount.MAX),
    currentFloat: z.number(),
    defaultFloat: z.number(),
    varianceExplanation: z.string().optional(),
  });

export const getSchema = ({ fields }) => getBaseSchema(fields);

export const getVarianceReason = (actualAmount, currentFloat, defaultFloat, varianceConfigs) => {
  const expectedPickupAmount = Number((currentFloat - defaultFloat).toFixed(getDecimalCount()));
  const difference = Number((actualAmount - expectedPickupAmount).toFixed(getDecimalCount()));
  const actualAmountNum = Number(actualAmount);

  if (difference < 0) {
    return varianceConfigs.short;
  }
  if (actualAmount > expectedPickupAmount && actualAmountNum > 0) {
    return varianceConfigs.over;
  }
  if (actualAmountNum === 0 && expectedPickupAmount < 0) {
    return varianceConfigs.negativeTillBalance;
  }
  return varianceConfigs.none;
};
