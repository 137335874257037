import { TextStyled } from '@nike/nike-design-system-components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CashOverviewTable from './cash-overview-table';
import { CASH_MODE_ENUM, useCashModeContext } from './context';
import { ErrorMessage, PermissionsContainer, Placeholder } from './reusable';
import { StatusIndicator } from './reusable/StatusIndicator';
import { NR_CASH_MODE_END_START, NR_CASH_MODE_START } from '../constants/newrelic.const';
import { SECURITY_ROLES_ENUM } from '../constants/roles.const';
import { PATH_END_OF_DAY, PATH_SAFE_RECONCILIATION } from '../constants/routes.const';
import { useGetProfile, useGetStoreInfo } from '../globals';
import { useGetLocalization } from '../hooks';
import { ID_END_OF_DAY_BUTTON, ID_START_OF_DAY_BUTTON } from '../utils/dom-element-ids';
import getIsFeatureEnabled from '../utils/get-is-feature-enabled';
import { nrError, nrPageAction } from '../utils/newRelic';
import { updateCashMode } from '../utils/service-calls';
import { getStoreLocaleDate } from '../utils/timeUtils';

const isCashEnabled = getIsFeatureEnabled('cash');
const isEodEnabled = getIsFeatureEnabled('eod');
const isSodEnabled = getIsFeatureEnabled('sod');

function Cash() {
  const getLocalization = useGetLocalization();
  const { country, storeId, storeNumber } = useGetProfile();
  const { currency, locale, timezone } = useGetStoreInfo();
  const push = useNavigate();
  const { cashMode, setCashMode, athleteInfo, cashModeCreateTime, setCashModeCreateTime } =
    useCashModeContext();
  const [updateCashModeError, setUpdateCashModeError] = useState(null);

  const handleSodOnClick = async () => {
    try {
      setUpdateCashModeError(null);
      const data = await updateCashMode(CASH_MODE_ENUM.OPEN);
      setCashMode(data.data.cash_mode);
      setCashModeCreateTime(data.data.createTime);
      nrPageAction(NR_CASH_MODE_START, {
        storeId,
        storeOpen: true,
        timestamp: Date.now(),
      });
      return push(PATH_SAFE_RECONCILIATION, { state: { isStartOfDayProcess: true } });
    } catch (error) {
      nrError(`CASH Mode Error: ${error}`);
      return setUpdateCashModeError(error);
    }
  };
  return (
    <main className="ncss-row ta-sm-c">
      <aside className="ncss-col-sm-12">
        <h5 className="headline-5 mb1-sm">{getLocalization('msg.nav-instructions')}</h5>
        <p className="headline-5">
          {getLocalization('lbl.store-number')}: {`${country} - ${storeNumber}`}
        </p>
        <p className="headline-5 mb5-sm">
          {getLocalization('lbl.store-currency')}: {currency}
        </p>
        {cashMode === 'OPEN' && (
          <>
            <StatusIndicator text={getLocalization('lbl.store-status-open')} />
            <TextStyled className="headline-5 mb5-sm">
              {getLocalization('msg.opened-by')}: {athleteInfo} at{' '}
              {getStoreLocaleDate(cashModeCreateTime, locale, timezone)}
            </TextStyled>
          </>
        )}
        {cashMode === 'CLOSED' && (
          <>
            <StatusIndicator text={getLocalization('lbl.store-status-closed')} />
            <TextStyled className="headline-5 mb5-sm">
              {getLocalization('msg.closed-by')}: {athleteInfo} at{' '}
              {getStoreLocaleDate(cashModeCreateTime, locale, timezone)}
            </TextStyled>
          </>
        )}

        {isCashEnabled && (
          <PermissionsContainer
            fallback={getLocalization('msg.no-permission')}
            requiredLevel={SECURITY_ROLES_ENUM.LEVEL_40}
          >
            {isEodEnabled && (
              <Placeholder
                id={ID_END_OF_DAY_BUTTON}
                name={getLocalization('lbl.end-of-day')}
                handleClick={() => {
                  nrPageAction(NR_CASH_MODE_END_START, {
                    storeId,
                    storeOpen: false,
                    timestamp: Date.now(),
                  });
                  return push(PATH_END_OF_DAY, { state: { isStartOfDayProcess: false } });
                }}
              />
            )}
            {isSodEnabled && (
              <Placeholder
                handleClick={handleSodOnClick}
                id={ID_START_OF_DAY_BUTTON}
                name={getLocalization('lbl.start-of-day')}
              />
            )}
            {updateCashModeError && (
              <ErrorMessage rawError={updateCashModeError}>
                {getLocalization('msg.open-store-status-failed')}
              </ErrorMessage>
            )}
            <CashOverviewTable />
          </PermissionsContainer>
        )}
      </aside>
    </main>
  );
}

export default Cash;
