import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { ContactSupportMessage } from './messages';
import { useGetLocalization } from '../../hooks';
import { AnyType } from '../../utils/propTypesUtils';
import { ButtonSubmit, ErrorMessage, LoadingIndicator } from '../reusable';
import SuccessMessage from '../reusable/SuccessMessage';

const useCrossFieldValidation = ({ form, fields }) => {
  const {
    trigger,
    watch,
    formState: { isDirty },
  } = form;

  const watchArray = useMemo(() => fields.map((field) => watch(field)), [fields, watch]);
  const triggerArray = useMemo(
    () => fields.map((field) => () => trigger(field)),
    [fields, trigger],
  );

  useEffect(() => {
    if (isDirty) {
      triggerArray.forEach((fieldTrigger) => fieldTrigger());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...watchArray, trigger]);
};

/**
 * @typedef SubmitShape
 * @property {boolean}  disableAfter Whether or not to disable after submit
 * @property {any} error The submit error message
 * @property {import('react-hook-form').UseFormHandleSubmit} handler The onSubmit callback, receives the react hook form field values
 * @property {boolean} isLoading Whether or not the submit is loading
 * @property {boolean} isSuccess Whether or not submit was successful
 * @property {string} label The submit button label
 * @property {boolean}  resetAfter Whether or not to reset after submit
 */

/**
 *
 * @param {Object} props
 * @param {string[]} props.crossFieldValidationArray Fields that require cross field validation
 * @param {Error[] | undefined[]} props.fetchErrors Array of potential errors from RTK Query
 * @param {[boolean[]]} props.fetchingFlags Array of booleans from RTK Query fetching variable, for cache invalidation
 * @param {import('react-hook-form').UseFormReturn} props.form Return value from useForm from react hook form
 * @param {[boolean[]]} props.loadingFlags Array of booleans from RTK Query loading variable, for initial load
 * @param {SubmitShape} props.submit
 */
export function FormWrapper({
  children,
  crossFieldValidationArray,
  fetchErrors,
  fetchingFlags,
  form,
  loadingFlags,
  submit: {
    disableAfter: disableAfterSubmit,
    error: submitError,
    handler: onSubmit,
    isLoading: isSubmitLoading,
    isSuccess: isSubmitSuccess,
    label: submitLabel,
    resetAfter: resetAfterSubmit,
  } = {},
  successMessage,
} = {}) {
  const getLocalization = useGetLocalization();

  const {
    clearErrors,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValidating, isSubmitSuccessful },
  } = form;

  useCrossFieldValidation({ fields: crossFieldValidationArray, form });

  const isFetching = fetchingFlags?.some((flag) => !!flag);
  const isLoading = loadingFlags?.some((loading) => !!loading);
  const hasFetchErrors = fetchErrors?.some((error) => error !== undefined);
  const hasAnyError = !!Object.keys(errors).length;

  if (Object.keys(errors).length) {
    // eslint-disable-next-line no-console
    console.log('Form Errors: ', errors);
  }

  useEffect(() => {
    if (resetAfterSubmit && isSubmitSuccess) {
      reset();
    }
  }, [resetAfterSubmit, isSubmitSuccess, reset]);

  // update submit error
  useEffect(() => {
    if (submitError) {
      // eslint-disable-next-line no-console
      console.error('Submit Error: ', submitError);
      setError('submit', { message: getLocalization('msg.form-submit-error') });
    }
  }, [submitError, setError, getLocalization]);

  // reset success and error on validate
  useEffect(() => {
    if (!disableAfterSubmit) {
      // reset submit state
      reset(undefined, {
        keepValues: true,
        keepDefaultValues: true,
        keepDirty: true,
        keepDirtyValues: true,
        keepSubmitCount: true,
        keepTouched: true,
        keepErrors: true,
      });
    }
    clearErrors('submit');
  }, [isValidating, reset, clearErrors, disableAfterSubmit]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (hasFetchErrors) {
    return <ContactSupportMessage />;
  }

  return (
    <>
      {children}

      <br />

      <ButtonSubmit
        isDisabled={hasAnyError || (disableAfterSubmit && isSubmitSuccess && isSubmitSuccessful)}
        isSubmitting={isFetching || isSubmitLoading}
        label={submitLabel}
        onSubmit={handleSubmit(onSubmit)}
      />
      {isSubmitSuccess && isSubmitSuccessful && !isFetching && (
        <SuccessMessage>
          {successMessage || getLocalization('msg.form-submit-success')}
        </SuccessMessage>
      )}
      {errors?.submit && <ErrorMessage>{errors?.submit?.message}</ErrorMessage>}
    </>
  );
}

FormWrapper.defaultProps = {
  crossFieldValidationArray: [],
  fetchErrors: [],
  fetchingFlags: [],
  successMessage: '',
};

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  crossFieldValidationArray: PropTypes.arrayOf(PropTypes.string),
  fetchErrors: PropTypes.arrayOf(AnyType),
  form: AnyType.isRequired,
  fetchingFlags: PropTypes.arrayOf(PropTypes.bool),
  loadingFlags: PropTypes.arrayOf(PropTypes.bool).isRequired,
  submit: PropTypes.shape({
    disableAfter: PropTypes.bool,
    error: AnyType,
    handler: PropTypes.func,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    label: PropTypes.string,
    resetAfter: PropTypes.bool,
  }).isRequired,
  successMessage: PropTypes.string,
};
