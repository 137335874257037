import { DropDownStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../hooks';
import { ID_STORE_TILL_SELECT } from '../../utils/dom-element-ids';
import LoadingIndicator from './LoadingIndicator';

function TillSelect({
  availableTills,
  retrieveSelectedTill,
  retrievingTills,
  selectedTill,
  supportText,
}) {
  const getLocalization = useGetLocalization();

  if (retrievingTills) {
    <LoadingIndicator />;
  }

  return (
    <DropDownStyled
      autoComplete="on"
      id={ID_STORE_TILL_SELECT}
      label={getLocalization('lbl.store-tills')}
      name="StoreTills"
      options={availableTills.map((item) => ({
        label: `${item.currencyCode} - ${item.tillNumber}`,
        value: item.id,
      }))}
      requiredIndicator="*"
      supportText={supportText}
      value={selectedTill}
      onChange={({ target: { value: newValue } }) => retrieveSelectedTill(newValue)}
    />
  );
}

TillSelect.defaultProps = {
  selectedTill: null,
  supportText: '',
};

TillSelect.propTypes = {
  availableTills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  retrieveSelectedTill: PropTypes.func.isRequired,
  retrievingTills: PropTypes.bool.isRequired,
  selectedTill: PropTypes.string,
  supportText: PropTypes.string,
};
export default TillSelect;
