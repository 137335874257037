import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../../../hooks';
import { ID_CURRENT_FLOAT } from '../../../../utils/dom-element-ids';
import { ButtonSubmit, MultipleCurrencyInput } from '../../../reusable';

const emptyString = ' ';

function TillAuditForm({
  auditTill,
  isDisabled,
  newCurrentFloat,
  newCurrentFloatError,
  responseError,
  setNewCurrentFloat,
  submitError,
  submitLoading,
  submitSuccess,
  varianceMessage,
  varianceMessageColor,
  currency,
  setCurrency,
}) {
  const getLocalization = useGetLocalization();
  return (
    <>
      <MultipleCurrencyInput
        isRequired
        currency={currency}
        errorMessage={newCurrentFloatError}
        id={ID_CURRENT_FLOAT}
        label={getLocalization('lbl.current-float')}
        message={varianceMessage}
        name="currentFloat"
        setAmount={setNewCurrentFloat}
        setCurrency={setCurrency}
        showErrorMessage={!!newCurrentFloatError}
        supportTextColor={varianceMessageColor}
        value={newCurrentFloat}
        onChange={({ value: newValue }) => setNewCurrentFloat(newValue)}
      />
      <br />
      <ButtonSubmit
        isDisabled={isDisabled || submitLoading || !!submitSuccess}
        isSubmitting={submitLoading}
        label={getLocalization('lbl.submit-till-audit')}
        messageError={`${responseError} ${submitError}`}
        messageSuccess={submitError || responseError ? emptyString : submitSuccess}
        onSubmit={auditTill}
      />
    </>
  );
}

TillAuditForm.propTypes = {
  auditTill: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  newCurrentFloat: PropTypes.number.isRequired,
  newCurrentFloatError: PropTypes.string.isRequired,
  responseError: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setNewCurrentFloat: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  varianceMessage: PropTypes.string.isRequired,
  varianceMessageColor: PropTypes.string.isRequired,
};
export default TillAuditForm;
