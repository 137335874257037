import { match, Default } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import TillSummaryModal from './TillSummaryModal';
import { PATH_TILL_ASSOCIATION } from '../../../../constants/routes.const';
import { useGetLocalization } from '../../../../hooks';
import {
  ID_ASSOCIATE_TILL_BUTTON,
  ID_ASSOCIATE_TILL_LINK,
  ID_BACKWARD_BUTTON,
  ID_FORWARD_BUTTON,
} from '../../../../utils/dom-element-ids';
import { getTillStatusLocalization } from '../../../../utils/getTillStatusLocalization';
import { ButtonDirection, ButtonNavigateTo } from '../../../reusable';

function ListItem({ columns, index, item }) {
  const bgColor = index % 2 ? 'bg-primary-grey' : 'bg-white';

  return (
    <div className={`va-sm-m u-full-width p2-sm ${bgColor}`}>
      {columns.map((column) => (
        <div
          className={`ncss-col-sm-${column.width || 1} ${
            column.getClassName ? column.getClassName(item) : ''
          }`}
        >
          {column.processor(item)}
        </div>
      ))}
    </div>
  );
}

ListItem.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      getClassName: PropTypes.func,
      width: PropTypes.number,
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape().isRequired,
};

function Table({ title, columns, data, itemsPerPage }) {
  const getLocalization = useGetLocalization();

  const [page, setPage] = useState(0);
  const [pageItems, setPageItems] = useState(data.slice(0, itemsPerPage));

  const changePage = (isForward) => {
    const newPage = isForward ? page + 1 : page - 1;
    setPageItems(data.slice(newPage * itemsPerPage, (newPage + 1) * itemsPerPage));
    setPage(newPage);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const pageForward = () => changePage(true);
  const pageBack = () => changePage(false);

  const canPageForward = () => !!data[(page + 1) * itemsPerPage];

  const canPageBackward = () => page > 0;

  return (
    <>
      <section className="headline-1 mb4-sm">{title}</section>
      {columns.map((column) => (
        <div
          className={`ncss-col-sm-${column.width || 1} headline-1 ${
            column.headerClassName ? column.headerClassName : ''
          }`}
        >
          {!column.header ? (
            <TillSummaryModal
              className="dialogModal"
              modalTitle={getLocalization('lbl.till-status-definitions')}
            />
          ) : null}
          {column.header}
        </div>
      ))}
      {pageItems.map((item, index) => (
        <ListItem key={JSON.stringify(item)} columns={columns} index={index} item={item} />
      ))}
      <section className="d-sm-flx flx-jc-sm-c pt2-sm">
        <ButtonDirection
          direction="left"
          id={ID_BACKWARD_BUTTON}
          isDisabled={!canPageBackward()}
          onClick={pageBack}
        />
        <section className="ncss-col-sm-8">{`${getLocalization('lbl.page')} ${
          page + 1
        }/${totalPages}`}</section>
        <ButtonDirection
          direction="right"
          id={ID_FORWARD_BUTTON}
          isDisabled={!canPageForward()}
          onClick={pageForward}
        />
      </section>
    </>
  );
}

Table.defaultProps = {
  columns: [
    {
      getClassName: () => '',
      header: '',
      processor: (item) => JSON.stringify(item),
      width: 1,
    },
  ],
  itemsPerPage: 7,
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      getClassName: PropTypes.func,
      header: PropTypes.string,
      processor: PropTypes.func,
      // Sum of all columns' width attributes should be 12
      width: PropTypes.number,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  itemsPerPage: PropTypes.number,
  title: PropTypes.string.isRequired,
};

function AssociateTillButton({ tillData }) {
  const { tillNumber } = tillData;
  const getLocalization = useGetLocalization();
  return (
    <ButtonNavigateTo
      buttonClassName="ncss-col-sm-12 full mb0-sm"
      buttonId={`${ID_ASSOCIATE_TILL_BUTTON}${tillNumber}`}
      buttonSize="small"
      data={tillData}
      label={getLocalization('lbl.associate-till')}
      linkId={`${ID_ASSOCIATE_TILL_LINK}${tillNumber}`}
      pathname={PATH_TILL_ASSOCIATION}
      sectionClassName="ncss-col-sm-12 full"
    />
  );
}

AssociateTillButton.propTypes = {
  tillData: PropTypes.shape().isRequired,
};

function Statuses({ data }) {
  const getLocalization = useGetLocalization();

  const getClassName = ({ status }) => {
    const color = match(status)(
      ['ACTIVE', 'text-color-success'],
      ['CLOSED', 'text-color-error'],
      [Default, 'text-color-primary-dark'],
    );
    return `ta-sm-c ${color}`;
  };

  const getTillStatusLabel = getTillStatusLocalization({ getLocalization });

  const getTillAction = (tillData) =>
    match(tillData.status)(
      ['CLOSED', () => <AssociateTillButton tillData={tillData} />],
      ['ACTIVE', null],
      ['PICKED_UP', <AssociateTillButton tillData={tillData} />],
      [Default, null],
    );

  if (data.length === 0) {
    return (
      <p className="text-color-accent typography-body-1">
        {getLocalization('msg.there-are-no-tills-available')}
      </p>
    );
  }

  return (
    <Table
      columns={[
        {
          header: `${getLocalization('lbl.till-number#')}`,
          processor: ({ tillNumber }) => `${tillNumber}`,
          width: 2,
        },
        {
          header: `${getLocalization('lbl.workstation-number#')}`,
          processor: ({ workstationNumber }) => (workstationNumber ? `${workstationNumber}` : '-'),
          width: 2,
        },
        {
          getClassName,
          header: `${getLocalization('lbl.status')}`,
          processor: getTillStatusLabel,
          width: 4,
        },
        {
          processor: getTillAction,
          width: 4,
        },
      ]}
      data={data}
      title={getLocalization('lbl.till-status')}
    />
  );
}

Statuses.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function TillStatusTable({ data }) {
  return <Statuses data={data} />;
}

TillStatusTable.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TillStatusTable;
