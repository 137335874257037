import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonSubmit from './ButtonSubmit';

function ButtonNavigateTo({
  buttonClassName,
  buttonId,
  buttonSize,
  data,
  isDisabled,
  isRendered,
  label,
  linkId,
  pathname,
  sectionClassName,
}) {
  const navigate = useNavigate();
  if (!isRendered) {
    return null;
  }

  if (isDisabled) {
    return (
      <ButtonSubmit
        buttonClassName={buttonClassName}
        buttonSize={buttonSize}
        id={buttonId}
        isDisabled={isDisabled}
        label={label}
        sectionClassName={sectionClassName}
        onSubmit={() => {}}
      />
    );
  }

  return (
    <ButtonSubmit
      buttonClassName={buttonClassName}
      buttonSize={buttonSize}
      id={linkId}
      label={label}
      sectionClassName={sectionClassName}
      onSubmit={() => navigate(pathname, { state: { data } })}
    />
  );
}

ButtonNavigateTo.defaultProps = {
  buttonClassName: 'mt2-sm mb2-sm',
  buttonId: null,
  buttonSize: 'large',
  data: null,
  isDisabled: false,
  isRendered: true,
  linkId: null,
  sectionClassName: 'ncss-col-sm-12 full',
};

ButtonNavigateTo.propTypes = {
  buttonClassName: PropTypes.string,
  buttonId: PropTypes.string,
  buttonSize: PropTypes.string,
  data: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  isRendered: PropTypes.bool,
  label: PropTypes.string.isRequired,
  linkId: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
};

export default ButtonNavigateTo;
