import React from 'react';

import { useGetLocalization } from '../../hooks';
import { Subheader } from './Subheader';

function PermissionDenied() {
  const getLocalization = useGetLocalization();

  return <Subheader>{getLocalization('msg.no-permission')}</Subheader>;
}

export default PermissionDenied;
