import React from 'react';

// Icon credit to Diogo Marquetti (https://icon-icons.com/icon/safe-box/122531)
function Safes() {
  return <svg height="15px" viewBox="0 -2 22.6 22.74" width="24px" xmlns="http://www.w3.org/2000/svg">
    <title>Safes</title>
    <g data-name="Camada 2" id="Camada_2">
      <g data-name="Camada 1" id="Camada_1-2">
        <path d="M10.81,6.62a3.63,3.63,0,1,0,3.63,3.63A3.63,3.63,0,0,0,10.81,6.62Zm0,6.25a2.63,2.63,0,1,1,2.63-2.62A2.63,2.63,0,0,1,10.81,12.87Z" fill="#000000" />
        <path d="M21.73,7.88A.88.88,0,0,0,22.6,7V5.62a.88.88,0,0,0-.87-.87h-.1V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V18.51a2,2,0,0,0,2,2H3v1.37a.88.88,0,0,0,.88.88H7.21a.88.88,0,0,0,.88-.87V20.5h5.46v1.37a.88.88,0,0,0,.88.88h3.34a.88.88,0,0,0,.88-.87V20.5h1a2,2,0,0,0,2-2V15.75h.1a.88.88,0,0,0,.88-.87V13.5a.88.88,0,0,0-.87-.87h-.1V7.88Zm-.12,5.74v1.13H17.48V13.62Zm-4.25-1a.88.88,0,0,0-.87.88v1.38a.88.88,0,0,0,.88.88H18V17H3.65V3.5H18V4.75h-.62a.88.88,0,0,0-.87.88V7a.88.88,0,0,0,.88.88H18v4.74Zm.13-5.74V5.75H21.6V6.88ZM7.08,21.74H4V20.5H7.08Zm10.56,0H14.54V20.5h3.09Zm3-3.23a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V2A1,1,0,0,1,2,1H19.64a1,1,0,0,1,1,1V4.75H19V3a.5.5,0,0,0-.5-.5H3.15a.5.5,0,0,0-.5.5V17.5a.5.5,0,0,0,.5.5H18.48a.5.5,0,0,0,.5-.5V15.75h1.65Zm0-5.89H19V7.88h1.65Z" fill="#000000" />
      </g>
    </g>
  </svg>
}

export default Safes;
