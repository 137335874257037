import { ButtonStyled } from '@nike/nike-design-system-components';
import { Maybe } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetLocalization } from '../../hooks';

function Button({
  appearance,
  ariaLabel,
  buttonClassName,
  icon,
  id,
  isDisabled,
  label,
  onClick,
  sectionClassName,
  size,
}) {
  const getLocalization = useGetLocalization();
  const effectiveAriaLabel = Maybe.of(ariaLabel).orElseGet(() => getLocalization('lbl.button'));
  const effectiveLabel = Maybe.of(label).orElseGet(() => getLocalization('lbl.button'));
  return (
    <section className={sectionClassName}>
      <ButtonStyled
        appearance={appearance}
        ariaLabel={effectiveAriaLabel}
        className={buttonClassName}
        disabled={isDisabled}
        id={id}
        size={size}
        type="button"
        onClick={onClick}
      >
        {icon || effectiveLabel}
      </ButtonStyled>
    </section>
  );
}

Button.defaultProps = {
  appearance: 'secondary',
  buttonClassName: 'mb2-sm',
  icon: null,
  id: '',
  isDisabled: false,
  sectionClassName: '',
  size: 'small',
};

Button.propTypes = {
  appearance: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  icon: PropTypes.shape(),
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sectionClassName: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
