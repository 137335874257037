import { NikeI18nProvider } from '@nike/i18n-react';
import { detectIncognito } from 'detectincognitojs';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { FallbackLoader, Login, BrowserError } from './components';
import {
  GET_LANGUAGE,
  GET_URL,
  LOGOUT,
  REQUEST_ACCESS_TOKEN,
  SET_TITLE,
} from './constants/auth.const';
import { useInterval, useGetBrowsingContext, useRetailLaunchpadMessaging } from './hooks';
import { authApi } from './utils/api';
import { decodeBermAccessToken, decodeBermRefreshToken, getExpirationDuration } from './utils/auth';
import { minToMs } from './utils/dateTime';
import { clearSession, getFromSession, saveInSession } from './utils/session';
import updatedTranslations, { supportedLanguages } from '../constants/supportedLanguages';

const useAuthMessagingStore = create(
  devtools((set) => ({
    langLocale: {},
    tokens: {},
    profile: {},
    requireLogin: false,
    setLangLocale: (data) => set((state) => ({ langLocale: { ...state.langLocale, ...data } })),
    setTokens: (data) => set((state) => ({ tokens: { ...state.tokens, ...data } })),
    setProfile: (data) => set((state) => ({ profile: { ...state.profile, ...data } })),
    setRequireLogin: (data) => set(() => ({ requireLogin: data })),
    clearProfile: () => set(() => ({ profile: {} })),
  })),
);

// expose a hook to the child app to allow for retrieval of data
export function useGetAuthData() {
  const { langLocale, profile, tokens } = useAuthMessagingStore();
  return { langLocale, profile, tokens };
}

export function useHandleLogout() {
  const { isRetailLaunchpad } = useGetBrowsingContext();
  const { postMessage } = useRetailLaunchpadMessaging(isRetailLaunchpad);
  const { setRequireLogin, clearProfile } = useAuthMessagingStore();
  return useCallback(() => {
    clearProfile();
    if (isRetailLaunchpad) {
      clearSession();
      postMessage({ name: LOGOUT });
    } else {
      clearSession();
      setRequireLogin(true);
    }
  }, [isRetailLaunchpad, postMessage, setRequireLogin, clearProfile]);
}

// standardized token decoding, normalize data
function decodeDataFromTokens(accessToken, refreshToken) {
  const { athleteNumber, country, expiresAt, storeId, storeNumber, storeRoles } =
    decodeBermAccessToken(accessToken);

  const { refreshExp } = decodeBermRefreshToken(refreshToken);

  const tokenDuration = expiresAt * 1000;
  const sessionDuration = refreshExp * 1000;

  return {
    profileData: {
      athleteNumber,
      country,
      storeId,
      storeNumber,
      storeRoles,
    },
    tokenData: {
      accessToken,
      refreshToken,
      sessionDuration,
      tokenDuration,
    },
  };
}

// temporary logging of session information
/* function sessionLogger(tokenDurationMs, sessionDurationMs) {
  const tokenLog = `${Math.round(msToMin(tokenDurationMs))} minutes`;
  const sessionLog =
    sessionDurationMs > 60000
      ? `${Math.round(msToMin(sessionDurationMs) / 60)} hours`
      : `${Math.round(msToMin(sessionDurationMs))} minutes`;

  // eslint-disable-next-line no-console
  console.log('Interval fired. Token expires in', tokenLog, '. Session expires in', sessionLog);
} */

// wrapper component to handle Retail Launchpad auth & messaging, provide fallback for direct login
export function AuthMessaging({ appName, children, loader }) {
  // check if running in iframe
  const { isRetailLaunchpad } = useGetBrowsingContext();
  // setup window messaging with retail launchpad
  const { messageState, postMessage } = useRetailLaunchpadMessaging(isRetailLaunchpad);
  // display loading message while waiting for RL response
  const [waitForCredentials, setWaitForCredentials] = useState(isRetailLaunchpad);
  // detects incognito mode and unsupported browsers
  const [hasBrowserIssue, setHasBrowserIssue] = useState(undefined);

  // zustand store
  const {
    profile,
    tokens,
    requireLogin,
    langLocale,
    setLangLocale,
    setProfile,
    setTokens,
    setRequireLogin,
  } = useAuthMessagingStore();
  function handleAuth(accessToken, refreshToken) {
    const { profileData, tokenData } = decodeDataFromTokens(accessToken, refreshToken);
    // store token data
    setTokens(tokenData);
    // store profile data
    if (!Object.keys(profile).length) {
      setProfile(profileData);
    }
  }

  function checkAuth() {
    if (waitForCredentials || requireLogin) {
      return;
    }
    const now = Date.now();
    const sessionDurationMs = getExpirationDuration(now, tokens.sessionDuration);
    const tokenDurationMs = getExpirationDuration(now, tokens.tokenDuration);

    if (tokenDurationMs < minToMs(4)) {
      if (isRetailLaunchpad) {
        postMessage({ name: REQUEST_ACCESS_TOKEN });
      } else {
        // handle standalone login/refresh
        authApi
          .refresh({
            access_token: tokens.accessToken,
            refresh_token: tokens.refreshToken,
          })
          .then(
            (res) => {
              const { access_token: accessToken, refresh_token: refreshToken } = res.data;
              saveInSession('accessToken', accessToken);
              saveInSession('refreshToken', refreshToken);
              handleAuth(accessToken, refreshToken);
            },
            (err) => {
              // eslint-disable-next-line no-console
              console.log('rejected:', err);
            },
          )
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err));
      }
    }
    // session about to expire, use this space to notify the user of impending logout
    if (sessionDurationMs < minToMs(5)) {
      // eslint-disable-next-line no-console
      console.log('session expiring in 5 minutes');
    }
    // session has expired, request logout from RL
    if (sessionDurationMs < minToMs(1)) {
      if (isRetailLaunchpad) {
        clearSession();
        postMessage({ name: LOGOUT });
      } else {
        clearSession();
        setRequireLogin(true);
      }
    }
    // logging status
    // sessionLogger(tokenDurationMs, sessionDurationMs);
  }

  useInterval(checkAuth, minToMs(2));

  function handleDirectLogin(data) {
    setRequireLogin(false);
    setWaitForCredentials(true);
    authApi
      .login(data)
      .then(
        (res) => {
          const { access_token: accessToken, refresh_token: refreshToken } = res.data;
          saveInSession('accessToken', accessToken);
          saveInSession('refreshToken', refreshToken);
          handleAuth(accessToken, refreshToken);
          setWaitForCredentials(false);
        },
        (err) => {
          // eslint-disable-next-line no-console
          setWaitForCredentials(false);
          setRequireLogin(true);
          // eslint-disable-next-line no-console
          console.log('rejected:', err);
        },
      )
      .catch((err) => {
        setWaitForCredentials(false);
        setRequireLogin(true);
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }

  function checkForIncognito() {
    return detectIncognito()
      .then((result) => result.isPrivate && setHasBrowserIssue('incognito'))
      .catch(() => setHasBrowserIssue('unsupported'));
  }

  // initial page load, request token and send page info to retail launchpad
  useEffect(() => {
    if (isRetailLaunchpad) {
      // is retail launchpad, request token and language
      postMessage({ name: REQUEST_ACCESS_TOKEN });
      postMessage({ name: GET_LANGUAGE });
      postMessage({ name: SET_TITLE, title: appName });
      postMessage({ name: GET_URL });
    } else {
      // standalone implementation, check for existing session
      const accessToken = getFromSession('accessToken');
      const refreshToken = getFromSession('refreshToken');
      if (accessToken && refreshToken) {
        handleAuth(accessToken, refreshToken);
      } else {
        setRequireLogin(true);
      }
    }
    checkForIncognito();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // listen for messages from RL
  useEffect(() => {
    if (isRetailLaunchpad) {
      const { lastMessage } = messageState;

      if (lastMessage?.name === 'accessToken') {
        const {
          token: { accessToken, refreshToken },
        } = lastMessage;

        handleAuth(accessToken, refreshToken);

        setWaitForCredentials(false);
      }
      if (lastMessage?.name === 'langLocale') {
        const { i18nLanguageCode: userLang, ncssLanguageCode: ncssLang } = lastMessage;
        setLangLocale({ ncssLang, userLang });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRetailLaunchpad, messageState]);

  if (hasBrowserIssue) {
    return (
      <NikeI18nProvider
        currentLanguageTag={langLocale.userLang || 'en'}
        supportedLanguages={supportedLanguages}
        translations={updatedTranslations}
      >
        <BrowserError reason={hasBrowserIssue} />
      </NikeI18nProvider>
    );
  }

  // return loader while we await a token from RL
  if (waitForCredentials) {
    return loader;
  }
  if (requireLogin) {
    // eslint-disable-next-line react/jsx-no-bind
    return <Login onSubmit={handleDirectLogin} appName={appName} />;
  }
  return children;
}

AuthMessaging.propTypes = {
  appName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  loader: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AuthMessaging.defaultProps = {
  loader: <FallbackLoader />,
};
