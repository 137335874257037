import React from 'react';

import { UI_NAME } from '../../constants/app.const';
import { useGetLocalization } from '../../hooks';

function RouteInvalid() {
  const getLocalization = useGetLocalization();
  const pageNotFoundTemplate = getLocalization('msg.template.page-not-found');
  const pageNotFoundMessage = pageNotFoundTemplate.replace('__UI_NAME__', UI_NAME);

  return (
    <main className="ncss-col-sm-12 ta-sm-c">
      <h1 className="display-3 mt10-sm mb4-sm text-color-error">
        {getLocalization('msg.route-is-invalid')}
      </h1>
      <p className="headline-4">{pageNotFoundMessage}</p>
      <p className="headline-4">{getLocalization('msg.use-nav-bar')}</p>
    </main>
  );
}

export default RouteInvalid;
