import { useCallback } from 'react';

import { getStore } from '../utils/service-calls';

export const useGetStore = () => {
  return useCallback(async (selectedCountry, selectedStore) => {
    try {
      const storeInfo = await getStore(selectedCountry, selectedStore);
      const {
        data: { objects, pages },
      } = storeInfo;
      if (pages.totalResources > 0) {
        const { currencies, id, locale, timezone } = objects[0];
        return {
          currency: currencies[0],
          id,
          locale,
          timezone,
        };
      }
      return {};
    } catch (error) {
      return error;
    }
  }, []);
};
